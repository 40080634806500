import React, { useContext } from 'react';
import { Box, IconButton } from '@mui/material';
import { AccountContext } from '../supertokens/Account';

import EditIcon from '@mui/icons-material/Edit';
import { Link } from 'react-router-dom';

function AdminEditLink({ sx = { top: 0, right: 0 }, section, id }) {
    const account = useContext(AccountContext);

    // Display nothing if not admin
    if (account.user.role !== 'Admin') {
        return null;
    }

    const btnStyle = {
        borderRadius: '50%',
        width: '50px',
        height: '50px',
        marginLeft: '5px',
        marginBottom: '5px',
    };

    return (
        <Box position="absolute" sx={sx} zIndex={20} margin={2}>
            <IconButton
                style={btnStyle}
                variant="blue"
                component={Link}
                to={`/administration/${section}/create/${id}`}
            >
                <EditIcon />
            </IconButton>
        </Box>
    );
}

export default AdminEditLink;

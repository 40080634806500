import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

import NotFound from '../../pages/NotFound.js';
import CMSForm from './CMSForm.js';
import { useYup } from '../../scripts/cms.js';
import mediaTypes from '../../constants/mediaTypes.js';

function BuilderForm() {
    const { id } = useParams();
    const [valid, setValid] = useState(true);
    const [data, setData] = useState();

    useEffect(() => {
        if (id) {
            axios
                .get(`/builder/get/${id}`)
                .then((res) => {
                    const builder = res.data;
                    setData({ ...builder, logo: { media: builder.logo } });
                })
                .catch(function () {
                    setValid(false);
                });
        }
    }, [id]);

    const inputs = [
        [{ id: 'title' }],
        [{ id: 'description', paragraph: true }],
        [{ id: 'logo', media: { limit: 1, mediaTypeId: mediaTypes.LOGO } }],
    ];

    const yup = useYup(inputs);

    if (valid) {
        return (
            <CMSForm
                api="builder"
                adminSlug="builders"
                data={data}
                inputs={inputs}
                _yup={yup}
            />
        );
    } else {
        return <NotFound />;
    }
}

export default BuilderForm;

import { useState, useContext } from 'react';
import Typography from '@mui/material/Typography';
import BidIcon from '@mui/icons-material/Warning';
import moment from 'moment';
import { Box, Button } from '@mui/material';
import { toast } from 'react-toastify';
import axios from 'axios';
import { AccountContext } from '../supertokens/Account';
import PopupForm from '../common/PopupForm';
import { Link } from 'react-router-dom';

function Bid({ item, i }) {
    const account = useContext(AccountContext);

    const [showDeleteDialog, setShowDeleteDialog] = useState(false);

    const handleDelete = () => {
        axios
            .post(`/bid/delete/${item.id}`)
            .then((res) => {
                setShowDeleteDialog(false);
                toast.success('Bid deletion successful');
            })
            .catch(function () {
                setShowDeleteDialog(false);
                toast.error('Failed to delete bid');
            });
    };

    return (
        <Box display="flex" marginTop={2.5}>
            <Box
                backgroundColor="status.info"
                width={{ xs: 15, md: 20 }}
                minWidth={{ xs: 15, md: 20 }}
            />
            <Box
                display="flex"
                sx={{
                    borderStyle: 'solid',
                    borderWidth: '1px 1px 1px 0px',
                    borderColor: '#ccc',
                    padding: 2.5,
                    borderRadius: '0px 50px 50px 0px',
                }}
                boxShadow="0px 2px 2px 1px #00000018"
            >
                <BidIcon
                    sx={{
                        marginRight: 2,
                        color: 'status.info',
                        fontSize: 35,
                        display: { xs: 'none', md: 'inherit' },
                    }}
                />
                <div style={{ marginTop: 4 }}>
                    <Typography
                        fontSize={16}
                        component="span"
                        letterSpacing={1.5}
                        fontWeight="bold"
                    >
                        {`$${(+item.amount).toLocaleString()} `}
                    </Typography>
                    <Typography
                        fontSize={16}
                        component="span"
                        letterSpacing={1.5}
                    >
                        {`Bid Placed By `}
                    </Typography>
                    <Typography
                        fontSize={16}
                        component="span"
                        letterSpacing={1.5}
                        fontWeight="bold"
                    >
                        <Link
                            to={`/user/${item.user.username}/purchases`}
                            sx={{
                                textDecoration: 'none',
                                color: 'dark',
                            }}
                        >
                            @{item.user.username}
                        </Link>
                    </Typography>

                    {item.broker && (
                        <Typography
                            fontSize={13}
                            component="span"
                            letterSpacing={1.5}
                            color="status.info"
                            marginLeft={1.5}
                        >
                            Agent Represented
                        </Typography>
                    )}

                    <Typography
                        fontSize={14}
                        color="text.secondary"
                        component="span"
                        marginLeft={1.5}
                    >
                        {moment(item.createdAt).format(`MMM D [at] h:mm A`)}
                    </Typography>
                </div>
            </Box>

            {account.isAdmin() && i === 0 && (
                <>
                    <Button
                        variant="danger"
                        size="tiny"
                        style={{ marginLeft: 10, borderRadius: 0 }}
                        onClick={() => setShowDeleteDialog(true)}
                    >
                        Delete
                    </Button>
                    <PopupForm
                        title="Delete Bid?"
                        body={
                            <div>Are you sure you want to delete this bid?</div>
                        }
                        action={handleDelete}
                        show={showDeleteDialog}
                        setShow={setShowDeleteDialog}
                    />
                </>
            )}
        </Box>
    );
}

export default Bid;

import {
    Box,
    Button,
    Grid,
    IconButton,
    Tooltip,
    Typography,
    useMediaQuery,
} from '@mui/material';
import DynamicIcon from './DynamicIcon';
import { Link } from 'react-router-dom';
import Verified from '../../assets/badge/verified.svg';

function Client({
    pairing,
    user,
    invite,
    handleApproveClient,
    handleRejectClient,
    handleDeleteInvite,
}) {
    const xs = useMediaQuery((theme) => theme.breakpoints.down('md'));

    let name = '';

    // Get name from user
    if (user) {
        name = user.firstName
            ? `${user.firstName} ${user.lastName}`
            : user.contactName;
    }
    // Get name from invite
    else if (invite) {
        name = `${invite.firstName} ${invite.lastName}`;
    }

    // Exit
    if (!name) {
        return null;
    }

    return (
        <Box
            backgroundColor="tint"
            padding={1.5}
            marginBottom={1}
            borderRadius={4}
            display="flex"
            flexDirection={{ xs: 'column', md: 'row' }}
        >
            <Box display="flex" width="100%">
                <Box
                    marginTop="auto"
                    marginBottom="auto"
                    marginRight={1.5}
                    width={40}
                >
                    <IconButton
                        component={Link}
                        to={`/user/${user?.username}/purchases`}
                        target="_blank"
                        disabled={!user || !user.activated}
                    >
                        <DynamicIcon
                            icon={user ? 'OpenInNew' : 'MailOutline'}
                            colour={
                                !user || !user.activated ? 'disabled' : 'dark'
                            }
                        />
                    </IconButton>
                </Box>
                <Grid container display="flex" marginRight="auto">
                    <Grid item xs={12} display="flex">
                        {user && (
                            <Box sx={{ marginRight: 1 }}>
                                <Tooltip
                                    title={
                                        user.verified
                                            ? 'Verified'
                                            : 'Unverified'
                                    }
                                >
                                    {user.verified ? (
                                        <img
                                            height={24}
                                            src={Verified}
                                            alt="Verified"
                                        />
                                    ) : (
                                        <Box>
                                            <DynamicIcon
                                                icon="Cancel"
                                                size={25}
                                                colour="status.danger"
                                            />
                                        </Box>
                                    )}
                                </Tooltip>
                            </Box>
                        )}
                        <Typography fontWeight="bold" marginTop="auto">
                            {name}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography
                            marginRight={1}
                            display={{ xs: 'flex', md: 'inline-block' }}
                        >
                            {user?.email || invite.email}
                        </Typography>
                        <Typography
                            marginRight={1}
                            color="brandDark"
                            display="inline-block"
                        >
                            {user?.username}
                        </Typography>
                        {!handleApproveClient &&
                            pairing &&
                            !pairing?.approved && (
                                <Typography
                                    color="disabled"
                                    display="inline-block"
                                >
                                    (Pending Pairing)
                                </Typography>
                            )}
                    </Grid>
                </Grid>
                <Box
                    display="flex"
                    marginTop="auto"
                    marginBottom="auto"
                    flexDirection={{ xs: 'column', md: 'row' }}
                >
                    {handleApproveClient && !pairing?.approved && (
                        <Box>
                            <IconButton
                                width={80}
                                style={{ marginRight: 1 }}
                                onClick={() => handleApproveClient(pairing)}
                            >
                                <DynamicIcon
                                    icon="CheckCircle"
                                    colour="status.success"
                                />
                            </IconButton>
                        </Box>
                    )}
                    {handleRejectClient && (
                        <Box>
                            <IconButton
                                width={80}
                                onClick={() => handleRejectClient(pairing)}
                            >
                                <DynamicIcon
                                    icon="Cancel"
                                    colour="status.danger"
                                />
                            </IconButton>
                        </Box>
                    )}
                    {handleDeleteInvite && invite && (
                        <Box>
                            <IconButton
                                width={80}
                                onClick={() => handleDeleteInvite(invite)}
                            >
                                <DynamicIcon
                                    icon="Cancel"
                                    colour="status.danger"
                                />
                            </IconButton>
                        </Box>
                    )}
                </Box>
            </Box>
            {!handleApproveClient && pairing && !pairing?.approved && (
                <Box
                    marginTop={{ xs: 1, md: 'auto' }}
                    marginBottom="auto"
                    marginLeft={{ md: 'auto' }}
                >
                    <Button
                        size="tiny"
                        variant="brand"
                        // onClick={() => navigate('/broker/clients')}
                        component={Link}
                        to="/broker/clients"
                        fullWidth={xs ? true : false}
                    >
                        <DynamicIcon
                            icon="Link"
                            sx={{ marginRight: 0.5 }}
                            size={18}
                        />
                        <Typography fontSize={14}>Accept</Typography>
                    </Button>
                </Box>
            )}
        </Box>
    );
}

export default Client;

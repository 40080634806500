const { useEffect } = require('react');
const { useLocation } = require('react-router-dom');

export default function ScrollToTop({ scrollRef, smooth }) {
    const { pathname } = useLocation();

    useEffect(() => {
        (scrollRef?.current || window).scrollTo({
            top: 0,
            behavior: smooth ? 'smooth' : 'instant',
        });
    }, [pathname]);

    return null;
}

import React, { useState, useEffect, useRef, useContext } from 'react';
import { Typography, IconButton, Box } from '@mui/material';
import Highlighter from 'react-highlight-words';
import Flag from '@mui/icons-material/Flag';
import { toast } from 'react-toastify';

import moment from 'moment';
import axios from 'axios';

import Avatar from '../user/Avatar';
import { AccountContext } from '../supertokens/Account';
import PopupForm from '../common/PopupForm';
import UserBadges from '../common/UserBadges';
import { Link } from 'react-router-dom';

function Comment({ item, sellerUsername, replyComment, anchorId }) {
    const account = useContext(AccountContext);

    const [showFlag, setShowFlag] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);

    const commentRef = useRef(null);

    useEffect(() => {
        if (item.id.toString() === anchorId) {
            commentRef.current.scrollIntoView({ block: 'center' });
        }
    }, [item.id, anchorId]);

    // Border customizations
    let thickness = 0;
    let accent = '#ccc';
    /*if (mentioned) {
        thickness = 1;
        accent = '#212529';
    } else if (isSeller) {
        thickness = 1;
        accent = '#212529';
    }*/

    /*const click = (e) => {
        if (e.target.classList.contains('text-highlight')) {
            const user = e.target.innerHTML;
            window.location.href = `/user/${user}`;
        }
    };*/

    const handleClose = () => {
        setShowFlag(false);
    };

    const handleDelete = () => {
        axios
            .post(`/comment/${account.isAdmin() ? 'deleteAdmin' : 'delete'}`, {
                id: item.id,
            })
            .then((res) => {
                setShowDeleteDialog(false);
            })
            .catch(function (err) {
                setShowDeleteDialog(false);
                toast.error('Failed to delete comment');
            });
    };

    const handleReport = (form) => {
        axios
            .post(`/comment/flag`, {
                commentId: item.id,
                ...form,
            })
            .then((res) => {
                handleClose();
                toast.success('Thank you for your report.');
            })
            .catch(function (err) {
                handleClose();
                toast.error(
                    err?.response?.data?.message || 'Unable to submit report.'
                );
            });
    };

    if (!item) {
        return null;
    }

    return (
        <div
            ref={commentRef}
            style={{
                marginTop: 20,
                borderStyle: 'solid',
                borderWidth: thickness,
                borderColor: accent,
                /*borderRadius: 8,*/
                padding: '20px 0px 15px 0px',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    marginLeft: '-10px',
                }}
            >
                <div style={{ width: 45, height: 45 }}>
                    <IconButton
                        style={{
                            width: 63,
                            height: 63,
                            marginTop: -9,
                        }}
                        href={`/user/${item.user.username}/comments`}
                    >
                        <Avatar username={item.user.username} sm />
                    </IconButton>
                </div>
                <Box
                    sx={{
                        flex: 1,
                        marginTop: 1,
                        marginLeft: { xs: 2.5, md: 4 },
                        display: 'flex',
                    }}
                >
                    <Typography
                        color="dark"
                        fontSize={20}
                        fontWeight="bold"
                        letterSpacing={1}
                        marginRight={0.5}
                    >
                        <Link
                            to={`/user/${item.user.username}/comments`}
                            sx={{
                                textDecoration: 'none',
                                color: 'dark',
                            }}
                        >
                            {` @${item.user.username}`}
                        </Link>
                    </Typography>
                    <Box marginTop={0.2}>
                        <UserBadges
                            username={item.user.username}
                            sellerUsername={sellerUsername}
                        />
                    </Box>
                </Box>
                <Typography
                    fontSize={14}
                    textAlign={'right'}
                    color="text.secondary"
                    margin="auto"
                >
                    {moment(item.createdAt).format(`MMM D [at] h:mm A`)}
                </Typography>
            </div>
            <div
                style={{
                    marginTop: 20,
                    whiteSpace: 'pre-line',
                    wordBreak: 'break-word',
                }}
            >
                <Typography fontSize={16}>
                    <Highlighter
                        highlightTag={AtUser}
                        searchWords={item?.ats || []}
                        autoEscape={true}
                        textToHighlight={item.text}
                    />
                </Typography>
            </div>
            <div style={{ display: 'flex' }}>
                <Typography
                    fontSize={14}
                    color="text.secondary"
                    sx={{
                        marginTop: '15px',
                        cursor: 'pointer',
                        textDecoration: 'none',
                    }}
                    className="underline"
                    onClick={() => {
                        if (replyComment) {
                            replyComment.current(item.user.username);
                        }
                    }}
                    component={replyComment ? undefined : Link}
                    to={
                        replyComment
                            ? undefined
                            : `/listing/${item.auction.slug}?commentId=${item.id}`
                    }
                >
                    {replyComment ? 'Reply' : 'View Listing'}
                </Typography>
                {(account.isAdmin() ||
                    item.user.username === account.user.username) && (
                    <Typography
                        fontSize={14}
                        color="text.secondary"
                        style={{
                            marginTop: '15px',
                            marginLeft: 20,
                            cursor: 'pointer',
                        }}
                        className="underline"
                        onClick={() => {
                            setShowDeleteDialog(true);
                        }}
                    >
                        Delete
                    </Typography>
                )}
                {account.signedIn && (
                    <IconButton
                        style={{
                            marginLeft: 'auto',
                            color: '#aaa',
                        }}
                        onClick={() => setShowFlag(true)}
                    >
                        <Flag fontSize="small" />
                    </IconButton>
                )}
            </div>
            <PopupForm
                title="Report Comment"
                body="Why is this comment inappropriate?"
                action={handleReport}
                show={showFlag}
                setShow={setShowFlag}
                yes="Submit"
                inputs={[[{ id: 'reason', multiline: true, rows: 4 }]]}
            />
            <PopupForm
                title="Delete Comment?"
                body={<div>Are you sure you want to delete this comment?</div>}
                action={handleDelete}
                show={showDeleteDialog}
                setShow={setShowDeleteDialog}
            />
        </div>
    );
}

const AtUser = ({ children }) => {
    return (
        <Typography
            fontWeight="bold"
            component={Link}
            to={`/user/${children}/comments`}
            sx={{ textDecoration: 'none', color: 'dark' }}
        >
            {children}
        </Typography>
    );
};

export default Comment;

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import NotFound from '../../pages/NotFound.js';

import { toast } from 'react-toastify';
import Field from './Field.js';

import moment from 'moment';
import UserSelectorButton from './UserSelectorButton.js';

function CouponForm() {
    const { id } = useParams();
    const [valid, setValid] = useState(true);

    const [couponId, setCouponId] = useState(id);

    const defaultForm = {};
    const validationSchema = Yup.object().shape({
        username: Yup.string(),
        code: Yup.string().required('Code is required'),
        discount: Yup.number().required('Discount is required'),
        quantity: Yup.number().required('Quantity is required'),
        description: Yup.string().required('Description is required'),
        start_date: Yup.date(),
        expiration_date: Yup.date(),
    });
    const formOptions = {
        resolver: yupResolver(validationSchema),
        defaultValues: defaultForm,
    };
    const {
        register,
        handleSubmit,
        formState,
        getValues,
        setValue,
        control,
        watch,
    } = useForm(formOptions);
    const { errors } = formState;

    useEffect(() => {
        // Get existing auction data (if you are modifying one)
        if (id) {
            axios
                .get(`/coupon/get/${id}`)
                .then((res) => {
                    const coupon = res.data;

                    setValue('username', coupon.user?.username || '');
                    setValue('code', coupon.code);
                    setValue('discount', coupon.discount);
                    setValue('quantity', coupon.quantity || 0);
                    setValue('description', coupon.description);

                    setValue(
                        'start_date',
                        moment(coupon.startDate).format().slice(0, 16)
                    );
                    setValue(
                        'expiration_date',
                        moment(coupon.expirationDate).format().slice(0, 16)
                    );

                    setCouponId(coupon.id);
                })
                .catch(function () {
                    setValid(false);
                });
        }
    }, [id, setValue]);

    function onSubmit(form) {
        form.coupon_id = id;

        // Create a new coupon
        if (!id) {
            axios
                .post(`/coupon/create/`, form)
                .then((res) => {
                    window.location.href = '/administration/coupons';
                })
                .catch(function (err) {
                    let message = err.message;
                    if (err.response && err.response.data) {
                        message = err.response.data;
                    }
                    toast.error(message);
                });
        }

        // Update an existing coupon
        else {
            axios
                .post(`/coupon/update/`, form)
                .then((res) => {
                    window.location.href = '/administration/coupons';
                })
                .catch(function (err) {
                    let message = err.message;
                    if (err.response && err.response.data) {
                        message = err.response.data;
                    }
                    toast.error(message);
                });
        }
    }

    if (valid) {
        return (
            <CouponFormInput
                onSubmit={onSubmit}
                handleSubmit={handleSubmit}
                formState={formState}
                errors={errors}
                register={register}
                setValue={setValue}
                getValues={getValues}
                control={control}
                id={couponId}
                watch={watch}
            />
        );
    } else {
        return <NotFound />;
    }
}

const CouponFormInput = ({
    onSubmit,
    handleSubmit,
    formState,
    errors,
    register,
    setValue,
    getValues,
    control,
    id,
    watch,
}) => {
    const yup = { register, getValues, setValue, errors, watch, control };

    const isEditing = id > 0;

    return (
        <div>
            <div className="card" style={{ maxWidth: 1000 }}>
                <div className="card-body">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <h6>{id ? 'Modify Coupon' : 'Create Coupon'}</h6>
                        <div className="input-panel-container">
                            {isEditing && (
                                <Field
                                    id="username"
                                    yup={yup}
                                    input={{
                                        optional: true,
                                        disabled: isEditing,
                                    }}
                                />
                            )}
                            {!isEditing && (
                                <UserSelectorButton
                                    getValues={getValues}
                                    setValue={setValue}
                                />
                            )}
                            <Field
                                id="code"
                                yup={yup}
                                input={{ textTransform: 'uppercase' }}
                            />
                            <Field
                                id="discount"
                                yup={yup}
                                input={{ number: true, step: 0.01 }}
                            />
                            <Field
                                id="quantity"
                                yup={yup}
                                input={{ disabled: isEditing }}
                            >
                                {[
                                    { id: 0, title: 'Unlimited' },
                                    { id: 1, title: 1 },
                                    { id: 2, title: 2 },
                                    { id: 3, title: 3 },
                                    { id: 4, title: 4 },
                                    { id: 5, title: 5 },
                                ]}
                            </Field>
                        </div>

                        <div className="input-panel-container">
                            <Field id="description" yup={yup} />
                        </div>

                        <div className="input-panel-container">
                            <Field
                                id="start_date"
                                yup={yup}
                                input={{ dateTime: true }}
                            />
                            <Field
                                id="expiration_date"
                                yup={yup}
                                input={{ dateTime: true }}
                            />
                        </div>

                        <hr />
                        <button
                            disabled={formState.isSubmitting}
                            className="btn btn-primary btn-wide"
                        >
                            {formState.isSubmitting && (
                                <span className="spinner-border spinner-border-sm mr-1"></span>
                            )}
                            Submit
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default CouponForm;

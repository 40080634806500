import { Box, Typography } from '@mui/material';

function ExclusivityIndicator({
    brokerId,
    userExclusive,
    brokerAccess,
    adminAccess,
    auctionInvites = [],
    totalAuctionInvites,
    height,
}) {
    let text = '';

    // Broker Viewing Client Listing
    if (brokerAccess) {
        const brokerClientsOnly = auctionInvites.length === totalAuctionInvites;

        // 1 Client Only
        if (auctionInvites.length === 1 && brokerClientsOnly) {
            const invite = auctionInvites[0].invite;
            const firstName = invite.user?.firstName || invite.firstName;
            const lastName = invite.user?.lastName || invite.lastName;

            text += `Exclusive to ${firstName} ${lastName}`;
        }
        // Broker Exclusive
        else if (auctionInvites.length > 1) {
            text += `${brokerClientsOnly ? 'Exclusive' : 'Available'} to ${
                auctionInvites.length
            } Clients`;

            // Inexclusive to your clients
            if (!brokerClientsOnly) {
                text += ` (${totalAuctionInvites} total)`;
            }
        }
        // Available to all Clients
        else if (brokerId) {
            text += 'Exclusive to All Clients';
        }
    }
    // User Exclusive
    else if (userExclusive) {
        // text = 'Exclusive';
    }
    // Broker Exclusive
    else if (brokerId) {
        // text = 'Exclusive';
    }

    if (!text) {
        return null;
    }

    return (
        <Box
            paddingTop={1}
            paddingBottom={1}
            height={`${height}px`}
            display="flex"
        >
            <Box
                sx={{
                    background:
                        'linear-gradient(90deg, rgba(18, 21, 23, 0.9) 25%, rgba(18, 21, 23, 0) 75%)',
                }}
                display="flex"
                width="100%"
            >
                <Typography
                    fontSize={18}
                    marginTop="auto"
                    marginBottom="auto"
                    marginRight={5}
                    marginLeft={2}
                    color="white"
                    letterSpacing={1.5}
                >
                    {text}
                </Typography>
            </Box>
        </Box>
    );
}

export default ExclusivityIndicator;

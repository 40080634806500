import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

import NotFound from '../../pages/NotFound.js';

import { toast } from 'react-toastify';
import AddressSearch from '../map/AddressSearch.js';

function AddressForm() {
    const { id } = useParams();
    const [valid, setValid] = useState(true);

    const [address, setAddress] = useState();
    const [addressId, setAddressId] = useState();

    const [projects, setProjects] = useState([]);
    const [builders, setBuilders] = useState([]);

    useEffect(() => {
        // Get list of projects
        axios
            .get(`/project/getAll`)
            .then((res) => {
                setProjects(res.data);
            })
            .catch(function () {});

        // Get list of builders
        axios
            .get(`/builder/getAll`)
            .then((res) => {
                setBuilders(res.data);
            })
            .catch(function () {});

        // Get existing address data (if you are modifying one)
        if (id) {
            axios
                .post(`/address/get/${id}`)
                .then((res) => {
                    setAddressId(res.data.id);
                    setAddress(res.data);
                })
                .catch(function () {
                    setValid(false);
                });
        }
    }, [id]);

    function onSubmit(form) {
        form.address_id = addressId;

        // Create a new project
        if (!id) {
            axios
                .post(`/address/create/`, form)
                .then((res) => {
                    window.location.href = '/administration/addresses';
                })
                .catch(function (err) {
                    let message = err.message;
                    if (err.response && err.response.data) {
                        message = err.response.data;
                    }
                    toast.error(message);
                });
        }

        // Update an existing project
        else {
            axios
                .post(`/address/update/`, form)
                .then((res) => {
                    window.location.href = '/administration/addresses';
                })
                .catch(function (err) {
                    let message = err.message;
                    if (err.response && err.response.data) {
                        message = err.response.data;
                    }
                    toast.error(message);
                });
        }
    }

    if (valid) {
        return (
            <AddressSearch
                onPick={(location) => onSubmit(location)}
                address={address}
                title={`${id ? 'Modify' : 'Create'} Address`}
                projects={projects}
                builders={builders}
            />
        );
    } else {
        return <NotFound />;
    }
}

export default AddressForm;

import React from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';
import ModalCard from './ModalCard';
import HTMLParse from '../common/HTMLParse';
import { Link } from '@mui/icons-material';

function StatusModal({ show, onClose, values = {} }) {
    const { status, title, body, link, button, action } = values;

    function handleClick() {
        if (link) {
            window.location.href = link;
        } else {
            if (action) {
                action();
            }
            onClose();
        }
    }

    return (
        <Modal
            open={show}
            onClose={onClose}
            onBackdropClick={onClose}
            className="modal-center"
        >
            <ModalCard
                show={show}
                onClose={onClose}
                type={status?.value}
                width={500}
            >
                <Box display="flex" marginBottom={5}>
                    <Typography
                        fontSize={28}
                        fontWeight="medium"
                        paddingTop={0.75}
                        component="div"
                    >
                        {title}
                    </Typography>
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        style={{
                            color: status?.colour,
                            transform: 'scale(1.5)',
                        }}
                        marginLeft={2}
                    >
                        {status?.icon}
                    </Box>
                </Box>
                <Typography
                    fontSize={20}
                    marginBottom={7}
                    lineHeight={1.6}
                    component="div"
                    className="parser"
                >
                    <HTMLParse value={body} />
                </Typography>

                <Button variant="dark" fullWidth onClick={handleClick}>
                    {link && <Link sx={{ marginRight: 1 }} />} {button || 'OK'}
                </Button>
            </ModalCard>
        </Modal>
    );
}

export default StatusModal;

import React, { useState, useEffect } from 'react';
import { Box, Button, CardMedia, Grid, Typography } from '@mui/material';
import {
    TabPanel as TabPanelUnstyled,
    TabsList as TabsListUnstyled,
    Tabs as TabsUnstyled,
    Tab as TabUnstyled,
} from '@mui/base';
import { styled } from '@mui/system';
import GoogleMapReact from 'google-map-react';
import Marker from '../map/Marker';
import CardList from '../common/CardList';
import FileCard from '../cards/FileCard';
import axios from 'axios';
import PropertyCard from '../cards/PropertyCard';
import ScrollContainer from 'react-indiana-drag-scroll';
import mediaTypes, { filterMedia } from '../../constants/mediaTypes';
import DynamicIcon from '../common/DynamicIcon';
import HTMLParse from '../common/HTMLParse';
import PaddedBox from '../common/PaddedBox';
import AdminEditLink from '../administration/AdminEditLink';
import mapStyle from '../../constants/mapStyle';
import NoData from '../common/NoData';
import { Link } from 'react-router-dom';

const NARROW_PADDING = {
    paddingLeft: { xs: 1, md: 5 },
    paddingRight: { xs: 1, md: 5 },
    paddingTop: 5,
    paddingBottom: 5,
};

const ProjectDescription = ({ project }) => {
    if (project) {
        return (
            <Section title="About this Project" value={project.description}>
                <Typography
                    letterSpacing={1.2}
                    lineHeight={1.8}
                    textAlign="center"
                    className="parser"
                    fontFamily="urbanist"
                >
                    <HTMLParse value={project.description} />
                </Typography>
            </Section>
        );
    } else {
        return null;
    }
};

const Section = ({ title, value = {}, children }) => {
    if (value === null || value?.length === 0 || value === false) {
        return null;
    }

    return (
        <Box
            display="flex"
            flexDirection="column"
            marginTop={4}
            marginBottom={10}
        >
            <Typography
                fontSize={26}
                letterSpacing={1.2}
                margin="auto"
                marginBottom={6}
                variant="heading"
            >
                {title}
            </Typography>
            {children}
        </Box>
    );
};

const AddressOverview = ({ address }) => {
    const Pair = ({ title, value }) => {
        return (
            <Grid container padding>
                <Grid item xs={6} paddingRight={5} textAlign="right">
                    <Typography
                        fontSize={17}
                        fontWeight="bold"
                        color="dark"
                        letterSpacing={1.2}
                        lineHeight={1.6}
                        fontFamily="urbanist"
                    >
                        {`${title}:`}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography
                        fontSize={17}
                        color="dark"
                        letterSpacing={1.2}
                        lineHeight={1.6}
                        fontFamily="urbanist"
                    >
                        {value}
                    </Typography>
                </Grid>
            </Grid>
        );
    };

    if (!address) {
        return null;
    }

    return (
        <Section title="Building Overview">
            <Grid container>
                <Pair
                    title="Storeys"
                    value={`${address.storeyCount} Storey${
                        address.storeyCount === 0 || address.storeyCount > 1
                            ? 's'
                            : ''
                    }`}
                />
                <Pair
                    title="Units"
                    value={`${
                        address.unitCount || '---'?.toString().padStart(2, '0')
                    }`}
                />
                <Pair
                    title="Completion Date"
                    value={`${address.completionDate || '---'}`}
                />
                {address.unitSizeMin && address.unitSizeMax && (
                    <Pair
                        title="Unit Size Range"
                        value={`${address.unitSizeMin.toLocaleString()} Sq.Ft. - ${address.unitSizeMax.toLocaleString()} Sq.Ft.`}
                    />
                )}
            </Grid>
        </Section>
    );
};

export const Amenity = ({ item, size = 50 }) => {
    const amenity = item.amenity || item;

    return (
        <Box textAlign="center">
            <DynamicIcon icon={amenity.icon} size={size} />
            <Typography
                fontSize={16}
                lineHeight={1.6}
                textAlign="center"
                margin="auto"
                marginTop={1.5}
                maxWidth={170}
            >
                {amenity.title}
            </Typography>
        </Box>
    );
};

const ProjectAmenities = ({ project }) => {
    return (
        <Section title="Amenities" value={project.projectAmenities}>
            <CardList
                items={project.projectAmenities}
                Component={Amenity}
                xs={6}
                md={4}
                lg={3}
                noCenter
            />
        </Section>
    );
};

const UnitsAvailable = ({ units }) => {
    return (
        <Section
            title="Units Available Within Building"
            value={units.length > 0}
        >
            <Grid container spacing={1} width={300} margin="auto">
                {units.map((unit, i) => (
                    <Grid item xs={6} padding={1} key={i}>
                        <Typography
                            component={Link}
                            to={`/listing/${unit.auction.slug}`}
                            fontSize={17}
                            fontWeight={
                                unit.auction.finished ? undefined : 'medium'
                            }
                            color={
                                unit.auction.finished
                                    ? 'text.secondary'
                                    : 'dark'
                            }
                            letterSpacing={1}
                            textAlign="left"
                            underline="hover"
                            fontFamily="urbanist"
                        >
                            {unit.unit}
                        </Typography>
                    </Grid>
                ))}
            </Grid>
        </Section>
    );
};

const Overview = ({ project, address, units, popout }) => {
    return (
        <Box
            display="flex"
            flexDirection="column"
            sx={{ padding: { xs: 5, lg: '0px 100px 50px' } }}
        >
            <ProjectDescription project={project} />
            <AddressOverview address={address} />
            <UnitsAvailable units={units} />
            {!popout && <ProjectAmenities project={project} />}

            {project && project.websiteUrl && (
                <Button
                    component={Link}
                    to={project.websiteUrl}
                    target="_blank"
                    variant="dark"
                    size="large"
                    sx={{
                        width: { xs: 250, md: 375, lg: 400 },
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        marginBottom: 10,
                        marginTop: 5,
                        textAlign: 'center',
                    }}
                >
                    Visit the Project Website
                </Button>
            )}

            <Builders address={address} />
        </Box>
    );
};

const Media = ({ images, videos }) => {
    const SPACING = 5;

    if (images?.length === 0 && videos?.length === 0) {
        return <NoData />;
    }

    return (
        <Box display="flex" flexDirection="column" sx={NARROW_PADDING}>
            <Grid container spacing={SPACING} marginBottom={SPACING}>
                {images.map((image) => (
                    <Grid
                        item
                        xs={12}
                        md={12}
                        lg={6}
                        margin="auto"
                        key={image.id}
                    >
                        <CardMedia
                            component="img"
                            sx={{ height: { xs: 300, md: 400, lg: 550 } }}
                            image={image.media.url}
                        />
                    </Grid>
                ))}
            </Grid>
            <Grid container spacing={SPACING}>
                {videos.map((video) => (
                    <Grid item xs={12} margin="auto" key={video.id}>
                        <CardMedia
                            component="iframe"
                            height={720}
                            image={`https://www.youtube.com/embed/${video.media.url}`}
                        />
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

const Files = ({ files, setMedia }) => {
    return (
        <Box display="flex" flexDirection="column" sx={NARROW_PADDING}>
            <Section title="Project Files">
                <CardList
                    items={files}
                    Component={FileCard}
                    xs={12}
                    md={6}
                    lg={4}
                    setMedia={setMedia}
                />
            </Section>
        </Box>
    );
};

function handleLoaded(map, maps, addresses) {
    // Only adjust position/zoom if displaying mulitiple addresses
    if (addresses?.length > 1) {
        // Set map bounds to what's needed to display all addresses
        var bounds = new maps.LatLngBounds();
        for (var i = 0; i < addresses?.length; i++) {
            bounds.extend({ lat: addresses[i].lat, lng: addresses[i].lng });
        }

        map.fitBounds(bounds);
    }
}

const Location = ({ address, project }) => {
    const points = [];

    var center = { lat: 0, lng: 0 };

    // Auction page (single address)
    if (address) {
        const point = { lat: address.lat, lng: address.lng };
        points.push(point);
        center = point;
    }
    // Project page (1 or more addresses)
    else if (project?.addresses?.length > 0) {
        const length = project.addresses.length;
        for (let i = 0; i < length; i++) {
            const _address = project.addresses[i];
            const lat = _address.lat;
            const lng = _address.lng;

            points.push({ lat: lat, lng: lng });
            center.lat += lat;
            center.lng += lng;
        }
        center.lat /= length;
        center.lng /= length;
    }

    return (
        <Box
            display="flex"
            flexDirection="column"
            sx={NARROW_PADDING}
            height="calc(100vh - 200px)"
        >
            <GoogleMapReact
                bootstrapURLKeys={{
                    key: process.env.REACT_APP_GOOGLE_MAPS_KEY,
                    libraries: ['places'],
                }}
                center={center}
                defaultZoom={18}
                options={{
                    gestureHandling: 'greedy',
                    fullscreenControl: false,
                    streetViewControl: true,
                    styles: mapStyle,
                }}
                yesIWantToUseGoogleMapApiInternals={true}
                onGoogleApiLoaded={({ map, maps }) =>
                    handleLoaded(map, maps, project?.addresses)
                }
            >
                {points.map((point, i) => (
                    <Marker key={i} lat={point.lat} lng={point.lng} />
                ))}
            </GoogleMapReact>
        </Box>
    );
};

const Builders = ({ address }) => {
    const Builder = ({ builder }) => {
        return (
            <Grid
                item
                xs={12}
                display="flex"
                alignItems="center"
                sx={{
                    flexDirection: {
                        xs: 'column-reverse',
                        md: 'row',
                        lg: 'row',
                    },
                }}
            >
                <Grid
                    item
                    xs={12}
                    md={5}
                    sx={{
                        paddingRight: { xs: 0, md: 10, lg: 15 },
                        paddingTop: { xs: 5, md: 0 },
                    }}
                >
                    <CardMedia
                        component="img"
                        padding={5}
                        width={200}
                        height={200}
                        image={builder.logo.url}
                        sx={{
                            objectFit: 'contain',
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={7} flex={1}>
                    <Typography
                        fontSize={18}
                        color="dark"
                        letterSpacing={1.2}
                        marginBottom={2}
                        sx={{ textAlign: { xs: 'center', md: 'left' } }}
                        fontWeight="bold"
                    >
                        {`About ${builder.title}`}
                    </Typography>
                    <Typography
                        letterSpacing={1.2}
                        lineHeight={1.8}
                        className="parser"
                        sx={{ textAlign: { xs: 'center', md: 'left' } }}
                        fontFamily="urbanist"
                    >
                        <HTMLParse value={builder.description} />
                    </Typography>
                </Grid>
            </Grid>
        );
    };

    if (address?.addressBuilders.length > 0) {
        const PADDING_SIDE = { xs: 0, md: 7, lg: 10 };
        return (
            <Section title="Developer" value={address.addressBuilders}>
                <Box
                    display="flex"
                    flexDirection="column"
                    sx={{
                        paddingLeft: PADDING_SIDE,
                        paddingRight: PADDING_SIDE,
                    }}
                >
                    <Grid container spacing={18}>
                        {address.addressBuilders.map((addressBuilder) => (
                            <Builder
                                key={addressBuilder.id}
                                builder={addressBuilder.builder}
                            />
                        ))}
                    </Grid>
                </Box>
            </Section>
        );
    } else {
        return null;
    }
};

const Banner = ({ project }) => {
    const logo = project?.logo?.url;
    const image = project?.projectMedia[0]?.media?.url;

    if (image) {
        return (
            <div style={{ position: 'relative' }}>
                <div className="project-banner-overlay" />
                {logo && (
                    <CardMedia
                        component="img"
                        sx={{
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            objectFit: 'contain',
                            padding: { xs: 6, md: 10, lg: 12 },
                        }}
                        image={logo}
                    />
                )}
                <CardMedia
                    component="img"
                    sx={{
                        height: { xs: 220, md: 300, lg: 360 },
                        width: '100%',
                    }}
                    image={image}
                />
            </div>
        );
    } else {
        return null;
    }
};

const Auctions = ({ auctions, popout }) => {
    return (
        <PaddedBox bottom={6.25} disabled={!popout}>
            <CardList
                items={auctions}
                Component={PropertyCard}
                lg={6}
                padding={1}
                vertical={8}
                spacing={6.25}
            >
                <NoData
                    title="There are currently no live listings in this project"
                    /*description="For Future Updates, Please Follow Above."*/
                    description=""
                />
            </CardList>
        </PaddedBox>
    );
};

const Tab = styled(TabUnstyled)`
    font-family: superior-title, sans-serif;
    color: #212529;
    border: 1px #212529 solid;
    cursor: pointer;
    font-size: 22px;
    background-color: white;
    width: 100%;
    padding: 15px 5px 12px;
    margin: 5px;
    display: flex;
    justify-content: center;
    min-width: 120px;
    border-radius: 50px;

    &:hover {
        background-color: #f1f1f1;
    }

    &.Mui-selected {
        background-color: #212529;
        color: white;
    }
    &.base--selected {
        background-color: #212529;
        color: white;
    }
`;

const TabsList = styled(TabsListUnstyled)`
    display: flex;
    align-items: center;
    align-content: space-between;
`;

function AddressDetails({
    address,
    project,
    setMedia,
    popout,
    auctionTab,
    overviewTab = true,
    mediaTab,
    filesTab,
    locationTab = true,
    defaultTabIndex = 0,
}) {
    const [images, setImages] = useState([]);
    const [videos, setVideos] = useState([]);
    const [files, setFiles] = useState([]);
    const [units, setUnits] = useState([]);
    const [auctions, setAuctions] = useState();

    if (!project) {
        project = address?.project;
    }

    if (!address && project?.addresses?.length === 1) {
        address = project.addresses[0];
    }

    useEffect(() => {
        // Gather image media
        setImages(filterMedia(project?.projectMedia, [mediaTypes.IMAGE]));

        // Gather video media
        setVideos(filterMedia(project?.projectMedia, [mediaTypes.VIDEO]));

        // Gather file media
        setFiles(filterMedia(project?.projectMedia, mediaTypes.FILES));

        // Get units available at the address
        if (overviewTab && address?.id) {
            // axios
            //     .get(`/property/getUnitsWithin/${address.id}`)
            //     .then((res) => {
            //         setUnits(res.data);
            //     })
            //     .catch(function () {});
        }

        // Get auctions of this project
        if (auctionTab) {
            axios
                .post('/auction/get', { projectSlug: project.slug })
                .then((res) => {
                    setAuctions(res.data.rows);
                })
                .catch(function () {});
        }
    }, [project, address]);

    const TAB_PADDING = { xs: 0, md: 2, lg: '50px' };

    return (
        <Box
            sx={{
                overflow: 'hidden',
                borderRadius: 0, //popout ? '55px 55px 30px 30px' : 0,
                boxShadow: popout ? '0px 2px 2px 1px #00000020' : '0px',
                position: 'relative',
            }}
        >
            <AdminEditLink section="projects" id={project.slug} />
            <Banner project={project} />
            <PaddedBox disabled={popout}>
                <Box>
                    <TabsUnstyled defaultValue={defaultTabIndex}>
                        <ScrollContainer style={{ margin: 5 }}>
                            <TabsList
                                sx={{
                                    paddingTop: TAB_PADDING,
                                    paddingLeft: TAB_PADDING,
                                    paddingRight: TAB_PADDING,
                                    paddingBottom: TAB_PADDING,
                                }}
                            >
                                {auctionTab && <Tab value={0}>Listings</Tab>}
                                {overviewTab && <Tab value={1}>Overview</Tab>}
                                {mediaTab && <Tab value={2}>Media</Tab>}
                                {filesTab && <Tab value={3}>Files</Tab>}
                                {locationTab &&
                                    (address ||
                                        project?.addresses?.length > 0) && (
                                        <Tab value={4}>Location</Tab>
                                    )}
                            </TabsList>
                        </ScrollContainer>
                        <TabPanelUnstyled value={0}>
                            <Auctions auctions={auctions} popout={popout} />
                        </TabPanelUnstyled>
                        <TabPanelUnstyled value={1}>
                            <Overview
                                address={address}
                                project={project}
                                units={units}
                                popout={popout}
                            />
                        </TabPanelUnstyled>
                        <TabPanelUnstyled value={2}>
                            <Media images={images} videos={videos} />
                        </TabPanelUnstyled>
                        <TabPanelUnstyled value={3}>
                            <Files files={files} setMedia={setMedia} />
                        </TabPanelUnstyled>
                        <TabPanelUnstyled value={4}>
                            <Location address={address} project={project} />
                        </TabPanelUnstyled>
                    </TabsUnstyled>
                </Box>
            </PaddedBox>
        </Box>
    );
}

export default React.memo(AddressDetails);

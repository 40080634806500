import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import CollapseContainer from '../common/CollapseContainer';
import { DataGrid } from '@mui/x-data-grid';
import { Tooltip, IconButton } from '@mui/material';
import { getRow } from '../../scripts/table';

import YesIcon from '@mui/icons-material/CheckCircle';
import NoIcon from '@mui/icons-material/Error';
import PopupForm from '../common/PopupForm';
import restrictions from '../../constants/restrictions';
import DynamicIcon from '../common/DynamicIcon';

function UserList() {
    const [data, setData] = useState([]);
    const [showBrokerDialog, setShowBrokerDialog] = useState(false);
    const [showMFADialog, setShowMFADialog] = useState(false);

    const [showDisableUser, setShowDisableUser] = useState(false);
    const [showEnableUser, setShowEnableUser] = useState(false);

    const [showCreateRestriction, setShowCreateRestriction] = useState(false);
    const [showResolveRestriction, setShowResolveRestriction] = useState(false);
    const [showVerificationDialog, setShowVerificationDialog] = useState(false);
    const [restrictionAction, setRestrictionAction] = useState();

    const [row, setRow] = useState();

    useEffect(() => {
        axios
            .get(`/user/getAdmin`)
            .then((res) => {
                setData(res.data);
            })
            .catch(function () {});
    }, []);

    const toggleBroker = (row) => {
        axios
            .post(`/broker/toggle`, {
                username: row.username,
            })
            .then((res) => {
                setShowBrokerDialog(false);

                // Show updated status in table
                const users = [...data];
                const index = users.findIndex((item) => item.id === row.id);
                users[index].broker.disabled = !users[index].broker.disabled;
                setData(users);
            })
            .catch(function () {});
    };

    const handleDisableUser = (row) => {
        axios
            .post(`/user/disable`, {
                username: row.username,
            })
            .then((res) => {
                setShowDisableUser(false);

                // Show updated status in table
                const users = [...data];
                const index = users.findIndex((item) => item.id === row.id);
                users[index].disabled = true;
                setData(users);
            })
            .catch(function () {});
    };

    const handleEnableUser = (row) => {
        axios
            .post(`/user/enable`, {
                username: row.username,
            })
            .then((res) => {
                setShowEnableUser(false);

                // Show updated status in table
                const users = [...data];
                const index = users.findIndex((item) => item.id === row.id);
                users[index].disabled = false;
                setData(users);
            })
            .catch(function () {});
    };

    function handleCreateRestriction(userId, restrictionId, column) {
        axios
            .post(`/restriction/create`, { userId, restrictionId })
            .then((res) => {
                setShowCreateRestriction(false);

                // Show updated status in table
                const users = [...data];
                const index = users.findIndex((item) => item.id === userId);
                users[index][column] = false;
                setData(users);
            })
            .catch(function () {});
    }

    function handleResolveRestriction(userId, restrictionId, column) {
        axios
            .post(`/restriction/resolve`, { userId, restrictionId })
            .then((res) => {
                setShowResolveRestriction(false);

                // Show updated status in table
                const users = [...data];
                const index = users.findIndex((item) => item.id === userId);
                users[index][column] = true;
                setData(users);
            })
            .catch(function () {});
    }

    function handleResetMFA(row) {
        axios
            .post(`/user/resetMFA`, {
                uuid: row.uuid,
            })
            .then((res) => {
                setShowMFADialog(false);

                // Show updated status in table
                const users = [...data];
                const index = users.findIndex((item) => item.id === row.id);
                users[index].mfa = null;
                setData(users);
            })
            .catch(function () {});
    }

    function handleExpireIDV(row) {
        axios
            .post(`/user/expireIDV`, {
                username: row.username,
            })
            .then((res) => {
                setShowVerificationDialog(false);

                // Show updated status in table
                const users = [...data];
                const index = users.findIndex((item) => item.id === row.id);
                users[index].verification = res.data;
                setData(users);
            })
            .catch(function () {});
    }

    const getMFA = (row) => {
        axios
            .post(`/user/getMFA`, {
                uuid: row.uuid,
            })
            .then((res) => {
                // Update MFA value from what was retrieved
                const users = [...data];
                const index = users.findIndex((item) => item.id === row.id);
                users[index].mfa = res.data;
                setData(users);
            })
            .catch(function () {});
    };

    const columns = [
        {
            field: 'id',
            headerName: 'ID',
            width: 50,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'uuid',
            headerName: 'UUID',
            width: 290,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'username',
            headerName: 'Username',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'email',
            headerName: 'Email',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'phoneNumber',
            headerName: 'Phone Number',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
        },
        // {
        //     field: 'customerId',
        //     headerName: 'Customer ID',
        //     flex: 1,
        //     headerAlign: 'center',
        //     align: 'center',
        // },
        {
            field: 'role',
            headerName: 'Role',
            width: 130,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'createdAt',
            headerName: 'Created At',
            width: 140,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'verified',
            headerName: 'Verified',
            width: 70,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                const onClick = (e) => {
                    setRow(getRow(e, params));
                    setShowVerificationDialog(true);
                };

                // Determine broker status
                const row = data.filter((a) => a.id === params.row.id)[0];
                const { verification, verified } = row;

                if (verified) {
                    if (verification.allowVerification) {
                        return (
                            <Tooltip
                                title={`Expires ${verification.expirationDate}, Reverification ${verification.reverificationDate}`}
                            >
                                <IconButton onClick={onClick}>
                                    <NoIcon
                                        style={{
                                            color: '#ee3333',
                                        }}
                                    />
                                </IconButton>
                            </Tooltip>
                        );
                    } else {
                        return (
                            <Tooltip
                                title={`Expires ${verification.reverificationDate}, Reverification ${verification.reverificationDate}`}
                            >
                                <IconButton onClick={onClick}>
                                    <YesIcon
                                        style={{
                                            color: '#32bf3e',
                                        }}
                                    />
                                </IconButton>
                            </Tooltip>
                        );
                    }
                } else {
                    return '';
                }
            },
            sortable: false,
        },
        {
            field: 'broker',
            headerName: 'Broker',
            width: 70,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                const onClick = (e) => {
                    setRow(getRow(e, params));
                    setShowBrokerDialog(true);
                };

                // Determine broker status
                const broker = data.filter((a) => a.id === params.row.id)[0]
                    .broker;

                if (broker) {
                    if (broker.disabled) {
                        return (
                            <Tooltip title="Enable Broker">
                                <IconButton onClick={onClick}>
                                    <NoIcon
                                        style={{
                                            color: '#ee3333',
                                        }}
                                    />
                                </IconButton>
                            </Tooltip>
                        );
                    } else {
                        return (
                            <Tooltip title="Disable Broker">
                                <IconButton onClick={onClick}>
                                    <YesIcon
                                        style={{
                                            color: '#32bf3e',
                                        }}
                                    />
                                </IconButton>
                            </Tooltip>
                        );
                    }
                }

                return null;
            },
            sortable: false,
        },
        {
            field: 'mfa',
            headerName: 'MFA',
            width: 60,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                const onClick = (e) => {
                    // Get MFA status
                    if (params.row.mfa === undefined) {
                        getMFA(params.row);
                    }
                    // Toggle MFA
                    else {
                        setRow(getRow(e, params));
                        setShowMFADialog(true);
                    }
                };

                // Determine broker status
                const mfa = data.filter((a) => a.id === params.row.id)[0].mfa;

                if (mfa === undefined) {
                    return (
                        <Tooltip title="Get MFA Status">
                            <IconButton onClick={onClick}>
                                <DynamicIcon
                                    icon="Autorenew"
                                    colour="status.info"
                                />
                            </IconButton>
                        </Tooltip>
                    );
                } else if (mfa) {
                    return (
                        <Tooltip title="Reset MFA">
                            <IconButton onClick={onClick}>
                                <YesIcon
                                    style={{
                                        color: '#32bf3e',
                                    }}
                                />
                            </IconButton>
                        </Tooltip>
                    );
                }

                return (
                    <NoIcon
                        style={{
                            color: '#ee3333',
                        }}
                    />
                );
            },
            sortable: false,
        },
        {
            field: 'canComment',
            headerName: 'Comment',
            width: 80,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                const onClick = (e) => {
                    const row = getRow(e, params);
                    setRow(row);
                    if (row.canComment === false) {
                        setRestrictionAction(() => () => {
                            handleResolveRestriction(
                                row.id,
                                restrictions.COMMENT.id,
                                'canComment'
                            );
                        });
                        setShowResolveRestriction(true);
                    } else {
                        setRestrictionAction(() => () => {
                            handleCreateRestriction(
                                row.id,
                                restrictions.COMMENT.id,
                                'canComment'
                            );
                        });
                        setShowCreateRestriction(true);
                    }
                };

                const canComment = data.filter((a) => a.id === params.row.id)[0]
                    .canComment;

                if (canComment === false) {
                    return (
                        <Tooltip title="Enable Commenting">
                            <IconButton onClick={onClick}>
                                <NoIcon
                                    style={{
                                        color: '#ee3333',
                                    }}
                                />
                            </IconButton>
                        </Tooltip>
                    );
                } else {
                    return (
                        <Tooltip title="Disable Commenting">
                            <IconButton onClick={onClick}>
                                <YesIcon
                                    style={{
                                        color: '#32bf3e',
                                    }}
                                />
                            </IconButton>
                        </Tooltip>
                    );
                }
            },
            sortable: false,
        },
        {
            field: 'canBid',
            headerName: 'Bid',
            width: 60,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                const onClick = (e) => {
                    const row = getRow(e, params);
                    setRow(row);
                    if (row.canBid === false) {
                        setRestrictionAction(() => () => {
                            handleResolveRestriction(
                                row.id,
                                restrictions.BID.id,
                                'canBid'
                            );
                        });
                        setShowResolveRestriction(true);
                    } else {
                        setRestrictionAction(() => () => {
                            handleCreateRestriction(
                                row.id,
                                restrictions.BID.id,
                                'canBid'
                            );
                        });
                        setShowCreateRestriction(true);
                    }
                };

                const canBid = data.filter((a) => a.id === params.row.id)[0]
                    .canBid;

                if (canBid === false) {
                    return (
                        <Tooltip title="Enable Bidding">
                            <IconButton onClick={onClick}>
                                <NoIcon
                                    style={{
                                        color: '#ee3333',
                                    }}
                                />
                            </IconButton>
                        </Tooltip>
                    );
                } else {
                    return (
                        <Tooltip title="Disable Bidding">
                            <IconButton onClick={onClick}>
                                <YesIcon
                                    style={{
                                        color: '#32bf3e',
                                    }}
                                />
                            </IconButton>
                        </Tooltip>
                    );
                }
            },
            sortable: false,
        },
        {
            field: 'disabled',
            headerName: 'Enabled',
            width: 80,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                const onClick = (e) => {
                    setRow(getRow(e, params));
                    params.row.disabled
                        ? setShowEnableUser(true)
                        : setShowDisableUser(true);
                };

                if (params.row.disabled) {
                    return (
                        <Tooltip title="Enable User">
                            <IconButton onClick={onClick}>
                                <NoIcon
                                    style={{
                                        color: '#ee3333',
                                    }}
                                />
                            </IconButton>
                        </Tooltip>
                    );
                } else {
                    return (
                        <Tooltip title="Disable User">
                            <IconButton onClick={onClick}>
                                <YesIcon
                                    style={{
                                        color: '#32bf3e',
                                    }}
                                />
                            </IconButton>
                        </Tooltip>
                    );
                }
            },
            sortable: false,
        },
    ];

    return (
        <div>
            <CollapseContainer title="User List">
                <div style={{ height: 645, width: '100%' }}>
                    <DataGrid
                        rows={data.map((item) => {
                            return {
                                id: item.id,
                                uuid: item.uuid,
                                username: item.username,
                                email: item.email,
                                customerId: item.customerId,
                                phoneNumber: item.phoneNumber,
                                role: item.role,
                                disabled: item.disabled,
                                canBid: item.canBid,
                                canComment: item.canComment,
                                mfa: item.mfa,
                                verified: item.verified,
                                createdAt: moment(item.createdAt).format(
                                    `MMM D[,] h:mm A`
                                ),
                            };
                        })}
                        columns={columns}
                        pageSize={10}
                        rowsPerPageOptions={[10]}
                        /*checkboxSelection*/
                        disableSelectionOnClick
                    />
                </div>
            </CollapseContainer>
            <PopupForm
                title="Toggle broker status?"
                body={
                    <div>
                        Are you sure you want to toggle this user's broker
                        status?
                    </div>
                }
                action={() => toggleBroker(row)}
                show={showBrokerDialog}
                setShow={setShowBrokerDialog}
            />
            <PopupForm
                title="Disable User?"
                body="Are you sure you want to disable this user?"
                action={() => handleDisableUser(row)}
                show={showDisableUser}
                setShow={setShowDisableUser}
            />
            <PopupForm
                title="Enable User?"
                body="Are you sure you want to enable this user?"
                action={() => handleEnableUser(row)}
                show={showEnableUser}
                setShow={setShowEnableUser}
            />
            <PopupForm
                title="Restrict User?"
                body="Are you sure you want to restrict this user?"
                action={restrictionAction}
                show={showCreateRestriction}
                setShow={setShowCreateRestriction}
            />
            <PopupForm
                title="Resolve Restriction?"
                body="Are you sure you want to resolve this restriction?"
                action={restrictionAction}
                show={showResolveRestriction}
                setShow={setShowResolveRestriction}
            />
            <PopupForm
                title="Reset MFA?"
                body={
                    <div>
                        <p>Are you sure you want to reset this user's MFA?</p>
                        <ul>
                            <li>
                                On their next sign-in they will be able to
                                choose a new phone number.
                            </li>
                            <li>
                                This will sign them out of all existing
                                sessions.
                            </li>
                            <li>
                                If their account has been comprimised, they
                                should change their password using the "Forgot
                                Password" process BEFORE resetting MFA.
                            </li>
                        </ul>
                    </div>
                }
                action={() => handleResetMFA(row)}
                show={showMFADialog}
                setShow={setShowMFADialog}
            />
            <PopupForm
                title="Expire IDV?"
                body="Are you sure you want to expire this user's ID verification?"
                action={() => handleExpireIDV(row)}
                show={showVerificationDialog}
                setShow={setShowVerificationDialog}
            />
        </div>
    );
}

export default UserList;

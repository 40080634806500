import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, IconButton, Modal } from '@mui/material';
import axios from 'axios';
import moment from 'moment';
import { DataGrid } from '@mui/x-data-grid';
import { toast } from 'react-toastify';
import PopupForm from '../../components/common/PopupForm';
import TableContainer from '../../components/common/TableContainer';
import { booleanCell } from '../../components/common/Table';
import { provinces } from '../../scripts/validation';
import { getBrokers } from '../../scripts/cms';

function Invite() {
    return (
        <Box>
            <RegistrationRequests />
            <SentInvites />
        </Box>
    );
}

const RegistrationRequests = () => {
    const [showManual, setShowManual] = useState(false);

    function handleSubmitManual(form) {
        axios
            .post(`/invite/createManual`, form)
            .then((res) => {
                setShowManual(false);
            })
            .catch(function (err) {
                toast.error(err?.response?.data?.message);
            });
    }

    return (
        <Box width={1} marginBottom={3}>
            <Typography fontWeight="bold" fontSize={22} marginBottom={2}>
                Manual Invitation
            </Typography>
            <Button variant="dark" onClick={() => setShowManual(true)}>
                Create Invitation
            </Button>
            <PopupForm
                width={800}
                title="Create Invitation"
                yes="Send"
                show={showManual}
                setShow={setShowManual}
                action={handleSubmitManual}
                defaults={{ country: 'Canada', province: 'ON' }}
                inputs={[
                    [{ id: 'email' }],
                    [{ id: 'firstName' }, { id: 'lastName' }],
                    [
                        {
                            id: 'isBroker',
                            toggle: {
                                toggleUser: {
                                    id: 'toggleUser',
                                    label: 'User',
                                    inputs: [
                                        [
                                            {
                                                id: 'brokerPairingId',
                                                source: getBrokers,
                                                label: 'Broker',
                                                optional: true,
                                                table: {
                                                    columns: [
                                                        {
                                                            field: 'firstName',
                                                            headerName:
                                                                'First Name',
                                                        },
                                                        {
                                                            field: 'lastName',
                                                            headerName:
                                                                'Last Name',
                                                        },
                                                        {
                                                            field: 'email',
                                                            headerName: 'Email',
                                                        },
                                                    ],
                                                    nullable: true,
                                                },
                                            },
                                        ],
                                    ],
                                },
                                toggleBroker: {
                                    id: 'toggleBroker',
                                    label: 'Broker',
                                    inputs: [
                                        [
                                            { id: 'brokerage' },
                                            {
                                                id: 'brokeragePhone',
                                                label: 'Brokerage Phone Number',
                                                mask: '+99999999999',
                                            },
                                        ],
                                        [
                                            {
                                                id: 'brokerageFax',
                                                label: 'Brokerage Fax Number',
                                                optional: true,
                                            },
                                            {
                                                id: 'brokerEmail',
                                                label: 'Broker Email',
                                            },
                                        ],
                                        [
                                            {
                                                id: 'title',
                                                label: 'Broker Title',
                                            },
                                            { id: 'brokerFirstName' },
                                        ],
                                        [
                                            {
                                                id: 'brokerMiddleName',
                                                optional: true,
                                            },
                                            { id: 'brokerLastName' },
                                        ],
                                        [
                                            {
                                                id: 'country',
                                                disabled: true,
                                            },
                                            {
                                                id: 'province',
                                                children: provinces,
                                            },
                                        ],
                                        [
                                            { id: 'city' },
                                            {
                                                id: 'postalCode',
                                                mask: 'a9a 9a9',
                                            },
                                        ],
                                        [
                                            {
                                                id: 'streetAddress',
                                                label: 'Address',
                                            },
                                            {
                                                id: 'unit',
                                                optional: true,
                                            },
                                        ],
                                        [
                                            {
                                                id: 'adminEmail',
                                            },
                                            {
                                                id: 'adminFirstName',
                                            },
                                        ],
                                        [
                                            {
                                                id: 'adminMiddleName',
                                                optional: true,
                                            },
                                            {
                                                id: 'adminLastName',
                                            },
                                        ],
                                    ],
                                },
                            },
                        },
                    ],
                ]}
            />
        </Box>
    );
};

const SentInvites = () => {
    const [data, setData] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);

    useEffect(() => {
        axios
            .post(`/invite/getInvites`)
            .then((res) => {
                setData(res.data);
            })
            .catch(function () {});
    }, []);

    function handleDelete() {
        axios
            .post(`/invite/deleteInvites`, selectedRows)
            .then((res) => {
                // Remove items from local data too
                setData((current) =>
                    current.filter((item) => !selectedRows.includes(item.id))
                );
            })
            .catch(function () {});
    }

    function handleClear() {
        setSelectedRows([]);
    }

    return (
        <Box width={1}>
            <TableContainer
                title="Sent Invitations"
                selectedRows={selectedRows}
                buttons={[
                    {
                        label: 'Delete',
                        variant: 'danger',
                        action: handleDelete,
                    },
                    {
                        label: 'Clear Selection',
                        action: handleClear,
                    },
                ]}
            >
                <SentInviteTable
                    data={data}
                    selectedRows={selectedRows}
                    setSelectedRows={setSelectedRows}
                />
            </TableContainer>
        </Box>
    );
};

const SentInviteTable = ({ data, selectedRows, setSelectedRows }) => {
    const columnsComment = [
        {
            field: 'id',
            headerName: 'ID',
            width: 75,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'email',
            headerName: 'Email',
            flex: 1,
            minWidth: 220,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'firstName',
            headerName: 'First Name',
            flex: 1,
            minWidth: 120,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'lastName',
            headerName: 'Last Name',
            flex: 1,
            minWidth: 120,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'createdAt',
            headerName: 'Created At',
            width: 140,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'isBroker',
            headerName: 'Broker',
            width: 90,
            headerAlign: 'center',
            align: 'center',
            renderCell: booleanCell,
        },
        {
            field: 'accepted',
            headerName: 'Accepted',
            width: 90,
            headerAlign: 'center',
            align: 'center',
            renderCell: booleanCell,
        },
    ];

    return (
        <Box height={645}>
            <DataGrid
                rows={data.map((item) => {
                    return {
                        id: item.id,
                        email: item.email,
                        firstName: item.firstName,
                        lastName: item.lastName,
                        isBroker: item.brokerId > 0,
                        accepted: item.accepted,
                        createdAt: moment(item.createdAt).format(
                            `MMM D[,] h:mm A`
                        ),
                    };
                })}
                columns={columnsComment}
                pageSize={10}
                rowsPerPageOptions={[10]}
                disableSelectionOnClick
                checkboxSelection
                selectionModel={selectedRows}
                onSelectionModelChange={(ids) => {
                    setSelectedRows(ids);
                }}
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'id', sort: 'desc' }],
                    },
                }}
                sx={{ border: 'none' }}
                isRowSelectable={(params) => params.row.accepted === false}
            />
        </Box>
    );
};

export default Invite;

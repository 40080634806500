import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, IconButton, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { Amenity } from '../../components/auction/AddressDetails';
import CardList from '../../components/common/CardList';
import CollapseContainer from '../../components/common/CollapseContainer';
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment';
import DynamicIcon from '../../components/common/DynamicIcon';
import { Link } from 'react-router-dom';

function Amenities() {
    const [data, setData] = useState([]);

    useEffect(() => {
        axios
            .get(`/amenity/getAdmin`)
            .then((res) => {
                setData(res.data);
            })
            .catch(function () {});
    }, []);

    const columns = [
        {
            field: 'id',
            headerName: 'ID',
            width: 50,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'title',
            headerName: 'Title',
            flex: 2,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'icon',
            headerName: 'Icon',
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            renderCell: (params) => {
                const { icon } = data.filter((a) => a.id === params.row.id)[0];

                return <DynamicIcon icon={icon} />;
            },
            sortable: false,
        },
        {
            field: 'createdAt',
            headerName: 'Created At',
            width: 120,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'edit',
            headerName: 'Edit',
            width: 90,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                return (
                    <IconButton
                        component={Link}
                        to={`/administration/amenities/create/${params.row.id}`}
                    >
                        <EditIcon style={{ color: '#166cdb' }} />
                    </IconButton>
                );
            },
            sortable: false,
        },
    ];

    return (
        <div>
            <CollapseContainer title={`Amenity List (${data?.length || 0})`}>
                <div style={{ height: 410, width: '100%' }}>
                    <DataGrid
                        rows={data.map((item) => {
                            return {
                                id: item.id,
                                title: item.title,
                                createdAt: moment(item.createdAt).format(
                                    `MMM D[,] h:mm A`
                                ),
                            };
                        })}
                        columns={columns}
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                        disableSelectionOnClick
                    />
                </div>
            </CollapseContainer>

            <Box backgroundColor="white" padding={3}>
                <Typography fontSize={20} fontWeight="medium">
                    {`Amenities (${data?.length || 0})`}
                </Typography>
                <CardList
                    items={data}
                    Component={Amenity}
                    lg={2}
                    md={4}
                    xs={6}
                    padding={0}
                    noCenter
                />
            </Box>
        </div>
    );
}

export default Amenities;

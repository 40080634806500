import { useRef, useState } from 'react';
import {
    Box,
    Button,
    Divider,
    Grid,
    IconButton,
    Tab,
    Tabs,
    Tooltip,
    Typography,
} from '@mui/material';
import CustomTabPanel from './CustomTabPanel';

function Panels({ panels: _panels, config = {}, maxHeight, discrete = true }) {
    const [tab, setTab] = useState(0);
    const tabsRef = useRef(null);

    const panels = [];
    for (let i in _panels) {
        const panel = _panels[i];

        // Admin
        if (panel.admin && !config.admin) {
            continue;
        }

        // Hide un-used
        if (!panel.children) {
            continue;
        }

        panels.push(panel);
    }

    // Nothing to show
    if (panels.length === 0) {
        return null;
    }

    return (
        <>
            {(panels.length > 1 || !discrete) && (
                <Tabs
                    value={tab}
                    onChange={(e, value) => setTab(value)}
                    variant="fullWidth"
                    ref={tabsRef}
                >
                    {panels.map((panel) => (
                        <Tab
                            key={panel.index}
                            label={panel.label}
                            value={panel.index}
                        />
                    ))}
                </Tabs>
            )}

            <Box
                display="flex"
                flexDirection="column"
                overflow="hidden"
                height={
                    maxHeight
                        ? maxHeight - tabsRef?.current?.clientHeight
                        : undefined
                }
            >
                <Box
                    padding={1}
                    paddingLeft={2}
                    paddingRight={2}
                    sx={{ overflowY: 'auto' }}
                >
                    {panels.map((panel) => (
                        <CustomTabPanel
                            key={panel.index}
                            value={tab}
                            index={panel.index}
                        >
                            {panel.children}
                        </CustomTabPanel>
                    ))}
                </Box>
            </Box>
        </>
    );
}

export default Panels;

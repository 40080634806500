import { Box, IconButton, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { dateCell } from './Table';
import DynamicIcon from './DynamicIcon';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useState } from 'react';
import PopupForm from './PopupForm';
import { Link } from 'react-router-dom';

function AuctionTable({ auctions, title, allowSync }) {
    const [showSync, setShowSync] = useState(false);

    function sync(row) {
        axios
            .post(`/event/sync`, {
                propertyId: row.propertyId,
                auctionId: row.id,
            })
            .then((res) => {
                toast.success('Sync successful');
                setShowSync(false);
            })
            .catch((err) => {
                toast.error(
                    `Sync unsuccessful (ID: ${row.id}) - ${err.message}`
                );
                setShowSync(false);
            });
    }

    const columns = [
        {
            field: 'id',
            headerName: 'ID',
            width: 75,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'groupId',
            headerName: 'GID',
            width: 75,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'project',
            headerName: 'Project',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'title',
            headerName: 'Title',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'unitNumber',
            headerName: 'Unit #',
            width: 100,
            headerAlign: 'center',
            align: 'center',
            type: 'number',
        },
        {
            field: 'floorNumber',
            headerName: 'Floor',
            width: 80,
            headerAlign: 'center',
            align: 'center',
            type: 'number',
        },
        {
            field: 'amount',
            headerName: 'Amount',
            width: 160,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'startDate',
            headerName: 'Start Date',
            width: 180,
            headerAlign: 'center',
            align: 'center',
            renderCell: dateCell,
        },
        {
            field: 'endDate',
            headerName: 'End Date',
            width: 180,
            headerAlign: 'center',
            align: 'center',
            renderCell: dateCell,
        },
        ...(allowSync
            ? [
                  {
                      field: 'sync',
                      headerName: 'Sync Events',
                      width: 110,
                      headerAlign: 'center',
                      align: 'center',
                      sortable: false,
                      renderCell: (params) => {
                          return (
                              <IconButton
                                  onClick={() => setShowSync(params.row)}
                              >
                                  <DynamicIcon icon="Sync" colour="#ee3333" />
                              </IconButton>
                          );
                      },
                  },
              ]
            : []),
        {
            field: 'view',
            headerName: 'View',
            width: 90,
            headerAlign: 'center',
            align: 'center',
            sortable: false,
            renderCell: (params) => {
                return (
                    <Link to={`/listing/${params.row.slug}`}>
                        <IconButton>
                            <DynamicIcon icon="Link" colour="#166cdb" />
                        </IconButton>
                    </Link>
                );
            },
        },
        {
            field: 'edit',
            headerName: 'Edit',
            width: 90,
            headerAlign: 'center',
            align: 'center',
            sortable: false,
            renderCell: (params) => {
                return (
                    <Link
                        to={`/administration/listings/create/${params.row.slug}`}
                    >
                        <IconButton>
                            <DynamicIcon icon="Edit" colour="#166cdb" />
                        </IconButton>
                    </Link>
                );
            },
        },
    ];

    return (
        <Box backgroundColor="black" borderRadius="10px">
            <Typography
                fontSize={18}
                padding={1.5}
                fontWeight="medium"
                color="white"
            >
                {title}
            </Typography>
            <Box backgroundColor="white">
                <DataGrid
                    rows={auctions.map((auction) => {
                        return {
                            ...auction.property,
                            ...auction,
                            groupId:
                                auction.auctionHistory?.auctionHistoryGroup?.id,
                            amount: `$${(auction.sold
                                ? auction.bids[0].amount
                                : +auction.minimumBid
                            ).toLocaleString()}`,
                            project: auction.property.address.project.title,
                        };
                    })}
                    columns={columns}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    disableSelectionOnClick
                    initialState={{
                        sorting: {
                            sortModel: [{ field: 'id', sort: 'desc' }],
                        },
                    }}
                    autoHeight
                    sx={{ border: 'none' }}
                />
            </Box>
            <PopupForm
                title="Sync Listing Events?"
                body={
                    <div>
                        <p>
                            Are you sure you want to sync the listing events for
                            this listing to the configured project defaults?
                        </p>
                        <p>
                            This will overwrite any existing events for this
                            unit that have a default configuration for this
                            project.
                        </p>
                    </div>
                }
                action={() => sync(showSync)}
                show={showSync}
                setShow={setShowSync}
            />
        </Box>
    );
}

export default AuctionTable;

import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import * as reactRouterDom from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Home from './pages/Home';
import Auction from './pages/Auction';
import NotFound from './pages/NotFound';
import User from './pages/User';
import Marketplace from './pages/Marketplace';
import CategoryPage from './pages/CategoryPage';
import ProjectPage from './pages/ProjectPage';

import Header from './components/common/Header';
import Administration from './pages/Administration';

import RequireAdmin from './components/routing/RequireAdmin';

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import { ThemeProvider, createTheme } from '@mui/material/styles';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import muiTheme from './muiTheme';
import { Account } from './components/supertokens/Account';
import RawHTML from './pages/RawHTML';

/* eslint import/no-webpack-loader-syntax: off */
import privacyPolicy from 'raw-loader!./assets/html/privacy_policy.html';
/* eslint import/no-webpack-loader-syntax: off */
import termsConditions from 'raw-loader!./assets/html/terms_conditions.html';
import ContactUsPage from './pages/ContactUsPage';
import About from './pages/About';

import SuperTokens, {
    SuperTokensWrapper,
    getSuperTokensRoutesForReactRouterDom,
} from 'supertokens-auth-react';
import EmailPassword from 'supertokens-auth-react/recipe/emailpassword';
import Session, {
    SessionAuth,
    useSessionContext,
} from 'supertokens-auth-react/recipe/session';
import EmailVerification from 'supertokens-auth-react/recipe/emailverification';
import Passwordless from 'supertokens-auth-react/recipe/passwordless';
import SecondFactor, {
    SecondFactorClaim,
} from './components/supertokens/SecondFactor';
import RequireLogin from './components/routing/RequireLogin';
import { isUUID } from './scripts/validation';
import ShowcaseRoute from './components/supertokens/ShowcaseRoute';
import { Box, Divider, Typography } from '@mui/material';
import PostPurchasePage from './pages/PostPurchasePage';
import PurchasePage from './pages/PurchasePage';

import HotglueConfig from '@hotglue/widget';
import RequireDeveloper from './components/routing/RequireDeveloper';
import { loadScripts } from './scripts/scriptLoaders';
import Dashboard from './components/common/Dashboard';
import { getDeveloperProjects } from './scripts/cms';
import PurchaseTracker from './components/purchase/PurchaseTracker';
import RequireBroker from './components/routing/RequireBroker';
import ClientManagement from './components/broker/ClientManagement';
import ExclusiveUnits from './components/broker/ExclusiveUnits';
import NoData from './components/common/NoData';
import BrokerInviteClient from './components/broker/BrokerInviteClient';
import ScrollToTop from './components/common/ScrollToTop';

const superTokensStyle = {
    button: {
        backgroundColor: '#212529',
        border: '0px',
    },
    container: {
        fontFamily: 'urbanist',
    },
    superTokensBranding: {
        display: 'none',
    },
};

SuperTokens.init({
    appInfo: {
        appName: 'Upside',
        apiDomain: process.env.REACT_APP_SERVER,
        websiteDomain: process.env.REACT_APP_CLIENT,
        apiBasePath: '/token',
        websiteBasePath: '/auth',
        apiGatewayPath: process.env.REACT_APP_SERVER_GATEWAY,
    },
    recipeList: [
        EmailVerification.init({
            style: superTokensStyle,
            mode: 'REQUIRED',
        }),
        EmailPassword.init({
            style: superTokensStyle,
            signInAndUpFeature: {
                signInForm: {
                    formFields: [
                        {
                            id: 'email',
                            label: 'Email or Username',
                            placeholder: 'Email or Username',
                            validate: async (value) => {
                                return undefined;
                            },
                        },
                    ],
                },
                signUpForm: {
                    privacyPolicyLink: '/privacy-policy',
                    termsOfServiceLink: '/terms-conditions',
                    formFields: [
                        {
                            id: 'email',
                            label: 'Email',
                            placeholder: 'Email Address',
                        },
                        {
                            id: 'password',
                            validate: async (value) => {
                                // 8 characters, 1 upper, 1 lower, 1 number
                                const pattern =
                                    /(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).{8,}$/;
                                return pattern.test(value)
                                    ? undefined
                                    : 'This does not meet the minimum password requirements. Passwords are required to be at least 8 characters long and contain 1 upper case character, 1 lower case character, and 1 number.';
                            },
                        },
                        {
                            id: 'username',
                            label: 'Username',
                            validate: async (value) => {
                                if (
                                    typeof value !== 'string' ||
                                    !(value.length >= 4)
                                ) {
                                    return 'Please enter a valid username. Usernames must be at least 4 characters long.';
                                }

                                return undefined;
                            },
                        },
                        {
                            id: 'contactName',
                            label: 'First Name',
                            placeholder: 'Preferred First Name',
                            validate: async (value) => {
                                if (
                                    typeof value !== 'string' ||
                                    !(value.length >= 2)
                                ) {
                                    return 'Please enter a valid first name. This field is not optional.';
                                }

                                return undefined;
                            },
                        },
                        {
                            id: 'betaCode',
                            label: 'Invitation Code',
                            validate: async (value) => {
                                return isUUID(value)
                                    ? undefined
                                    : 'This invitation code is invalid.';
                            },
                        },
                    ],
                },
            },
        }),
        Passwordless.init({
            style: superTokensStyle,
            onHandleEvent: async (context) => {
                // Reload the page after signin, smooths initial render
                if (context.action === 'SUCCESS') {
                    window.location.reload();
                }
            },
            signInUpFeature: {
                disableDefaultUI: true,
                defaultCountry: 'CA',
            },
            contactMethod: 'PHONE',
            override: {
                components: {
                    PasswordlessSignInUpHeader_Override: () => {
                        return (
                            <div style={{ marginBottom: 15 }}>
                                <Typography
                                    fontWeight="bold"
                                    fontSize={24}
                                    fontFamily="urbanist"
                                    marginBottom={2}
                                >
                                    Secure Your Account
                                </Typography>
                                <Divider
                                    sx={{
                                        borderColor: '#ddd !important',
                                    }}
                                />
                            </div>
                        );
                    },
                    // we override the component which shows the change phone number button
                    PasswordlessUserInputCodeFormFooter_Override: ({
                        DefaultComponent,
                        ...props
                    }) => {
                        const session = useSessionContext();

                        if (
                            session.loading !== true &&
                            session.accessTokenPayload.phoneNumber === undefined
                        ) {
                            // this will show the change phone number button
                            return <DefaultComponent {...props} />;
                        }

                        // this will hide the change phone number button
                        return null;
                    },
                },
            },
        }),
        Session.init({
            override: {
                functions: (oI) => ({
                    ...oI,
                    getGlobalClaimValidators: ({
                        claimValidatorsAddedByOtherRecipes,
                    }) => {
                        return [
                            ...claimValidatorsAddedByOtherRecipes,
                            SecondFactorClaim.validators.isTrue(),
                        ];
                    },
                }),
            },
        }),
    ],
});

const theme = createTheme(muiTheme);

function App() {
    useEffect(() => {
        loadScripts();
    }, []);

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <SuperTokensWrapper>
                <Account>
                    <ThemeProvider theme={theme}>
                        <Router>
                            <Pages />

                            <ToastContainer
                                position="top-right"
                                autoClose={10000}
                                style={{ marginTop: '70px' }}
                                hideProgressBar={false}
                            />
                        </Router>
                    </ThemeProvider>
                </Account>
            </SuperTokensWrapper>
        </LocalizationProvider>
    );
}

const Pages = () => {
    return (
        <Routes>
            {getSuperTokensRoutesForReactRouterDom(reactRouterDom).map(
                (route) => (
                    <Route
                        path={route.props.path}
                        element={
                            <ShowcaseRoute>{route.props.element}</ShowcaseRoute>
                        }
                        key={route.key}
                    />
                )
            )}

            <Route
                path="/second-factor"
                element={
                    <SessionAuth>
                        <ShowcaseRoute>
                            <SecondFactor />
                        </ShowcaseRoute>
                    </SessionAuth>
                }
            />

            <Route
                exact
                path="/"
                element={
                    <RequireLogin>
                        <Header />
                        <Home />
                    </RequireLogin>
                }
            />
            <Route
                exact
                path="/listing/:slug"
                element={
                    <RequireLogin>
                        <Header />
                        <Auction />
                    </RequireLogin>
                }
            />
            <Route
                exact
                path="/marketplace/"
                element={
                    <RequireLogin>
                        <Header />
                        <Marketplace />
                    </RequireLogin>
                }
            />
            <Route
                exact
                path="/user/:username/*"
                element={
                    <RequireLogin>
                        <Header />
                        <User />
                    </RequireLogin>
                }
            />
            <Route
                exact
                path="/administration/*"
                element={
                    <RequireAdmin>
                        <HotglueConfig
                            config={{
                                apiKey: 'PyslnBCd02aOYFwLeDJgq8n7qDcdHd6XaVcZ8n9E',
                                envId: process.env
                                    .REACT_APP_HOTGLUE_ENVIRONMENT_ID,
                            }}
                        >
                            <Header />
                            <Administration />
                        </HotglueConfig>
                    </RequireAdmin>
                }
            />

            <Route
                exact
                path="/category/:slug"
                element={
                    <RequireLogin>
                        <Header />
                        <CategoryPage />
                    </RequireLogin>
                }
            />
            <Route
                exact
                path="/category/*"
                element={
                    <RequireLogin>
                        <Header />
                        <CategoryPage />
                    </RequireLogin>
                }
            />

            {/* {<Route exact path="/city/:slug" element={<CityPage />} />
            <Route exact path="/city/*" element={<CityPage />} />} */}

            <Route
                exact
                path="/project/:slug"
                element={
                    <RequireLogin>
                        <Header />
                        <ProjectPage />
                    </RequireLogin>
                }
            />

            {/* <Route
                exact
                path="/submit"
                element={
                    <RequireLogin optional>
                        <Typeform
                            id={process.env.REACT_APP_TYPEFORM_SUBMIT}
                            title="Submit a Property"
                        />
                    </RequireLogin>
                }
            /> */}
            {/* <Route
                exact
                path="/register"
                element={
                    <RequireLogin optional>
                        <Typeform
                            id={process.env.REACT_APP_TYPEFORM_REGISTER}
                            title="Register"
                        />
                    </RequireLogin>
                }
            /> */}

            <Route
                exact
                path="/privacy-policy"
                element={
                    <RequireLogin optional>
                        <Header />
                        <RawHTML file={privacyPolicy} />
                    </RequireLogin>
                }
            />

            <Route
                exact
                path="/terms-conditions"
                element={
                    <RequireLogin optional>
                        <Header />
                        <RawHTML file={termsConditions} />
                    </RequireLogin>
                }
            />

            <Route
                exact
                path="/contact-us"
                element={
                    <>
                        <Header />
                        <ContactUsPage />
                    </>
                }
            />

            {/* <Route
                exact
                path="/about"
                element={
                    <RequireLogin optional>
                        <Header />
                        <About />
                    </RequireLogin>
                }
            /> */}

            <Route
                exact
                path="/post-purchase/:slug"
                element={
                    <RequireLogin>
                        <Header />
                        <PostPurchasePage />
                    </RequireLogin>
                }
            />

            <Route
                exact
                path="/purchase/:slug"
                element={
                    <RequireLogin>
                        <Header />
                        <PurchasePage />
                    </RequireLogin>
                }
            />

            <Route
                exact
                path="/broker/*"
                element={
                    <RequireBroker>
                        <Header />
                        <Dashboard
                            title="Broker"
                            path="/broker"
                            defaultPath="purchase-tracker"
                            pages={[
                                {
                                    title: 'Overview',
                                    slug: '',
                                    Component: null,
                                    icon: 'Speed',
                                },
                                {
                                    title: 'Purchase Tracker',
                                    slug: 'purchase-tracker',
                                    Component: PurchaseTracker,
                                    props: {
                                        config: {
                                            admin: false,
                                            developer: false,
                                            broker: true,
                                            // paramId: 'brokerage',
                                            api: `/broker/getPurchaseTracker`,
                                        },
                                    },
                                    icon: 'GpsFixed',
                                },
                                {
                                    title: 'Allocations',
                                    slug: 'units',
                                    Component: ExclusiveUnits,
                                    icon: 'Home',
                                    divider: true,
                                },
                                {
                                    title: 'Client Management',
                                    slug: 'clients',
                                    Component: ClientManagement,
                                    icon: 'Person',
                                    pages: [
                                        {
                                            title: 'Invite Client to Upside',
                                            slug: 'invite',
                                            Component: BrokerInviteClient,
                                            icon: 'PersonAddAlt1',
                                        },
                                    ],
                                },
                                {
                                    title: 'Team Management',
                                    slug: 'team',
                                    Component: null,
                                    icon: 'People',
                                },
                            ]}
                        />
                    </RequireBroker>
                }
            />

            <Route
                exact
                path="/developer/*"
                element={
                    <RequireDeveloper>
                        <Header />
                        <Dashboard
                            title="Developer"
                            path="/developer"
                            defaultPath="purchase-tracker"
                            pages={[
                                {
                                    title: 'Overview',
                                    slug: '',
                                    Component: null,
                                    icon: 'Speed',
                                },
                                {
                                    title: 'Purchase Tracker',
                                    slug: 'purchase-tracker',
                                    Component: PurchaseTracker,
                                    props: {
                                        config: {
                                            admin: false,
                                            developer: true,
                                            paramId: 'projectSlug',
                                            api: `/developer/getPurchaseTracker`,
                                        },
                                    },
                                    icon: 'GpsFixed',
                                    // divider: true,
                                },
                                {
                                    title: 'Addresses',
                                    slug: 'addresses',
                                    Component: null,
                                    icon: 'LocationOn',
                                },
                                {
                                    title: 'Models',
                                    slug: 'models',
                                    Component: null,
                                    icon: 'Dashboard',
                                },
                                {
                                    title: 'Listings',
                                    slug: 'listings',
                                    Component: null,
                                    icon: 'Home',
                                    divider: true,
                                },
                                {
                                    title: 'Addons',
                                    slug: 'addons',
                                    Component: null,
                                    icon: 'AddCircle',
                                },
                                {
                                    title: 'Incentives',
                                    slug: 'incentives',
                                    Component: null,
                                    icon: 'AutoAwesome',
                                },
                                {
                                    title: 'Amenities',
                                    slug: 'amenities',
                                    Component: null,
                                    icon: 'Grade',
                                },
                            ]}
                            menu={{
                                source: getDeveloperProjects,
                                title: 'Projects',
                                param: 'slug',
                                titleStyle: { textAlign: 'center' },
                                icon: 'Apartment',
                            }}
                        />
                    </RequireDeveloper>
                }
            />

            <Route
                exact
                path="*"
                element={
                    <RequireLogin optional>
                        <Header />
                        <NotFound />
                    </RequireLogin>
                }
            />
        </Routes>
    );
};

export default App;

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

import { toast } from 'react-toastify';
import { getMediaElements, loadForm, useYup } from '../../scripts/cms.js';
import InputForm from '../common/InputForm.js';
import { Box, Button, Divider, Grid, Typography } from '@mui/material';

function CMSForm({
    title,
    api,
    apiSuffix,
    adminSlug,
    inputs,
    data,
    defaultForm = {},
    attribute = 'id',
    _yup,
    children,
    update = 'update',
    create = 'create',
    success,
    warning,
    buttonText = 'Save',
    backgroundColor = '#ddd',
    innerBackgroundColor = 'white',
}) {
    // Prevent accidentally exiting the form ("Leave Site? Changes you made may not be saved.")
    const [showUnloadPrompt, setShowUnloadPrompt] = useState(true);
    useEffect(() => {
        const handleBeforeUnload = (e) => {
            if (showUnloadPrompt) {
                e.preventDefault();
                e.returnValue = ''; // Required for some browsers to show the prompt
            }
        };

        // Handle event creation/removal
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [showUnloadPrompt]);

    const editing = useParams()[attribute] !== undefined;

    // Set up Yup validation
    const yup = useYup(inputs, defaultForm, _yup);

    // Bring in existing data if there is any
    useEffect(() => {
        loadForm(data, yup.setValue);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    function onSubmit(_form) {
        // Clone form so the formatting doesn't affect parameter owner (caused a media duplication bug)
        let form = { ..._form };

        // Pre-submit data formatting
        for (let i = 0; i < inputs.length; i++) {
            for (let j = 0; j < inputs[i].length; j++) {
                const input = inputs[i][j];

                // Media
                if (input.media && input.media.limit !== 1) {
                    form[input.id] = getMediaElements(input.id);
                }
            }
        }

        // Remove ignorable media from request
        form['new-media'] = [];

        // Call backend with the create/update request
        axios
            .post(
                `/${api}/${editing ? update : create}${
                    apiSuffix ? apiSuffix : ''
                }/`,
                form
            )
            .then((res) => {
                setShowUnloadPrompt(false);
                // Delay the re-route slightly so that the state is updated properly
                setTimeout(() => {
                    if (adminSlug) {
                        window.location.href = `/administration/${adminSlug}`;
                    } else if (success) {
                        toast.success(success);
                        yup.reset();
                    } else {
                        window.location.reload();
                    }
                }, 0);
            })
            .catch(function (err) {
                let message = err.message;
                if (err.response && err.response.data) {
                    message = err.response.data;
                }
                toast.error(message);
            });
    }

    // Don't load form unless the data is ready
    if (editing && !data) {
        return <></>;
    }

    return (
        <Box backgroundColor={backgroundColor}>
            <Grid container spacing={2} backgroundColor={innerBackgroundColor}>
                <Grid item xs={12} md={10} lg={8} className="card-body">
                    <form onSubmit={yup.handleSubmit(onSubmit)}>
                        {title && (
                            <Typography
                                fontSize={20}
                                fontWeight="bold"
                                marginTop={2}
                                textAlign="center"
                            >{`${
                                editing ? 'Modify' : 'Create'
                            } ${title}`}</Typography>
                        )}
                        {warning && (
                            <Typography
                                fontSize={18}
                                fontWeight="medium"
                                color="status.danger"
                                marginBottom={2}
                                marginTop={2}
                                textAlign="center"
                            >
                                WARNING: Be careful of all inputs in this form.
                                Do not submit changes if you have not refreshed
                                the page recently (to avoid overwriting recent
                                changes from other administrators).
                            </Typography>
                        )}
                        {title && <Divider sx={{ marginBottom: 2 }} />}

                        <InputForm
                            yup={yup}
                            inputs={inputs}
                            editing={editing}
                        />

                        <Button
                            disabled={yup.formState.isSubmitting}
                            variant="dark"
                            type="submit"
                            fullWidth
                            sx={{ marginTop: 1 }}
                        >
                            {yup.formState.isSubmitting && (
                                <span className="spinner-border spinner-border-sm mr-1"></span>
                            )}
                            {buttonText}
                        </Button>
                    </form>
                </Grid>
                <Grid item xs={12} md={2} lg={4}>
                    {children}
                </Grid>
            </Grid>
        </Box>
    );
}

export default CMSForm;

import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

import NotFound from '../../pages/NotFound.js';

import {
    getAlphabet,
    getAmendmentTypes,
    getAmenities,
    getConnectors,
    getDevelopers,
    getDocupilotTemplateTypes,
    getDocupilotTemplates,
    getEventTypes,
    getManualPaymentOptions,
    getUpgradeOptions,
    getUpgradeSubtypes,
    getUpgradeTypes,
    mapConfig,
    useYup,
} from '../../scripts/cms.js';
import { Box, Button, Typography } from '@mui/material';
import DragArea from './media/DragArea.js';
import DynamicIcon from '../common/DynamicIcon.js';
import mediaTypes from '../../constants/mediaTypes.js';
import CMSForm from './CMSForm.js';

function ProjectForm() {
    const { slug } = useParams();
    const [valid, setValid] = useState(true);

    const [data, setData] = useState();

    const [clientAccountBody, setClientAccountBody] = useState();
    const [upgradesBody, setUpgradesBody] = useState();
    const [commissionUpgradesBody, setCommissionUpgradesBody] = useState();

    useEffect(() => {
        // Get existing auction data (if you are modifying one)
        if (slug) {
            axios
                .get(`/project/getAdmin/${slug}`)
                .then((res) => {
                    const project = res.data;

                    // Format amenities
                    const amenityList = [];
                    for (let i = 0; i < project.projectAmenities.length; i++) {
                        amenityList.push(
                            project.projectAmenities[i].amenity.id
                        );
                    }

                    // Format commissions
                    const commissions = project.commissions;
                    for (let i = 0; i < project.commissions.length; i++) {
                        const cuList = [];
                        const c = project.commissions[i];
                        for (let j = 0; j < c.commissionUpgrades.length; j++) {
                            const cu = c.commissionUpgrades[j];
                            cuList.push(cu.upgradeId);
                        }

                        // Update commission object
                        c.commissionUpgrades = cuList;
                        c.fixedOrPercent =
                            c.percent === null
                                ? 'toggleFixed'
                                : 'togglePercent';
                    }

                    // Format incentives
                    const incentives = project.incentives;
                    for (let i = 0; i < project.incentives.length; i++) {
                        const incentive = project.incentives[i];

                        // Update incentive object
                        incentive.type =
                            incentive.upgradesPercent === null &&
                            incentive.upgradesFixedAmount === null
                                ? incentive.basePricePercent === null &&
                                  incentive.basePriceFixedAmount === null
                                    ? 'toggleNone'
                                    : 'toggleBasePrice'
                                : 'toggleUpgrades';

                        // Upgrade option IDs
                        const upgradeOptions = [];
                        for (
                            let j = 0;
                            j < incentive.incentiveUpgradeOptions?.length;
                            j++
                        ) {
                            upgradeOptions.push(
                                incentive.incentiveUpgradeOptions[j]
                                    .upgradeOptionId
                            );
                        }
                        incentive.upgradeOptionIds = upgradeOptions;
                    }

                    setData({
                        ...res.data,
                        logo: { media: project.logo },
                        amenities: amenityList,
                        commissions: commissions,
                        incentives: incentives,
                        // --- Config variables ---
                        // Amendments
                        amendmentServiceFees: mapConfig(
                            project?.config?.amendment?.serviceFees,
                            'amendmentTypeId',
                            'amount'
                        ),
                        // Post-Purchase
                        officerMax: project?.config?.postPurchase?.officerMax,
                        individualMax:
                            project?.config?.postPurchase?.individualMax,
                        disableIndividual:
                            project?.config?.postPurchase?.disableIndividual,
                        disableCorporate:
                            project?.config?.postPurchase?.disableCorporate,
                        disableCorporateAndIndividual:
                            project?.config?.postPurchase
                                ?.disableCorporateAndIndividual,
                        // Property Events
                        propertyEvents: mapConfig(
                            project?.config?.propertyEvents,
                            'eventTypeId',
                            'expectedDate'
                        ),
                        // -- DocuSign --
                        // CC List
                        ccList: project?.config?.docusign?.ccList || [],
                        // Include Editor
                        editorEmail: project?.config?.docusign?.editor?.email,
                        editorAPS: project?.config?.docusign?.editor?.aps,
                        editorICA: project?.config?.docusign?.editor?.ica,
                        editorAmendment:
                            project?.config?.docusign?.editor?.amendment,

                        // -- Email --
                        // Disclosure Package Url
                        disclosurePackageUrl:
                            project?.config?.email?.disclosurePackageUrl,

                        // Hotglue
                        enableSalesforce:
                            project?.config?.hotglue?.enableSalesforce,
                        enableGoogleSheets:
                            project?.config?.hotglue?.enableGoogleSheets,
                        enableExcel: project?.config?.hotglue?.enableExcel,

                        // Manual Payment Options
                        manualPaymentOptionIds:
                            project?.config?.postPurchase
                                ?.manualPaymentOptionIds || [],
                        // Model
                        maintenance: project?.config?.model?.maintenance,
                        propertyTax: project?.config?.model?.propertyTax,
                        purchaseProcedure:
                            project?.config?.model?.purchaseProcedure,
                        mortgageRequirements:
                            project?.config?.model?.mortgageRequirements,

                        // Deposit
                        memo: project?.config?.deposit?.memo,
                    });

                    setClientAccountBody({
                        userId: project.developer.user.id,
                        developerId: project.developer.id,
                    });

                    setUpgradesBody({
                        projectId: project.id,
                        ignoreIncentive: false,
                    });
                    setCommissionUpgradesBody({
                        projectId: project.id,
                    });
                })
                .catch(function () {
                    setValid(false);
                });
        }
    }, [slug]);

    const inputs = [
        [
            {
                id: 'projectMedia',
                optional: true,
                media: {},
            },
        ],
        [
            {
                id: 'developerId',
                label: 'Select Developer',
                source: getDevelopers,
                sourceAttribute: 'user.username',
                restricted: true,
            },
        ],
        [
            {
                id: 'clientAccountId',
                source: getClientAccounts,
                sourceBody: clientAccountBody,
                label: 'Client Account ID',
                table: { column: 'zumId' },
                optional: true,
                delayed: true,
            },
        ],
        [{ id: 'title', label: 'Project Title' }, { id: 'slug' }],
        [{ id: 'websiteUrl', optional: true, label: 'Project Website URL' }],
        [{ id: 'description', paragraph: true, label: 'Project Description' }],
        [
            {
                id: 'logo',
                media: {
                    limit: 1,
                    mediaTypeId: mediaTypes.LOGO,
                },
                optional: true,
                label: 'Project Logo (Limit 1 upload, format specified)',
            },
        ],
        [
            {
                id: 'upgrades',
                inputs: [
                    [
                        {
                            id: 'upgradeTypeId',
                            source: getUpgradeTypes,
                            label: 'Upgrade Type',
                        },
                    ],
                    [{ id: 'title', label: 'Upgrade Title' }],
                    [{ id: 'description', label: 'Upgrade Description' }],
                    [
                        {
                            id: 'maxQuantity',
                            optional: true,
                            number: true,
                            description:
                                'Maximum sum of upgrade quantities that can be chosen at post-purchase. Ex. The sum of surface parking and underground parking options cannot exceed this maximum.',
                        },
                    ],
                ],
                title: 'Upgrade',
                array: {
                    id: 'upgradeOptions',
                    inputs: [
                        [
                            { id: 'title' },
                            {
                                id: 'upgradeSubtypeId',
                                source: getUpgradeSubtypes,
                                label: 'Upgrade Subtype',
                                sourceParameters: [
                                    { id: 'upgradeTypeId', parent: true },
                                    { id: 'developerId' },
                                ],
                                optional: true,
                                placeholder: 'None',
                            },
                        ],
                        [
                            { id: 'price', number: true },
                            {
                                id: 'quantity',
                                number: true,
                                description:
                                    'The quantity of items that this upgrade option includes. Value should be 0 for options that include nothing (ex. No parking)',
                            },
                            {
                                id: 'reserved',
                                number: true,
                                description:
                                    'The quantity that is included for free with the purchase of the unit. Must be <= to quantity. The reserved amount does not contribute to the inventory usage.',
                            },
                            {
                                id: 'ignoreIncentive',
                                checkbox: true,
                                description:
                                    'When enabled, this upgrade option will not be discounted by incentives.',
                            },
                        ],
                    ],
                    title: 'Upgrade Item Choice(s)',
                },
            },
        ],
        [
            {
                id: 'depositSchedules',
                inputs: [
                    [
                        { id: 'title', label: 'Deposit Schedule Name' },
                        {
                            id: 'coolingPeriod',
                            number: true,
                            label: 'Cooling period (days)',
                        },
                    ],
                ],
                title: 'Deposit Schedule',
                array: {
                    id: 'deposits',
                    inputs: [
                        [
                            {
                                id: 'percent',
                                number: true,
                                optional: true,
                                label: 'Percentage (%)',
                                step: 0.001,
                            },
                            {
                                id: 'fixedAmount',
                                number: true,
                                optional: true,
                                label: 'Fixed Amount ($)',
                                step: 0.01,
                            },
                            {
                                id: 'subtraction',
                                number: true,
                                label: 'Amount to deduct',
                                description:
                                    'For usage on % deposits to subtract the fixed amount defined in the first deposit (ex. 5% less $5000)',
                                step: 0.01,
                            },
                            {
                                id: 'scheduleFromFirm',
                                checkbox: true,
                                description:
                                    'Whether the deposit should be scheduled from the firm date, or from when the APS was signed. First deposit should schedule from firm.',
                            },
                        ],
                        [
                            { id: 'dueString' },
                            { id: 'dueDays', number: true, optional: true },
                        ],
                    ],
                    title: 'Deposit(s)',
                },
                optional: true,
            },
        ],
        [
            {
                id: 'commissions',
                inputs: [
                    [{ id: 'title', label: 'Commission Structure Name' }],
                    [
                        {
                            id: 'docupilotTemplateId',
                            label: 'Docupilot Template',
                            source: getDocupilotTemplates,
                            sourceParameters: [
                                { id: 'id', as: 'projectId' },
                                { id: 'docupilotTemplateTypeId', value: 2 },
                            ],
                            table: {
                                columns: [
                                    {
                                        field: 'title',
                                        headerName: 'Title',
                                    },
                                    {
                                        field: 'templateId',
                                        headerName: 'Template ID',
                                    },
                                ],
                            },
                        },
                    ],
                    [
                        {
                            id: 'description',
                            rows: 4,
                            optional: true,
                            label: 'Commission Description',
                        },
                    ],
                    [
                        {
                            id: 'fixedOrPercent',
                            toggle: {
                                toggleFixed: {
                                    id: 'toggleFixed',
                                    inputs: [
                                        [
                                            {
                                                id: 'fixedAmount',
                                                number: true,
                                                label: 'Fixed Amount ($)',
                                                step: 0.01,
                                            },
                                        ],
                                    ],
                                    label: 'Fixed Amount ($)',
                                },
                                togglePercent: {
                                    id: 'togglePercent',
                                    inputs: [
                                        [
                                            {
                                                id: 'percent',
                                                number: true,
                                                label: 'Percentage (%)',
                                                step: 0.001,
                                            },
                                            {
                                                id: 'tax',
                                                label: 'Net of sales tax',
                                                checkbox: true,
                                            },
                                        ],
                                        [
                                            {
                                                id: 'commissionUpgrades',
                                                source: getProjectUpgrades,
                                                sourceBody:
                                                    commissionUpgradesBody,
                                                label: 'Upgrades: Commission will be calculated including these upgrades',
                                                table: {
                                                    multi: true,
                                                    columns: [
                                                        {
                                                            field: 'id',
                                                            headerName: 'ID',
                                                            flex: 0.5,
                                                        },
                                                        {
                                                            field: 'title',
                                                            headerName:
                                                                'Upgrade',
                                                            flex: 4,
                                                        },
                                                    ],
                                                },
                                                optional: true,
                                            },
                                        ],
                                    ],
                                    label: 'Percentage (%)',
                                },
                            },
                        },
                    ],
                ],
                title: 'Commission Structure',
                array: {
                    id: 'commissionPayments',
                    inputs: [
                        [
                            {
                                id: 'fixedAmount',
                                number: true,
                                optional: true,
                                label: 'Fixed Amount ($)',
                                step: 0.01,
                            },
                            {
                                id: 'percent',
                                number: true,
                                optional: true,
                                step: 0.001,
                                label: 'Percentage (%)',
                            },
                            {
                                id: 'eventTypeId',
                                source: getEventTypes,
                                label: 'Trigger Event',
                            },
                        ],
                    ],
                    title: 'Commission Payment(s)',
                },
            },
        ],
        [
            {
                id: 'incentives',
                inputs: [
                    [
                        {
                            id: 'templateName',
                            label: 'Name (Internal)',
                        },
                        {
                            id: 'title',
                            label: 'Title (Listing)',
                        },
                    ],
                    [{ id: 'scheduleIdentifier' }],
                    [
                        {
                            id: 'description',
                            rows: 4,
                            label: 'Description (Listing)',
                        },
                    ],
                    [{ id: 'contractDescription', rows: 8, optional: true }],
                    [
                        {
                            id: 'disableListing',
                            checkbox: true,
                            description: 'Hide from listing page',
                        },
                        {
                            id: 'disableContract',
                            checkbox: true,
                            description:
                                'Excludes the incentive from the APS if checked',
                        },
                    ],
                    [
                        {
                            id: 'type',
                            toggle: {
                                toggleBasePrice: {
                                    id: 'toggleBasePrice',
                                    inputs: [
                                        [
                                            {
                                                id: 'basePriceFixedAmount',
                                                number: true,
                                                optional: true,
                                                label: 'Base Price Fixed Amount ($)',
                                                step: 0.01,
                                            },
                                            {
                                                id: 'basePricePercent',
                                                number: true,
                                                optional: true,
                                                label: 'Base Price Percent (%)',
                                                step: 0.001,
                                            },
                                        ],
                                    ],
                                    label: 'Base Price',
                                },
                                toggleUpgrades: {
                                    id: 'toggleUpgrades',
                                    inputs: [
                                        [
                                            {
                                                id: 'upgradesFixedAmount',
                                                number: true,
                                                optional: true,
                                                label: 'Upgrades Fixed Amount ($)',
                                                step: 0.01,
                                            },
                                            {
                                                id: 'upgradesPercent',
                                                number: true,
                                                optional: true,
                                                label: 'Upgrades Percent (%)',
                                                step: 0.001,
                                            },
                                        ],
                                        [
                                            {
                                                id: 'upgradeOptionIds',
                                                source: getUpgradeOptions,
                                                sourceBody: upgradesBody,
                                                table: {
                                                    columns: [
                                                        {
                                                            field: 'upgradeId',
                                                            headerName: 'ID',
                                                            flex: 0.5,
                                                            renderCell: (
                                                                params
                                                            ) => {
                                                                return params
                                                                    .row.upgrade
                                                                    .id;
                                                            },
                                                        },
                                                        {
                                                            field: 'upgrade',
                                                            headerName:
                                                                'Upgrade',
                                                            flex: 4,
                                                            renderCell: (
                                                                params
                                                            ) => {
                                                                return params
                                                                    .row.upgrade
                                                                    .title;
                                                            },
                                                        },
                                                        {
                                                            field: 'id',
                                                            headerName: 'ID',
                                                            flex: 0.5,
                                                        },
                                                        {
                                                            field: 'title',
                                                            headerName:
                                                                'Upgrade Option',
                                                            flex: 4,
                                                        },
                                                    ],
                                                    multi: true,
                                                },
                                                optional: true,
                                                label: 'Upgrade Options (Select none to apply to all)',
                                            },
                                        ],
                                    ],
                                    label: 'Upgrade',
                                },
                                toggleNone: {
                                    id: 'toggleNone',
                                    label: 'None',
                                    inputs: [
                                        [
                                            {
                                                id: 'customAmount',
                                                number: true,
                                                optional: true,
                                                step: 0.01,
                                                description:
                                                    'Value of this incentive. Used for special monetary values (ex. Gift card)',
                                            },
                                        ],
                                    ],
                                },
                            },
                        },
                    ],
                ],
                title: 'Incentive',
            },
        ],
        [
            {
                id: 'amenities',
                source: getAmenities,
                label: 'Amenity',
                table: {
                    multi: true,
                    columns: [
                        {
                            field: 'title',
                            headerName: 'Amenity',
                            flex: 2,
                        },
                        {
                            field: 'icon',
                            headerName: 'Icon',
                            Component: DynamicIcon,
                            props: ['icon'],
                            sortable: false,
                        },
                    ],
                },
                optional: true,
            },
        ],
        [
            {
                id: 'inventories',
                inputs: [
                    [
                        {
                            id: 'upgradeTypeId',
                            source: getUpgradeTypes,
                            label: 'Upgrade Type',
                        },
                        {
                            id: 'upgradeSubtypeId',
                            source: getUpgradeSubtypes,
                            label: 'Upgrade Subtype',
                            sourceParameters: [
                                { id: 'upgradeTypeId', child: true },
                                { id: 'developerId' },
                            ],
                            optional: true,
                            placeholder: 'None',
                        },
                    ],
                    [{ id: 'quantity', number: true }],
                ],
                title: 'Inventory Definition',
            },
        ],
        [
            {
                id: 'updateDeliveries',
                checkbox: true,
                description:
                    'If checked, all DocuPilot templates wil be regenerated based on this request.',
                optional: true,
            },
        ],
        [
            {
                id: 'docupilotTemplates',
                inputs: [
                    [{ id: 'templateId', number: true }, { id: 'title' }],
                    [
                        {
                            id: 'docupilotTemplateTypeId',
                            label: 'Type',
                            source: getDocupilotTemplateTypes,
                        },
                        {
                            id: 'amendmentTypeId',
                            label: 'Amendment Type',
                            optional: true,
                            placeholder: 'None',
                            source: getAmendmentTypes,
                            sourceParameters: [
                                { id: 'docupilotTemplateTypeId', child: true },
                            ],
                        },
                    ],
                ],
                title: 'DocuPilot Template',
            },
        ],
        [
            {
                id: 'signingSummaryTemplateId',
                number: true,
                optional: true,
            },
            {
                id: 'dealSummaryTemplateId',
                number: true,
                optional: true,
            },
        ],
        [
            {
                id: 'fintracIndividualTemplateId',
                number: true,
                optional: true,
            },
            {
                id: 'fintracCorporateTemplateId',
                number: true,
                optional: true,
            },
        ],
        [
            {
                id: 'amendmentServiceFees',
                title: 'Service Fee Default',
                optional: true,
                inputs: [
                    [
                        {
                            id: 'amendmentTypeId',
                            source: getAmendmentTypes,
                            label: 'Amendment Type',
                        },
                        { id: 'amount', number: true },
                    ],
                ],
            },
        ],
        [
            {
                id: 'propertyEvents',
                title: 'Listing Event Default',
                optional: true,
                inputs: [
                    [
                        {
                            id: 'eventTypeId',
                            source: getEventTypes,
                            sourceBody: { property: true },
                            label: 'Event Type',
                        },
                        { id: 'expectedDate', date: true },
                    ],
                ],
            },
        ],
        [
            { id: 'enableSalesforce', checkbox: true, optional: true },
            { id: 'enableGoogleSheets', checkbox: true, optional: true },
            { id: 'enableExcel', checkbox: true, optional: true },
        ],
        [
            {
                id: 'memo',
                optional: true,
                description:
                    "Used in deposit payment bank memos as: '{depositMemo}-{unitNumber}-{depositNumber}'",
                maxLength: 6,
            },
            {
                id: 'disclosurePackageUrl',
                label: 'Disclosure Package URL',
                optional: true,
                description:
                    "URL to the project's disclosure package that will be sent via email",
            },
        ],
        [
            {
                id: 'editorEmail',
                label: 'Editor Email - Must be a DocuSign user)',
                optional: true,
                description:
                    'DocuSign editor that must review agreements before purchasers are able to sign.',
            },
            {
                id: 'editorAPS',
                label: 'Enable Editor - APS',
                optional: true,
                checkbox: true,
            },
            {
                id: 'editorICA',
                label: 'Enable Editor - ICA',
                optional: true,
                checkbox: true,
            },
            {
                id: 'editorAmendment',
                label: 'Enable Editor - Amendments',
                optional: true,
                checkbox: true,
            },
        ],
        [
            {
                id: 'ccList',
                title: 'Docusign CC (APS, Amendment, ICA)',
                optional: true,
                inputs: [[{ id: 'email' }, { id: 'name' }]],
            },
        ],
        [
            { id: 'officerMax', number: true, optional: true, min: 1, max: 10 },
            {
                id: 'individualMax',
                number: true,
                optional: true,
                min: 1,
                max: 10,
            },
            { id: 'disableIndividual', checkbox: true, optional: true },
            { id: 'disableCorporate', checkbox: true, optional: true },
            {
                id: 'disableCorporateAndIndividual',
                checkbox: true,
                optional: true,
            },
        ],
        [
            {
                id: 'manualPaymentOptionIds',
                source: getManualPaymentOptions,
                label: 'Manual Payment Options',
                table: { column: 'title', multi: true },
            },
        ],
        [
            {
                id: 'maintenance',
                label: 'Maintenance Fees (Default)',
                paragraph: true,
                optional: true,
            },
        ],
        [
            {
                id: 'propertyTax',
                label: 'Property Tax (Default)',
                paragraph: true,
                optional: true,
            },
        ],
        [
            {
                id: 'purchaseProcedure',
                label: 'Purchase Procedure (Default)',
                paragraph: true,
                optional: true,
            },
        ],
        [
            {
                id: 'mortgageRequirements',
                label: 'Mortgage Requirements (Default)',
                paragraph: true,
                optional: true,
            },
        ],
    ];

    const yup = useYup(inputs, { amenities: [] });

    if (valid) {
        return (
            <CMSForm
                api="project"
                adminSlug="projects"
                data={data}
                attribute="slug"
                inputs={inputs}
                _yup={yup}
            >
                <RelatedMedia watch={yup.watch} slug={slug} />
            </CMSForm>
        );
    } else {
        return <NotFound />;
    }
}

function getClientAccounts(setState, body) {
    axios
        .post(`/zum/adminGetClientAccounts`, body)
        .then((res) => {
            setState(res.data);
        })
        .catch(function () {});
}

function getProjectUpgrades(setState, body) {
    axios
        .post(`/project/getUpgrades`, body)
        .then((res) => {
            setState(res.data);
        })
        .catch(function () {});
}

const RelatedMedia = ({ watch, slug }) => {
    return (
        <Box
            height="calc(100vh - 100px)"
            top={0}
            backgroundColor="white"
            className="auction-media"
            padding={2}
            overflow="auto"
        >
            <Typography fontSize={18} textAlign="center">
                Existing Media
            </Typography>
            <DragArea
                id="existing-project-media"
                source={watch('projectMedia')}
                title="Existing Project Media"
                cols={3}
            />
            <AddressMedia watch={watch} slug={slug} />
        </Box>
    );
};

const AddressMedia = ({ watch, slug }) => {
    const [addresses, setAddresses] = useState();

    const projectId = watch('id');

    function loadAddresses() {
        setAddresses([]);
        axios
            .post(`/project/getRelatedMedia/${projectId}`)
            .then((res) => {
                setAddresses(res.data);
            })
            .catch(function () {
                setAddresses();
            });
    }

    return (
        <Box>
            {!addresses && (
                <Button
                    variant="dark"
                    size="small"
                    fullWidth
                    disabled={!slug || !projectId}
                    onClick={() => loadAddresses()}
                >
                    Load Other Listings
                </Button>
            )}
            {addresses?.map((address) => (
                <AddressMediaGroup key={address.id} address={address} />
            ))}
        </Box>
    );
};

const AddressMediaGroup = ({ address }) => {
    return (
        <>
            <hr />
            <Typography
                fontSize={18}
                textAlign="center"
            >{`${address.streetAddress}, ${address.city}, ${address.province}`}</Typography>
            {address.properties.map((property) => (
                <DragArea
                    key={property.id}
                    id={`existing-property-media-${property.id}`}
                    source={property.propertyMedia}
                    title={`${property.auction.title}${
                        property.unit ? ` - ${property.unit}` : ''
                    }`}
                    cols={3}
                    link={`/listing/${property.auction.slug}`}
                />
            ))}
        </>
    );
};

export default ProjectForm;

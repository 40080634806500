import React, { useState, useEffect } from 'react';
import { Box, Button, Collapse, Grid, Typography } from '@mui/material';
import Input from './Input';
import axios from 'axios';
import PopupForm from '../../common/PopupForm';
import Loading from '../../common/Loading';
import { provinces } from '../../../scripts/validation';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

function BrokerInfo() {
    const [broker, setBroker] = useState();
    const [brokers, setBrokers] = useState([]);

    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
    const [dialogTitle, setDialogTitle] = useState();
    const [dialogBody, setDialogBody] = useState();
    const [dialogAction, setDialogAction] = useState();

    useEffect(() => {
        axios
            .get(`/broker/getPrivate`)
            .then((res) => {
                setBroker(res.data);
            })
            .catch(function () {});

        axios
            .get(`/broker/getBrokers`)
            .then((res) => {
                setBrokers(res.data);
            })
            .catch(function () {});
    }, []);

    // Display loading indication
    if (broker === null || broker === undefined) {
        return <Loading height={50} />;
    }

    const handleRemoveBroker = (broker) => {
        showDialog(
            'Remove Broker?',
            <div>
                Are you sure you want to remove{' '}
                <strong>{`${broker.user.username}`}</strong> from your list of
                brokers?
            </div>,
            () => {
                axios
                    .post(`/broker/removeBroker/${broker.id}`)
                    .then(() => {
                        removeBroker(broker);

                        setShowConfirmationDialog(false);
                    })
                    .catch(function (err) {
                        toast.error(err?.response?.data || err?.message);
                        setShowConfirmationDialog(false);
                    });
            }
        );
    };

    const removeBroker = (item) => {
        setBrokers(brokers.filter((broker) => broker.id !== item.id));
    };

    const showDialog = (title, body, action) => {
        setShowConfirmationDialog(true);
        setDialogTitle(title);
        setDialogBody(body);
        setDialogAction(() => action);
    };

    return (
        <Box>
            <YourBrokers
                brokers={brokers}
                handleRemoveBroker={handleRemoveBroker}
            />

            {!broker?.approved && <BrokerRegistration />}

            {broker?.approved && (
                <Box>
                    <Typography
                        fontSize={20}
                        fontWeight="medium"
                        marginBottom={1}
                    >
                        Broker
                    </Typography>
                    <Button
                        variant="dark"
                        fullWidth
                        component={Link}
                        to="/broker"
                    >
                        View Dashboard
                    </Button>
                </Box>
            )}
            <PopupForm
                title={dialogTitle}
                body={dialogBody}
                action={dialogAction}
                show={showConfirmationDialog}
                setShow={setShowConfirmationDialog}
            />
        </Box>
    );
}

const Broker = ({ broker, handleRemoveBroker }) => {
    return (
        <Box
            display="flex"
            backgroundColor="tint"
            padding={1}
            paddingLeft={2}
            marginBottom={1}
            borderRadius={4}
        >
            <Typography display="flex" margin="auto auto auto 0" color="dark">
                {broker.user.username}
            </Typography>
            <Button
                width={80}
                variant="danger"
                size="tiny"
                onClick={() => handleRemoveBroker(broker)}
            >
                Remove
            </Button>
        </Box>
    );
};

const YourBrokers = ({ brokers, handleRemoveBroker }) => {
    const [showAdd, setShowAdd] = useState(false);

    const handleSubmitBroker = (form) => {
        axios
            .post(`/broker/submitPairingRequest`, form)
            .then((res) => {
                setShowAdd(false);
            })
            .catch(function (err) {});
    };

    return (
        <Box marginBottom={4}>
            <Typography fontSize={20} fontWeight="medium" marginBottom={1}>
                Your Brokers
            </Typography>
            {brokers.map((broker) => (
                <Broker
                    key={broker.id}
                    broker={broker}
                    handleRemoveBroker={handleRemoveBroker}
                />
            ))}
            {brokers.length === 0 && (
                <Typography color="text.secondary">
                    You have no brokers to display.
                </Typography>
            )}

            <Grid container gap={1} spacing={1} marginTop={2}>
                <Button item variant="dark" onClick={() => setShowAdd(true)}>
                    Add Broker
                </Button>
            </Grid>

            <PopupForm
                title="Add a Broker"
                yes="Submit"
                show={showAdd}
                setShow={setShowAdd}
                action={handleSubmitBroker}
                inputs={[[{ id: 'email', label: 'Email Address' }]]}
                body={
                    <div>
                        <div style={{ marginBottom: 8 }}>
                            Please enter the email address of your broker.
                        </div>
                        <div>
                            If the email address is correct, your broker will be
                            notified and can approve the request in their
                            profile.
                        </div>
                    </div>
                }
            />
        </Box>
    );
};

const BrokerRegistration = () => {
    const [loading, setLoading] = useState(true);
    const [showRegister, setShowRegister] = useState(false);
    const [broker, setBroker] = useState();

    const [showConfirmDelete, setShowConfirmDelete] = useState(false);

    useEffect(() => {
        axios
            .get(`/broker/getRegistration`)
            .then((res) => {
                setBroker(res.data);
            })
            .catch(function () {})
            .finally(function () {
                setLoading(false);
            });
    }, []);

    const handleSubmitRegistration = (form) => {
        axios
            .post(`/broker/submitRegistration`, form)
            .then(() => {
                setBroker({ ...form, response: null });
                setShowRegister(false);
            })
            .catch(function (err) {
                toast.error(
                    err?.response?.data?.message ||
                        'Unable to process your request.'
                );
            });
    };

    const handleCancelRegistration = () => {
        axios
            .post(`/broker/cancelRegistration`)
            .then(() => {
                setShowConfirmDelete(false);
                setBroker();
            })
            .catch(function (err) {});
    };

    // Needed a state update otherwise the masked inputs wouldn't render with a value
    if (loading) {
        return <Loading height={50} />;
    }

    return (
        <Box maxWidth={700}>
            <Typography fontSize={20} fontWeight="medium" marginBottom={1}>
                Broker Registration
            </Typography>
            <Typography marginBottom={2}>
                Submit a request to become a broker on Upside.
            </Typography>

            {broker?.response && (
                <Box
                    marginBottom={2}
                    display="flex"
                    flex={1}
                    padding={1}
                    backgroundColor="background.danger"
                    border="2px dashed #C40B37"
                >
                    <Typography
                        marginRight={1}
                        fontWeight="bold"
                        color="status.danger"
                    >
                        Response:
                    </Typography>
                    <Typography>{broker.response}</Typography>
                </Box>
            )}
            {broker && !broker?.response && (
                <Box
                    marginBottom={2}
                    display="flex"
                    flex={1}
                    padding={1}
                    backgroundColor="background.info"
                    border="2px dashed #1874FF"
                >
                    <Typography
                        marginRight={1}
                        fontWeight="bold"
                        color="status.info"
                    >
                        Response Pending
                    </Typography>
                </Box>
            )}

            <Grid container gap={1} spacing={1}>
                <Grid item>
                    <Button
                        item
                        variant="dark"
                        onClick={() => setShowRegister(true)}
                    >
                        {broker ? 'Edit Registration' : 'Register Now'}
                    </Button>
                </Grid>
                {broker && (
                    <Grid item>
                        <Button
                            item
                            variant="danger"
                            onClick={() => setShowConfirmDelete(true)}
                        >
                            Cancel Registration
                        </Button>
                    </Grid>
                )}
            </Grid>

            <PopupForm
                title="Broker Registration"
                yes="Submit"
                width={500}
                show={showRegister}
                setShow={setShowRegister}
                action={handleSubmitRegistration}
                inputs={[
                    [
                        { id: 'brokerage' },
                        {
                            id: 'brokeragePhone',
                            label: 'Brokerage Phone Number',
                            mask: '+99999999999',
                        },
                    ],
                    [
                        {
                            id: 'brokerageFax',
                            label: 'Brokerage Fax Number',
                            optional: true,
                        },
                        {
                            id: 'email',
                            label: 'Broker Email',
                        },
                    ],
                    [
                        {
                            id: 'title',
                            label: 'Broker Title',
                        },
                        { id: 'firstName' },
                    ],
                    [{ id: 'middleName', optional: true }, { id: 'lastName' }],
                    [
                        { id: 'country', disabled: true },
                        {
                            id: 'province',
                            children: provinces,
                        },
                    ],
                    [
                        { id: 'city' },
                        {
                            id: 'postalCode',
                            mask: 'a9a 9a9',
                        },
                    ],
                    [
                        {
                            id: 'streetAddress',
                            label: 'Address',
                        },
                        { id: 'unit', optional: true },
                    ],
                    [
                        {
                            id: 'adminEmail',
                        },
                        {
                            id: 'adminFirstName',
                        },
                    ],
                    [
                        {
                            id: 'adminMiddleName',
                            optional: true,
                        },
                        {
                            id: 'adminLastName',
                        },
                    ],
                ]}
                defaults={
                    broker || {
                        country: 'Canada',
                        province: 'ON',
                    }
                }
            />
            <PopupForm
                title="Delete broker request submission?"
                body={
                    <div>
                        Are you sure you want to delete your broker submission?
                    </div>
                }
                action={() => handleCancelRegistration()}
                show={showConfirmDelete}
                setShow={setShowConfirmDelete}
            />
        </Box>
    );
};

export default BrokerInfo;

import React, { useEffect, useState } from 'react';
import Loading from '../../components/common/Loading';
import axios from 'axios';
import { Box, Button, TextField, Typography } from '@mui/material';
import CardList from '../../components/common/CardList';
import HTMLParse from '../../components/common/HTMLParse';
import { Link } from 'react-router-dom';

function Dashboard() {
    const [projects, setProjects] = useState([]);

    useEffect(() => {
        axios
            .get(`/project/getAll`)
            .then((res) => {
                setProjects(res.data);
            })
            .catch(function () {});
    }, []);

    if (!projects) {
        return <Loading />;
    }

    return <CardList items={projects} Component={ProjectCard} noCenter />;
}

function ProjectCard({ item: project }) {
    return (
        <Box
            height={200}
            border="1px solid #707070"
            borderRadius={4}
            backgroundColor="#f8f8f8"
            padding={3}
            display="flex"
            flexDirection="column"
            component={Link}
            to={`/administration/dashboard/${project.slug}`}
            color="dark"
            sx={{ textDecoration: 'none' }}
        >
            <Typography fontSize={22} fontWeight="medium" marginBottom={3}>
                {project.title}
            </Typography>
            <Typography fontWeight="light" className="clamp3">
                <HTMLParse value={project.description} />
            </Typography>
        </Box>
    );
}

export default Dashboard;

import { useEffect, useRef, useState } from 'react';
import {
    Box,
    Button,
    Divider,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    Skeleton,
    Typography,
} from '@mui/material';
import {
    Route,
    Routes,
    Link,
    useParams,
    useLocation,
    Navigate,
} from 'react-router-dom';
import DynamicIcon from './DynamicIcon';
import NoData from './NoData';
import ScrollToTop from './ScrollToTop';
import NotFound from '../../pages/NotFound';
import { setTitle } from '../../scripts/style';

function Page({ title, icon, menu, path, pages = [], back, children }) {
    const scrollRef = useRef(null);
    const { pathname } = useLocation();

    function getBack(num) {
        let backPath = pathname;
        for (let i = 0; i < num; i++) {
            backPath = backPath.substring(0, backPath.lastIndexOf('/'));
        }
        return backPath;
    }

    return (
        <Box
            padding={{ xs: 0, md: 4 }}
            flex={1}
            backgroundColor="#ddd"
            height="100%"
            sx={{ overflowY: 'auto' }}
            ref={scrollRef}
        >
            <Box
                backgroundColor="#fafafa"
                padding={{ xs: 2, md: 4 }}
                borderRadius={{ xs: 0, md: 8 }}
            >
                <ScrollToTop scrollRef={scrollRef} />
                <Box display="flex" textAlign="left" marginBottom={2}>
                    {back && (
                        <Box width={60} position="relative">
                            <IconButton
                                component={Link}
                                to={getBack(back)}
                                sx={{
                                    position: 'absolute',
                                    top: -3,
                                }}
                                variant="tintLight"
                            >
                                <DynamicIcon
                                    icon="West"
                                    size={28}
                                    colour="dark"
                                />
                            </IconButton>
                        </Box>
                    )}
                    {menu?.title && (
                        <Typography
                            fontSize={24}
                            fontWeight="bold"
                            color="tintFont"
                            marginRight={2}
                            borderRight="1px solid #aaa"
                            paddingRight={2}
                            display={{ xs: 'none', md: 'block' }}
                        >
                            {menu.title}
                        </Typography>
                    )}
                    {!back && icon && (
                        <DynamicIcon
                            sx={{
                                marginTop: 'auto',
                                marginBottom: 'auto',
                                marginRight: 2,
                                marginLeft: { xs: 1, md: 0 },
                                display: {
                                    xs: back ? 'none' : 'block',
                                    md: 'block',
                                },
                            }}
                            icon={icon}
                            size={32}
                        />
                    )}
                    <Typography
                        fontSize={24}
                        fontWeight="bold"
                        marginRight="auto"
                    >
                        {title}
                    </Typography>
                </Box>

                {title && (
                    <Divider
                        sx={{
                            marginBottom: 2,
                            marginTop: { xs: 0, md: 3 },
                        }}
                    />
                )}

                {pages.length > 0 && (
                    <Grid container spacing={2} marginBottom={2}>
                        {pages.map((subpage, i) => (
                            <Grid key={i} item xs={12} md={6} lg={3}>
                                <ListItem disablePadding variant="brand">
                                    <Link
                                        to={`${path}/${subpage.slug}`}
                                        style={{
                                            color: 'inherit',
                                            textDecoration: 'none',
                                            width: '100%',
                                        }}
                                    >
                                        <ListItemButton>
                                            <Typography
                                                width="100%"
                                                padding={1}
                                                marginRight="auto"
                                                fontSize={18}
                                            >
                                                {subpage.title}
                                            </Typography>
                                            <DynamicIcon
                                                sx={{ marginRight: 1 }}
                                                icon={subpage.icon || 'East'}
                                                size={28}
                                            />
                                        </ListItemButton>
                                    </Link>
                                </ListItem>
                            </Grid>
                        ))}
                    </Grid>
                )}

                {children}
            </Box>
        </Box>
    );
}

function Container({ children }) {
    return (
        <Box
            display="flex"
            flexDirection={{ xs: 'column', md: 'row' }}
            width="100%"
            height="calc(100vh - 70px)"
        >
            {children}
        </Box>
    );
}

function SelectionMenu({ menu, items, path }) {
    // Skeleton loader
    if (items === undefined) {
        return (
            <Grid
                container
                spacing={{ xs: 2, md: 4, lg: 12 }}
                padding={{ xs: 1, md: 2, lg: 4 }}
            >
                <MenuItem skeleton={true} />
                <MenuItem skeleton={true} />
            </Grid>
        );
    }

    // Render list
    if (items?.length > 0) {
        return (
            <Grid
                container
                spacing={{ xs: 2, md: 4, lg: 12 }}
                padding={{ xs: 1, md: 2, lg: 4 }}
            >
                {items.map((item) => (
                    <MenuItem
                        key={item.id}
                        item={item}
                        path={`${path}/${item[menu.param]}`}
                    />
                ))}
            </Grid>
        );
    }

    // No selection available
    else {
        return <NoData />;
    }
}

function MenuItem({ item, path, skeleton }) {
    const IMAGE_HEIGHT = 300;

    const PADDING = { xs: 2, md: 4, lg: 6 };

    return (
        <Grid item xs={12} lg={4} md={6}>
            {skeleton ? (
                <Box>
                    <Skeleton height={IMAGE_HEIGHT + 200} variant="rounded" />
                </Box>
            ) : (
                <Box component={Link} to={path} sx={{ textDecoration: 'none' }}>
                    <Box
                        backgroundColor="tint"
                        padding={PADDING}
                        paddingBottom={PADDING}
                        borderRadius={6}
                        sx={{
                            '&:hover': {
                                backgroundColor: 'tintDarker',
                            },
                        }}
                    >
                        <Box
                            sx={{
                                width: '100%',
                                height: IMAGE_HEIGHT,
                                objectFit: 'contain',
                            }}
                            src={item.logo?.url}
                            alt={item.title}
                            component="img"
                            padding={PADDING}
                            borderRadius={6}
                            backgroundColor="dark"
                        />
                        <Typography
                            marginTop={PADDING}
                            padding={3}
                            fontSize={24}
                            textAlign="center"
                            color="dark"
                            backgroundColor="white"
                            borderRadius={6}
                            boxShadow="0 1px 1px rgba(0,0,0,0.1), 0 2px 2px rgba(0,0,0,0.1)"
                        >
                            {item.title}
                        </Typography>
                    </Box>
                </Box>
            )}
        </Grid>
    );
}

function Dashboard({ title, path, defaultPath = '', pages = [], menu }) {
    setTitle(`${title} Dashboard`);

    const [menuItems, setMenuItems] = useState();
    const [selectedMenuItem, setSelectedMenuItem] = useState();

    const params = useParams();
    const param = menu ? params['*'].split('/')[0] : '';
    const localPath = menu ? `${path}/${param}` : path;

    // Load source data if expected
    useEffect(() => {
        if (menu?.source) {
            menu.source(setMenuItems);
        }
    }, []);

    // Retrieve selected menu item
    useEffect(() => {
        setSelectedMenuItem(null);
        if (menu?.source && param) {
            menu.source(setSelectedMenuItem, { slug: param });
        }
    }, [param]);

    // Dashboard Selection
    if (menu && !param) {
        return (
            <Container>
                <Page title={menu.title} icon={menu.icon}>
                    <SelectionMenu menu={menu} items={menuItems} path={path} />
                </Page>
            </Container>
        );
    }

    // Dashboard
    return (
        <Container>
            <RemoveTrailingSlash />
            <Sidebar
                title={title}
                localPath={localPath}
                path={path}
                pages={pages}
                param={param}
                menu={menu}
                menuItem={selectedMenuItem}
            />
            <Routes>
                {getPageRoutes({
                    pages,
                    selectedMenuItem,
                    localPath,
                    param,
                    menu,
                    defaultPath,
                    path,
                })}

                <Route
                    exact
                    path="*"
                    element={
                        <Page>
                            <NoData
                                title="404"
                                description="This Page Doesn't Exist"
                            />
                        </Page>
                    }
                />
            </Routes>
        </Container>
    );
}

function getPageRoutes({
    pages,
    selectedMenuItem,
    localPath,
    param,
    menu,
    defaultPath,
    path,
}) {
    const routes = [];

    // Pages
    for (let i in pages) {
        const page = pages[i];

        // Main Route
        routes.push({
            path: menu ? `/${param}/${page.slug}` : `/${page.slug}`,
            element: (
                <Page
                    title={page.title}
                    menu={selectedMenuItem}
                    icon={page.icon}
                    pages={page.pages}
                    path={`${localPath}/${page.slug}`}
                >
                    {page.Component ? (
                        <page.Component param={param} {...page.props} />
                    ) : (
                        <Navigate
                            replace
                            to={{
                                pathname: menu
                                    ? `${path}/${param}/${defaultPath}`
                                    : `${path}/${defaultPath}`,
                            }}
                        />
                    )}
                </Page>
            ),
        });

        // Child Route (/:child.param)
        if (page.child) {
            const { child } = page;
            routes.push({
                path: menu
                    ? `/${param}/${page.slug}/:${child.param}`
                    : `/${page.slug}/:${child.param}`,
                element: (
                    <Page
                        title={page.title}
                        menu={selectedMenuItem}
                        icon={child.icon}
                        path={`${localPath}/${page.slug}`}
                        back={1}
                    >
                        <child.Component param={param} {...child.props} />
                    </Page>
                ),
            });
        }

        // Subpages
        for (let j in page?.pages) {
            const subpage = page.pages[j];
            routes.push({
                path: menu
                    ? `/${param}/${page.slug}/${subpage.slug}`
                    : `/${page.slug}/${subpage.slug}`,
                element: (
                    <Page
                        title={subpage.title}
                        menu={selectedMenuItem}
                        icon={subpage.icon}
                        pages={subpage.pages}
                        back={1}
                    >
                        {page.Component ? (
                            <subpage.Component
                                param={param}
                                {...subpage.props}
                            />
                        ) : (
                            <Navigate
                                replace
                                to={{
                                    pathname: menu
                                        ? `${path}/${param}/${defaultPath}`
                                        : `${path}/${defaultPath}`,
                                }}
                            />
                        )}
                    </Page>
                ),
            });

            // Child Route (/:child.param)
            if (subpage.child) {
                const { child } = subpage;
                routes.push({
                    path: menu
                        ? `/${param}/${page.slug}/${subpage.slug}/:${child.param}`
                        : `/${page.slug}/${subpage.slug}/:${child.param}`,
                    element: (
                        <Page
                            title={child.title}
                            menu={selectedMenuItem}
                            icon={child.icon}
                            // path={`${localPath}/${page.slug}`}
                            back={2}
                        >
                            <child.Component param={param} {...child.props} />
                        </Page>
                    ),
                });
            }
        }
    }

    return routes.map((route, i) => (
        <Route key={i} exact path={route.path} element={route.element} />
    ));
}

function Sidebar({
    title,
    localPath,
    path,
    pages,
    param,
    menu,
    menuItem,
    backgroundColor = '#eee',
}) {
    const [open, setOpen] = useState(false);

    const headerRequired = title || (param && menu);

    return (
        <Box
            width={{ xs: '100%', md: 280 }}
            backgroundColor={backgroundColor}
            boxShadow="rgba(0, 0, 0, 0.3) 1px 1px 10px"
            zIndex={100}
            overflow="auto"
        >
            <Box
                backgroundColor="#22222270"
                height="calc(100vh - 70px)"
                width="100%"
                position="absolute"
                display={{ xs: open ? 'block' : 'none', md: 'none' }}
                zIndex={-1}
                onClick={() => setOpen(false)}
            />
            {headerRequired && (
                <Box padding={1} backgroundColor={backgroundColor}>
                    {title && (
                        <Box display="flex">
                            <Typography
                                fontSize={28}
                                fontWeight="bold"
                                textAlign="center"
                                width="100%"
                                padding={{ xs: 0, md: 2 }}
                                paddingBottom={{ xs: 0, md: 2 }}
                                paddingTop={{ xs: 0, md: 3 }}
                                borderRadius={3}
                            >
                                {title}
                            </Typography>
                            <Box
                                margin="auto"
                                position="absolute"
                                right={10}
                                top={70 + 4}
                                display={{ md: 'none' }}
                            >
                                <IconButton onClick={() => setOpen(!open)}>
                                    <DynamicIcon
                                        icon="Menu"
                                        colour={open ? 'disabled' : 'dark'}
                                        size={34}
                                    />
                                </IconButton>
                            </Box>
                        </Box>
                    )}
                    {param && menu && (
                        <NavItem
                            page={{
                                title: menuItem?.title,
                                icon: 'KeyboardBackspace',
                                iconStyle: { position: 'absolute' },
                                titleStyle: { textAlign: 'center' },
                            }}
                            path={path}
                            variant="brand"
                            sx={{
                                marginBottom: 0,
                                marginTop: 1,
                                display: {
                                    xs: open ? 'block' : 'none',
                                    md: 'block',
                                },
                            }}
                        />
                    )}
                </Box>
            )}
            <Box
                display={{ xs: open ? 'initial' : 'none', md: 'initial' }}
                position={{ xs: 'absolute', md: 'initial' }}
                maxHeight={{ xs: '50%', md: undefined }}
                width="100%"
                backgroundColor={backgroundColor}
                padding={{ xs: 1, md: 0 }}
                paddingTop={{ xs: 0 }}
                overflow="auto"
            >
                {headerRequired && (
                    <Divider
                        sx={{
                            margin: 1,
                            marginBottom: 2,
                            display: { xs: 'none', md: 'block' },
                        }}
                    />
                )}
                <Box>
                    <List
                        sx={{
                            margin: 1,
                            marginTop: { xs: 0, md: 1 },
                            padding: 0,
                        }}
                    >
                        {pages.map((page, i) => (
                            <NavItem
                                key={i}
                                page={page}
                                path={`${localPath}${
                                    page.slug ? `/${page.slug}` : ''
                                }`}
                                disabled={!page.Component}
                                onClick={() => setOpen(false)}
                            />
                        ))}
                    </List>
                </Box>
            </Box>
        </Box>
    );
}

function NavItem({ page, path, variant, sx, disabled, onClick = () => {} }) {
    const { pathname } = useLocation();

    // Hide option if disabled
    if (disabled) {
        return null;
    }

    const isCurrent = page.slug ? pathname.includes(path) : pathname === path;

    return (
        <Box>
            <ListItem
                disablePadding
                variant={variant || (isCurrent ? 'dark' : undefined)}
                sx={{ marginBottom: 1, ...sx }}
            >
                <Link
                    to={path}
                    style={{
                        color: 'inherit',
                        textDecoration: 'none',
                        width: '100%',
                    }}
                    onClick={onClick}
                >
                    <ListItemButton disabled={disabled}>
                        {page?.icon && (
                            <DynamicIcon
                                sx={{ marginRight: 1.5, ...page.iconStyle }}
                                icon={page.icon}
                            />
                        )}

                        <Typography
                            width="100%"
                            sx={page.titleStyle}
                            padding={1}
                        >
                            {page?.title || '. . .'}
                        </Typography>
                    </ListItemButton>
                </Link>
            </ListItem>
            {page.divider && <Divider sx={{ marginTop: 2, marginBottom: 2 }} />}
        </Box>
    );
}

function RemoveTrailingSlash({ ...rest }) {
    const location = useLocation();

    // If the last character of the url is '/'
    if (location.pathname.match('/.*/$')) {
        return (
            <Navigate
                replace
                {...rest}
                to={{
                    pathname: location.pathname.replace(/\/+$/, ''),
                    search: location.search,
                }}
            />
        );
    } else return null;
}

export default Dashboard;

import { Box } from '@mui/material';
import CMSForm from '../cms/CMSForm';

function BrokerInviteClient() {
    return (
        <Box>
            <CMSForm
                api="broker"
                create="invite"
                inputs={[
                    [{ id: 'email' }],
                    [{ id: 'firstName' }, { id: 'lastName' }],
                ]}
                buttonText="Send Invite"
                backgroundColor="transparent"
                innerBackgroundColor="transparent"
                success="Client invited successfully!"
            />
        </Box>
    );
}

export default BrokerInviteClient;

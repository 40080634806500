import React from 'react';
import moment from 'moment';
import { Box, Typography } from '@mui/material';
import NoData from '../common/NoData';
import ViewMoreContainer from '../common/ViewMoreContainer';
import { Link } from 'react-router-dom';

function BidHistory({ bids }) {
    return (
        <ViewMoreContainer height={250}>
            {bids && bids.length > 0 ? (
                bids.map((bid, i) => (
                    <Box
                        key={i}
                        height={50}
                        width="100%"
                        display="flex"
                        alignItems="center"
                        paddingLeft={5}
                        paddingRight={5}
                        backgroundColor={i === 0 && 'dark'}
                        borderRadius={10}
                    >
                        <Typography
                            fontSize={14}
                            letterSpacing={1}
                            style={{
                                flex: 3,
                            }}
                            color={i === 0 ? 'white' : 'text.secondary'}
                            fontWeight={i === 0 ? 'medium' : 'regular'}
                        >
                            {moment(bid.createdAt).format(`MMM D [at] h:mm A`)}
                        </Typography>
                        <Typography
                            fontSize={14}
                            letterSpacing={1}
                            style={{ flex: 3 }}
                            color={i === 0 ? 'white' : 'text.secondary'}
                            fontWeight={i === 0 ? 'medium' : 'regular'}
                        >
                            <Link
                                to={`/user/${bid.user.username}/purchases`}
                                sx={{
                                    textDecoration: 'none',
                                    color: 'inherit',
                                }}
                            >
                                {`@${bid.user.username}`}
                            </Link>
                        </Typography>
                        <Typography
                            fontSize={14}
                            letterSpacing={1}
                            style={{ flex: 1 }}
                            color={i === 0 ? 'white' : 'text.secondary'}
                            fontWeight={i === 0 ? 'medium' : 'regular'}
                            textAlign="right"
                        >
                            {`$${bid.amount.toLocaleString()}`}
                        </Typography>
                    </Box>
                ))
            ) : (
                <NoData
                    description="There are no bids to display"
                    paddingTop={6}
                    paddingBottom={3}
                />
            )}
        </ViewMoreContainer>
    );
}

export default BidHistory;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PropertyCard from '../components/cards/PropertyCard';

import { socket } from '../scripts/socket';
import CategoryList from '../components/common/CategoryList';
import Footer from '../components/common/Footer';
import ProjectCard from '../components/cards/ProjectCard';
import { Button, Box, Typography, Grid, useMediaQuery } from '@mui/material';

import './Home.css';
import CardList from '../components/common/CardList';
import ScrollContainer from 'react-indiana-drag-scroll';

import imgHero from '../assets/home_hero.jpg';
import Introduction from '../components/copy/Introduction';
import PaddedBox from '../components/common/PaddedBox';
import DynamicIcon from '../components/common/DynamicIcon';
import { navigateScroll } from '../scripts/animation';
import { Link } from 'react-router-dom';

function Home() {
    const [auctions, setAuctions] = useState();
    const [projects, setProjects] = useState([]);

    useEffect(() => {
        axios
            .post('/auction/get', { limit: 6 })
            .then((res) => {
                setAuctions(res.data.rows);
            })
            .catch(function () {});

        axios
            .post(`/project/getDisplay`)
            .then((res) => {
                setProjects(res.data);
            })
            .catch(function () {});
    }, []);

    // Receive Created Bids
    useEffect(() => {
        let mounted = true;

        if (auctions?.length > 0) {
            // Bid - Create
            socket.on(`bid/create`, (info) => {
                if (mounted) {
                    const _auctions = [...auctions];
                    const index = _auctions.findIndex(
                        (item) => item.id === info.auctionId
                    );
                    // Update property card
                    _auctions[index].minimumBid = info.minimumBid;
                    setAuctions(_auctions);
                }
            });

            // Auction - Extend
            socket.on(`auction/extend`, (info) => {
                if (mounted) {
                    const _auctions = [...auctions];
                    const index = _auctions.findIndex(
                        (item) => item.id === info.auctionId
                    );
                    _auctions[index].endDate = info.endDate;
                    setAuctions(_auctions);
                }
            });
        }

        // Unmount
        return () => {
            mounted = false;
        };
    }, [auctions]);

    return (
        <Box backgroundColor="tint">
            <HomeHeader />

            <Introduction />

            <Section>
                {/* {projects?.length > 0 && (
                    <Box sx={{ marginBottom: 12 }}>
                        <ViewAllSection title="New Project Releases">
                            <HorizontalList>
                                {projects.map((item) => (
                                    <ImageListItem
                                        key={item.id}
                                        sx={{ display: 'inline-block' }}
                                    >
                                        <ProjectCard item={item} />
                                    </ImageListItem>
                                ))}
                            </HorizontalList>
                        </ViewAllSection>
                    </Box>
                )} */}

                <Box /*sx={{ marginBottom: 15 }}*/>
                    <ViewAllSection title="Opportunities" path="/marketplace">
                        <CardList
                            items={auctions}
                            Component={PropertyCard}
                            lg={4}
                            md={6}
                            padding={0}
                            vertical={0}
                            spacing={5.5}
                            noCenter
                        />
                    </ViewAllSection>
                </Box>

                {/* <ViewAllSection title="Browse By Category" path="/category">
                    <CategoryList />
                </ViewAllSection> */}
            </Section>

            <Footer />
        </Box>
    );
}

const HomeHeader = () => {
    const xs = useMediaQuery((theme) => theme.breakpoints.down('md'));

    return (
        <Box height="100vh" marginTop="-70px" width={1} backgroundColor="#333">
            <div className="home-header-overlay" />
            <Box
                className="home-header-content"
                sx={{
                    width: { xs: '100%', md: 850, lg: 1150 },
                    maxWidth: { xs: 500, md: 850, lg: 1150 },
                    paddingLeft: { xs: '20px', md: '150px', lg: '280px' },
                    paddingRight: { xs: '20px' },
                }}
            >
                <Typography
                    color="white"
                    sx={{
                        fontSize: { xs: 40, md: 50, lg: 65 },
                    }}
                    lineHeight={1.2}
                    variant="heading"
                >
                    Now Featuring:
                </Typography>
                <Typography
                    color="white"
                    sx={{
                        fontSize: { xs: 40, md: 50, lg: 65 },
                    }}
                    gutterBottom
                    lineHeight={1.2}
                    variant="heading"
                >
                    The Platform at Station Park.
                </Typography>
                <Typography
                    color="white"
                    sx={{
                        fontSize: { xs: 16, md: 20, lg: 24 },
                    }}
                    fontFamily="urbanist"
                    marginTop={2}
                >
                    Upside makes it easy to invest in curated pre-construction
                    projects through top brokers across Canada.
                </Typography>
                <Grid
                    container
                    sx={{
                        marginTop: 6,
                        marginBottom: 5,
                    }}
                    gap={{ xs: 1, md: 1.5 }}
                >
                    <Button
                        component={Link}
                        to="/marketplace"
                        variant="light"
                        size="medium"
                    >
                        <Typography
                            fontWeight="bold"
                            fontFamily="urbanist"
                            sx={{
                                fontSize: { xs: 16, md: 18, lg: 20 },
                            }}
                        >
                            View Opportunities
                        </Typography>
                    </Button>
                    {/* {!xs && (
                        <Button
                            component={Link}
                            to="/about"
                            variant="light"
                            size="medium"
                        >
                            <Typography
                                fontWeight="bold"
                                fontFamily="urbanist"
                                sx={{
                                    fontSize: { xs: 16, md: 18, lg: 20 },
                                }}
                            >
                                About Us
                            </Typography>
                        </Button>
                    )} */}
                </Grid>
            </Box>
            <img className="home-header-image" src={imgHero} alt="" />
        </Box>
    );
};

const Section = ({ children }) => {
    const PADDING = { xs: 10, md: 12, lg: 15 };
    return (
        <PaddedBox top={PADDING} bottom={PADDING}>
            <Box flexDirection="column" display="flex">
                {children}
            </Box>
        </PaddedBox>
    );
};

const ViewAllSection = ({ title, path, children }) => {
    return (
        <div>
            <Box
                sx={{
                    display: 'flex',
                    marginBottom: 5,
                }}
            >
                <Typography
                    sx={{
                        fontSize: 35,
                    }}
                    color="dark"
                    letterSpacing={0.5}
                    marginBottom={'5px'}
                    style={{ flex: 1 }}
                    variant="heading"
                >
                    {title}
                </Typography>

                {path && (
                    <div style={{ margin: 'auto' }}>
                        <Button
                            component={Link}
                            to={path}
                            variant="transparent"
                        >
                            <Typography
                                fontSize={22}
                                fontFamily="urbanist"
                                fontWeight="medium"
                                sx={{ textDecoration: 'underline' }}
                            >
                                View All
                            </Typography>
                        </Button>
                    </div>
                )}
            </Box>
            {children}
        </div>
    );
};

const HorizontalList = ({ children }) => {
    return (
        <Box position="relative">
            <ScrollContainer className="project-scroll-container">
                {children}
            </ScrollContainer>
            <Box
                position="absolute"
                top="calc(50% - 17px)"
                padding={3}
                display="flex"
                justifyContent="space-between"
                sx={{
                    pointerEvents: 'none',
                    left: { xs: 0, lg: -100 },
                    width: { xs: 1, lg: 'calc(100% + 200px)' },
                }}
            >
                <Button
                    onClick={() =>
                        navigateScroll(-450, 'project-scroll-container')
                    }
                    variant="grey"
                    sx={{ padding: 2, pointerEvents: 'all' }}
                >
                    <Box sx={{ transform: 'rotate(180deg)' }}>
                        <DynamicIcon icon="ArrowForwardIos" size={30} />
                    </Box>
                </Button>
                <Button
                    onClick={() =>
                        navigateScroll(450, 'project-scroll-container')
                    }
                    variant="grey"
                    sx={{ padding: 2, pointerEvents: 'all' }}
                >
                    <DynamicIcon icon="ArrowForwardIos" size={30} />
                </Button>
            </Box>
        </Box>
    );
};

export default Home;

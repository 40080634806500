import './Administration.css';
import Dashboard from '../components/common/Dashboard';

import ProjectForm from '../components/cms/ProjectForm';
import AddressForm from '../components/cms/AddressForm';
import ModelForm from '../components/cms/ModelForm';
import AuctionForm from '../components/cms/AuctionForm';
import DeveloperForm from '../components/cms/DeveloperForm';
import BuilderForm from '../components/cms/BuilderForm';
import AmenityForm from '../components/cms/AmenityForm';
import PropertyTypeForm from '../components/cms/PropertyTypeForm';
import CouponForm from '../components/cms/CouponForm';
import UpgradeTypeForm from '../components/cms/UpgradeTypeForm';

import { default as ProjectList } from './administration/Dashboard';
import Project from './administration/Project';
import Auctions from './administration/Auctions';
import Addresses from './administration/Addresses';
import Projects from './administration/Projects';
import Builders from './administration/Builders';
import Amenities from './administration/Amenities';
import PropertyTypes from './administration/PropertyTypes';
import Users from './administration/Users';
import Invite from './administration/Invite';
import Media from './administration/Media';
import Developers from './administration/Developers';
import Coupons from './administration/Coupons';
import Models from './administration/Models';
import UpgradeTypes from './administration/UpgradeTypes';
import PurchaseTracker from '../components/purchase/PurchaseTracker';

function Administration() {
    return (
        <Dashboard
            title="Administration"
            path="/administration"
            defaultPath="dashboard"
            pages={[
                {
                    title: 'Dashboard',
                    slug: '',
                    Component: null,
                    icon: 'Speed',
                },
                {
                    title: 'Dashboard',
                    slug: 'dashboard',
                    Component: ProjectList,
                    icon: 'Speed',
                    child: {
                        Component: Project,
                        param: 'slug',
                    },
                },
                {
                    title: 'Purchase Tracker',
                    slug: 'purchase-tracker',
                    Component: PurchaseTracker,
                    props: {
                        config: {
                            admin: true,
                            developer: false,
                            broker: false,
                            api: `/administration/getPurchaseTracker`,
                        },
                    },
                    icon: 'GpsFixed',
                },
                {
                    title: 'Users',
                    slug: 'users',
                    Component: Users,
                    icon: 'AccountCircle',
                    pages: [
                        {
                            title: 'Invite Users to Upside',
                            slug: 'invite',
                            Component: Invite,
                            icon: 'PersonAddAlt1',
                        },
                    ],
                    divider: true,
                },
                {
                    title: 'Listings',
                    slug: 'listings',
                    Component: Auctions,
                    icon: 'EventNote',
                    pages: [
                        {
                            title: 'Create Listing',
                            slug: 'create',
                            Component: AuctionForm,
                            icon: 'Add',
                            child: {
                                title: 'Edit Listing',
                                Component: AuctionForm,
                                param: 'slug',
                            },
                        },
                    ],
                },
                {
                    title: 'Models',
                    slug: 'models',
                    Component: Models,
                    icon: 'AccountTree',
                    pages: [
                        {
                            title: 'Create Model',
                            slug: 'create',
                            Component: ModelForm,
                            icon: 'Add',
                            child: {
                                title: 'Edit Model',
                                Component: ModelForm,
                                param: 'id',
                            },
                        },
                    ],
                },
                {
                    title: 'Addresses',
                    slug: 'addresses',
                    Component: Addresses,
                    icon: 'Home',
                    pages: [
                        {
                            title: 'Create Address',
                            slug: 'create',
                            Component: AddressForm,
                            icon: 'Add',
                            child: {
                                title: 'Edit Address',
                                Component: AddressForm,
                                param: 'id',
                            },
                        },
                    ],
                },
                {
                    title: 'Projects',
                    slug: 'projects',
                    Component: Projects,
                    icon: 'Apartment',
                    pages: [
                        {
                            title: 'Create Project',
                            slug: 'create',
                            Component: ProjectForm,
                            icon: 'Add',
                            child: {
                                title: 'Edit Project',
                                Component: ProjectForm,
                                param: 'slug',
                            },
                        },
                    ],
                },
                {
                    title: 'Developers',
                    slug: 'developers',
                    Component: Developers,
                    icon: 'AccountCircle',
                    pages: [
                        {
                            title: 'Create Developer',
                            slug: 'create',
                            Component: DeveloperForm,
                            icon: 'Add',
                            child: {
                                title: 'Edit Developer',
                                Component: DeveloperForm,
                                param: 'username',
                            },
                        },
                    ],
                    divider: true,
                },
                {
                    title: 'Builders',
                    slug: 'builders',
                    Component: Builders,
                    icon: 'Groups',
                    pages: [
                        {
                            title: 'Create Builder',
                            slug: 'create',
                            Component: BuilderForm,
                            icon: 'Add',
                            child: {
                                title: 'Edit Builder',
                                Component: BuilderForm,
                                param: 'id',
                            },
                        },
                    ],
                },
                {
                    title: 'Amenities',
                    slug: 'amenities',
                    Component: Amenities,
                    icon: 'EmojiEmotions',
                    pages: [
                        {
                            title: 'Create Amenity',
                            slug: 'create',
                            Component: AmenityForm,
                            icon: 'Add',
                            child: {
                                title: 'Edit Amenity',
                                Component: AmenityForm,
                                param: 'id',
                            },
                        },
                    ],
                },
                {
                    title: 'Property Types',
                    slug: 'propertyTypes',
                    Component: PropertyTypes,
                    icon: 'Home',
                    pages: [
                        {
                            title: 'Create Property Type',
                            slug: 'create',
                            Component: PropertyTypeForm,
                            icon: 'Add',
                            child: {
                                title: 'Edit Property Type',
                                Component: PropertyTypeForm,
                                param: 'id',
                            },
                        },
                    ],
                },
                {
                    title: 'Upgrade Types',
                    slug: 'upgradeTypes',
                    Component: UpgradeTypes,
                    icon: 'Upgrade',
                    pages: [
                        {
                            title: 'Create Upgrade Type',
                            slug: 'create',
                            Component: UpgradeTypeForm,
                            icon: 'Add',
                            child: {
                                title: 'Edit Upgrade Type',
                                Component: UpgradeTypeForm,
                                param: 'id',
                            },
                        },
                    ],
                },
                {
                    title: 'Coupons',
                    slug: 'coupons',
                    Component: Coupons,
                    icon: 'LocalOffer',
                    pages: [
                        {
                            title: 'Create Coupon',
                            slug: 'create',
                            Component: CouponForm,
                            icon: 'Add',
                            child: {
                                title: 'Edit Coupon',
                                Component: CouponForm,
                                param: 'id',
                            },
                        },
                    ],
                },
                {
                    title: 'Media',
                    slug: 'media',
                    Component: Media,
                    icon: 'Image',
                },
            ]}
        />
    );
}

export default Administration;

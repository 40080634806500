import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import CollapseContainer from '../common/CollapseContainer';
import { DataGrid } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import { Tooltip, IconButton } from '@mui/material';
import { getRow } from '../../scripts/table';
import PopupForm from '../common/PopupForm';
import EditIcon from '@mui/icons-material/Edit';
import { Link } from 'react-router-dom';

function CouponList() {
    const [data, setData] = useState([]);

    const [row, setRow] = useState();
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);

    useEffect(() => {
        axios
            .post(`/coupon/getAll`)
            .then((res) => {
                setData(res.data);
            })
            .catch(function () {});
    }, []);

    const columns = [
        {
            field: 'id',
            headerName: 'ID',
            width: 50,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'username',
            headerName: 'Username',
            width: 130,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'code',
            headerName: 'Code',
            width: 150,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'discount',
            headerName: 'Discount',
            width: 100,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'quantity',
            headerName: 'Quantity',
            width: 100,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'description',
            headerName: 'Description',
            flex: 1,
            renderCell: (params) => {
                return (
                    <div className="tooltip-admin">
                        <div>{params.value}</div>
                        <span className="tooltiptext-admin">
                            {params.value}
                        </span>
                    </div>
                );
            },
        },
        {
            field: 'startDate',
            headerName: 'Start Date',
            width: 140,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                return moment(params.row.startDate).format(`MMM D[,] h:mm A`);
            },
        },
        {
            field: 'expirationDate',
            headerName: 'Expiration Date',
            width: 140,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                return moment(params.row.expirationDate).format(
                    `MMM D[,] h:mm A`
                );
            },
        },
        {
            field: 'createdAt',
            headerName: 'Created At',
            width: 140,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'edit',
            headerName: 'Edit',
            width: 90,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                return (
                    <IconButton
                        component={Link}
                        to={`/administration/coupons/create/${params.row.id}`}
                        variant="blue"
                    >
                        <EditIcon style={{ color: '#166cdb' }} />
                    </IconButton>
                );
            },
            sortable: false,
        },
        {
            field: 'delete',
            headerName: 'Delete',
            width: 100,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                const onClick = (e) => {
                    setRow(getRow(e, params));
                    setShowDeleteDialog(true);
                };

                return (
                    <Tooltip title="Delete coupon">
                        <IconButton onClick={onClick}>
                            <DeleteIcon style={{ color: '#ee3333' }} />
                        </IconButton>
                    </Tooltip>
                );
            },
            sortable: false,
        },
    ];

    const deleteCoupon = (row) => {
        axios
            .post(`/coupon/delete`, { id: row.id })
            .then(() => {
                // Update row to be expired (deleted)
                const _data = [...data];
                const target = _data.filter((a) => a.id === row.id);
                target[0].expirationDate = new Date().now;
                setData(_data);
                setShowDeleteDialog(false);
            })
            .catch(function () {});
    };

    function getRowClassName(params) {
        const row = params.row;

        const now = moment();
        const validStart = now.isAfter(moment(row.startDate));
        const validExpiration = now.isBefore(moment(row.expirationDate));

        if (!validExpiration) {
            return 'row-danger';
        } else if (!validStart) {
            return 'row-warning';
        }
    }

    return (
        <div>
            <CollapseContainer title="Coupons">
                <div style={{ height: 670, width: '100%' }}>
                    <DataGrid
                        rows={data.map((item) => {
                            return {
                                id: item.id,
                                username: item.user?.username || '*',
                                code: item.code,
                                discount: item.discount,
                                quantity: item.quantity || '*',
                                description: item.description,
                                startDate: item.startDate,
                                expirationDate: item.expirationDate,
                                createdAt: moment(item.createdAt).format(
                                    `MMM D[,] h:mm A`
                                ),
                            };
                        })}
                        columns={columns}
                        pageSize={10}
                        rowsPerPageOptions={[10]}
                        /*checkboxSelection*/
                        disableSelectionOnClick
                        getRowClassName={getRowClassName}
                        initialState={{
                            sorting: {
                                sortModel: [
                                    { field: 'expirationDate', sort: 'desc' },
                                ],
                            },
                        }}
                    />
                </div>
            </CollapseContainer>

            <PopupForm
                title="Delete coupon?"
                body={<div>Are you sure you want to delete this coupon?</div>}
                action={() => deleteCoupon(row)}
                show={showDeleteDialog}
                setShow={setShowDeleteDialog}
            />
        </div>
    );
}

export default CouponList;

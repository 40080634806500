import React from 'react';
import { Box, Typography } from '@mui/material';

function NoData({
    title,
    description = 'There is nothing to display here.',
    padding,
    paddingTop = 14,
    paddingBottom = 14,
    textAlign = 'center',
}) {
    return (
        <Box
            maxWidth={550}
            textAlign={textAlign}
            paddingTop={padding || paddingTop}
            paddingBottom={padding || paddingBottom}
            display="flex"
            justifyContent={textAlign}
            alignItems={textAlign}
            flexDirection="column"
            margin="auto"
        >
            <Title value={title} />
            <Description value={description} />
        </Box>
    );
}

const Title = ({ value }) => {
    if (!value) {
        return null;
    }

    return (
        <Typography
            fontSize={23}
            fontWeight="bold"
            textTransform="uppercase"
            letterSpacing={2}
            marginBottom={5}
            lineHeight={2}
        >
            {value}
        </Typography>
    );
};

const Description = ({ value }) => {
    if (!value) {
        return null;
    }

    return (
        <Typography
            fontSize={17}
            fontWeight="light"
            color="text.secondary"
            letterSpacing={1}
            component="div"
        >
            {value}
        </Typography>
    );
};

export default NoData;

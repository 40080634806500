import { useEffect, useState } from 'react';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Typography } from '@mui/material';

function Media() {
    const [deletedMedia, setDeletedMedia] = useState([]);

    useEffect(() => {
        axios
            .post(`/media/getDeleted`)
            .then((res) => {
                setDeletedMedia(res.data);
            })
            .catch(function () {});
    }, []);

    return (
        <Box>
            <Typography marginBottom={2} fontSize={20} fontWeight="bold">
                Soft-Deleted Media
            </Typography>
            <DataGrid
                autoHeight
                rows={deletedMedia}
                columns={[
                    {
                        field: 'id',
                        headerName: 'ID',
                    },
                    {
                        field: 'url',
                        headerName: 'URL',
                        flex: 1,
                    },
                    {
                        field: 'key',
                        headerName: 'Key',
                        flex: 1,
                    },
                ]}
            />
        </Box>
    );
}

export default Media;

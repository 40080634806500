import { ArrowBackIos } from '@mui/icons-material';
import { Box, CardMedia, Typography } from '@mui/material';
import { useEffect } from 'react';
import Footer from '../components/common/Footer';

import image1 from '../assets/404_1.jpg';
import image2 from '../assets/404_2.jpg';
import SocialButtons from '../components/common/SocialButtons';
import { Link } from 'react-router-dom';

function NotFound({ onLoad }) {
    useEffect(() => {
        if (onLoad) {
            onLoad();
        }
    }, [onLoad]);

    return (
        <Box>
            <Box
                sx={{
                    height: 'calc(100vh - 70px)',
                    backgroundColor: 'dark',
                    flexDirection: { xs: 'column', md: 'row' },
                    display: 'flex',
                }}
            >
                <Box
                    sx={{
                        width: { xs: '100%', md: '30%' },
                        height: { xs: '25%', md: '100%' },
                        display: { xs: 'none', md: 'flex' },
                        justifyContent: 'center',
                        alignItems: 'center',
                        margin: 'auto',
                        flexDirection: 'column',
                    }}
                    backgroundColor="#666"
                >
                    <CardMedia
                        component="img"
                        sx={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                        }}
                        image={image2}
                    />
                    <Box height={50} width="100%" backgroundColor="#3e3e3e" />
                    <Box
                        height={200}
                        width="100%"
                        backgroundColor="#222"
                        display="flex"
                    >
                        <GoHome />
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                    flex={1}
                    height="100%"
                    backgroundColor="dark"
                    position="relative"
                >
                    <CardMedia
                        component="img"
                        sx={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            background: 'rgba(0, 0, 0, 0.5)',
                        }}
                        image={image1}
                    />
                    <Box
                        sx={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            background: '#222222bb',
                        }}
                    />
                    <Box color="white" zIndex={1} margin="auto">
                        <Typography
                            sx={{
                                fontSize: { xs: 20, md: 25, lg: 30 },
                            }}
                            fontWeight="medium"
                        >
                            This Page
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: { xs: 20, md: 25, lg: 30 },
                            }}
                            fontWeight="medium"
                        >
                            Doesn't Exist
                        </Typography>
                        <Typography
                            sx={{ fontSize: { xs: 140, md: 175, lg: 210 } }}
                            fontWeight="medium"
                            marginTop={-2}
                        >
                            404
                        </Typography>
                        <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                            <GoHome />
                        </Box>
                    </Box>
                </Box>
                <Box
                    sx={{
                        width: { xs: '100%', md: 200 },
                        height: { xs: 150, md: '100%' },
                        paddingTop: { xs: 0, md: '20vh' },
                        paddingBottom: { xs: 0, md: '20vh' },
                        paddingLeft: { xs: '20vw', md: 0 },
                        paddingRight: { xs: '20vw', md: 0 },
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        margin: 'auto',
                        flexDirection: { xs: 'row', md: 'column' },
                    }}
                    width={300}
                    backgroundColor="white"
                >
                    <SocialButtons />
                </Box>
            </Box>
            <Footer />
        </Box>
    );
}

const GoHome = () => {
    return (
        <Typography
            component={Link}
            to="/"
            fontSize={25}
            color="white"
            fontWeight="medium"
            textAlign="center"
            margin="auto"
            paddingTop={2}
            style={{ textDecoration: 'none' }}
        >
            <ArrowBackIos fontSize="small" />
            Go Home
        </Typography>
    );
};

export default NotFound;

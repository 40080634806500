import React from 'react';
import { Box, Typography } from '@mui/material';

function ViewAllContainer({
    title,
    path,
    length,
    children,
    hideEmpty,
    maxWidth = 800,
}) {
    if (hideEmpty && length === 0) {
        return null;
    }

    return (
        <Box maxWidth={maxWidth}>
            <Box
                sx={{
                    display: 'flex',
                    marginBottom: 2,
                    paddingTop: 4,
                    paddingLeft: { xs: 2, md: 0 },
                }}
            >
                <Typography
                    fontSize={24}
                    marginBottom={1}
                    style={{ flex: 1 }}
                    variant="heading"
                >
                    {title} {length ? `(${length})` : ''}
                </Typography>
            </Box>
            {children}
        </Box>
    );
}

export default ViewAllContainer;

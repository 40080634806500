import { Box, LinearProgress, Typography } from '@mui/material';
import moment from 'moment';
import DynamicIcon from './DynamicIcon';

function SegmentedProgressBar({
    inputs = [],
    reverse = false,
    Child,
    childAttribute,
    childProps,
}) {
    const MIN_WIDTH = '180px';

    function DateString({ dateString }) {
        if (!dateString) {
            return '---';
        }

        return moment(dateString).format('MMM D, YYYY');
    }

    return (
        <Box display="flex" flexWrap="wrap" gap="5px">
            {inputs.map((input, i) => {
                const startDate = moment(input.startDate);
                const endDate = moment(input.endDate);
                const now = moment();

                const timeRequired = startDate.diff(endDate, 'seconds');
                const timeTaken = startDate.diff(now, 'seconds');

                let percent = timeTaken / timeRequired;

                // TODO: Constant definiton for these statuses like on backend? Ideally a better solution than 2 files.
                let statusColour = '#c9bfad';
                let icon = null;
                if (input.status === 'scheduled') {
                    statusColour = 'status.info';
                } else if (input.status === 'started') {
                    statusColour = 'status.progress';
                    icon = 'Pending';
                } else if (input.status === 'late') {
                    statusColour = 'status.danger';
                    icon = 'Cancel';
                } else if (input.status === 'warning') {
                    statusColour = 'status.warning';
                    icon = 'Warning';
                } else if (input.status === 'done') {
                    statusColour = 'status.success';
                    icon = 'CheckCircle';
                }

                // 0% if not started / no date
                if (
                    !input.startDate ||
                    !input.endDate ||
                    now.isBefore(startDate)
                ) {
                    percent = 0;
                }
                // 100% if past end date
                else if (now.isAfter(endDate)) {
                    percent = 1;
                }

                return (
                    <Box
                        key={i}
                        flex={`1 1 ${MIN_WIDTH}`}
                        minWidth={MIN_WIDTH}
                        maxWidth="100%"
                        marginBottom={1}
                    >
                        <Box
                            width="100%"
                            display="flex"
                            position="relative"
                            color={statusColour}
                        >
                            <LinearProgress
                                value={percent * 100}
                                variant="determinate"
                                color="inherit"
                                sx={{
                                    width: '100%',
                                    height: '100%',
                                    position: 'absolute',
                                    borderRadius: 10,
                                }}
                            />
                            <Box
                                display="flex"
                                marginTop={0.25}
                                marginBottom={0.25}
                                sx={{
                                    width: '100%',
                                    height: '100%',
                                    position: 'relative',
                                }}
                            >
                                <Typography
                                    textAlign="center"
                                    fontSize={13}
                                    color="white"
                                    paddingLeft={1}
                                    paddingRight={1}
                                    borderRadius={100}
                                    backgroundColor="#00000050"
                                    margin="auto"
                                >
                                    {icon && (
                                        <DynamicIcon
                                            icon={icon}
                                            size={15}
                                            sx={{
                                                marginBottom: 0.25,
                                                marginRight: 0.5,
                                            }}
                                        />
                                    )}
                                    {input.title}
                                </Typography>
                            </Box>
                        </Box>
                        <Box width="100%" display="flex">
                            {i === 0 && (
                                <Typography flex={1} fontSize={14}>
                                    <DateString dateString={input.startDate} />
                                </Typography>
                            )}
                            {i > 0 && !reverse && (
                                <Typography flex={1} fontSize={14}>
                                    <DateString dateString={input.startDate} />
                                </Typography>
                            )}
                            {(i === inputs.length - 1 || reverse) && (
                                <Typography
                                    flex={1}
                                    fontSize={14}
                                    textAlign="right"
                                >
                                    <DateString dateString={input.endDate} />
                                </Typography>
                            )}
                        </Box>
                        {Child && childAttribute && (
                            <Child
                                data={input[childAttribute]}
                                props={childProps}
                            />
                        )}
                    </Box>
                );
            })}
        </Box>
    );
}

export default SegmentedProgressBar;

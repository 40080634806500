import React, { useEffect, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import { Box, Typography } from '@mui/material';
import DynamicIcon from './DynamicIcon';

function CollapseContainer({
    children,
    title,
    description,
    expandDefault = true,
    height,
    backgroundColor = 'black',
    containerBackgroundColor = '#eee',
    color = 'white',
    barHeight = 40,
    lock = false,
    boxShadow = '0 1px 1px rgba(0,0,0,0.01), 0 2px 2px rgba(0,0,0,0.1)',
    sx,
    innerSx,
    handleRefresh,
    loading,
}) {
    const [expand, setExpand] = useState(expandDefault);
    const [collapsed, setCollapsed] = useState(!expand);

    const SLIM = containerBackgroundColor === backgroundColor;
    const BORDER_RADIUS = SLIM ? 10 : 16;

    useEffect(() => {
        setExpand(expandDefault);
    }, [expandDefault]);

    useEffect(() => {
        if (expand) {
            setCollapsed(false);
        }
    }, [expand]);

    const ExpandMore = styled((props) => {
        const { expand, ...other } = props;
        return (
            <Box>
                <IconButton {...other} />
            </Box>
        );
    })(({ theme, expand }) => ({
        transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
        color: color,
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    }));

    return (
        <Box
            className="collapse-menu"
            borderRadius={`${BORDER_RADIUS}px`}
            boxShadow={boxShadow}
            width="100%"
            marginBottom={2}
            sx={sx}
        >
            <Box
                sx={{
                    display: 'flex',
                    backgroundColor: backgroundColor,
                    color: color,
                    minHeight: `${barHeight}px`,
                }}
                borderRadius={`${BORDER_RADIUS}px ${BORDER_RADIUS}px ${
                    expand ? 0 : collapsed ? BORDER_RADIUS : 0
                }px ${expand ? 0 : collapsed ? BORDER_RADIUS : 0}px`}
                className={lock ? undefined : 'clickable'}
                onClick={() => {
                    if (!lock) setExpand(!expand);
                }}
            >
                <Typography
                    marginTop="auto"
                    marginBottom="auto"
                    marginLeft={SLIM ? 1 : 3}
                    fontSize={20}
                    fontWeight="bold"
                    component="div"
                    paddingTop={1}
                    paddingBottom={1}
                >
                    {title}
                </Typography>
                {description && (
                    <Typography
                        marginTop="auto"
                        marginBottom="auto"
                        marginLeft={1.25}
                        fontSize={20}
                        fontWeight="medium"
                    >
                        {description}
                    </Typography>
                )}
                <Box
                    margin="auto"
                    display="flex"
                    marginRight={SLIM ? 0 : 1}
                    gap={1}
                >
                    {handleRefresh && (
                        <IconButton
                            onClick={(event) => {
                                event.stopPropagation();
                                if (!loading) {
                                    handleRefresh();
                                }
                            }}
                        >
                            <DynamicIcon
                                icon={loading ? 'Autorenew' : 'Refresh'}
                            />
                        </IconButton>
                    )}
                    {!lock && (
                        <ExpandMore
                            expand={expand}
                            aria-expanded={expand}
                            aria-label="show more"
                        >
                            <ExpandMoreIcon />
                        </ExpandMore>
                    )}
                </Box>
            </Box>
            <Collapse
                style={{
                    flexDirection: 'column',
                    display: 'flex',
                    overflow: 'auto',
                    maxHeight: height || 'auto',
                }}
                in={expand}
                timeout="auto"
                unmountOnExit
                className="hidden-scrollbar"
                onExited={() => setCollapsed(true)}
            >
                <Box
                    backgroundColor={containerBackgroundColor}
                    borderRadius={`0px 0px ${BORDER_RADIUS}px ${BORDER_RADIUS}px`}
                    sx={{ scroll: 'none', ...innerSx }}
                >
                    {children}
                </Box>
            </Collapse>
        </Box>
    );
}

export default CollapseContainer;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Button, IconButton, Typography } from '@mui/material';
import Avatar from './Avatar';
import PopupForm from '../common/PopupForm';
import { Link } from 'react-router-dom';

function UserSummary({ username }) {
    const [user, setUser] = useState({});

    const [showEmailForm, setShowEmailForm] = useState(false);
    const [emailFormErrors, setEmailFormErrors] = useState();
    const [emailProcessing, setEmailProcessing] = useState(false);

    useEffect(() => {
        let mounted = true;

        axios
            .get(`/user/getSummary/${username}`)
            .then((res) => {
                if (mounted) {
                    setUser(res.data);
                }
            })
            .catch(function () {});

        // Unmount
        return () => {
            mounted = false;
        };
    }, [username]);

    const handleSubmitEmail = (form) => {
        setEmailFormErrors(null);
        setEmailProcessing(true);

        axios
            .post(`/email/contactSeller/`, form)
            .then((res) => {
                setShowEmailForm(false);
            })
            .catch(function (err) {
                setEmailFormErrors(err?.response?.data?.errors);
            })
            .finally(() => {
                setEmailProcessing(false);
            });
    };

    const Item = ({ title, count }) => {
        return (
            <Typography
                fontSize={16}
                fontWeight="light"
                color="dark"
                letterSpacing={1}
            >
                {`${title} (${count || 0})`}
            </Typography>
        );
    };

    return (
        <Box
            marginBottom={3}
            sx={{
                paddingLeft: { xs: 0, md: 4 },
                paddingRight: { xs: 0, md: 4 },
            }}
        >
            <Box display="flex">
                <Box paddingRight={{ xs: 1, md: 3 }}>
                    <IconButton
                        sx={{
                            height: 70,
                            width: 70,
                            marginLeft: 'auto',
                            marginRight: 'auto',
                        }}
                        // href={`/user/${username}/listings`}
                    >
                        <Avatar username={username} />
                    </IconButton>
                </Box>
                <Box flex={1} display="flex">
                    <Typography
                        fontWeight="bold"
                        letterSpacing={1}
                        width={1}
                        // className="clickable"
                        // component={Link}
                        // to={`/user/${username}/listings`}
                        sx={{
                            textDecoration: 'none',
                            color: 'dark',
                            marginTop: 'auto',
                            marginBottom: 'auto',
                        }}
                    >
                        @{username}
                    </Typography>
                    {/* <Item title="Listings" count={user.listings} />
                    <Item title="Sold" count={user.sold} /> */}
                </Box>
                {/* <Box display="flex">
                    <Button
                        variant="white"
                        type="border"
                        size="small"
                        onClick={() => setShowEmailForm(true)}
                        sx={{ margin: 'auto' }}
                    >
                        Contact Seller
                    </Button>
                </Box> */}
            </Box>
            {/* <PopupForm
                title="Contact Seller"
                body="What would you like to ask the seller?"
                action={handleSubmitEmail}
                show={showEmailForm}
                setShow={setShowEmailForm}
                yes="Send"
                inputs={[
                    { id: 'Name', required: true },
                    { id: 'Email', required: true },
                    { id: 'Message', required: true, rows: 6 },
                ]}
                errors={emailFormErrors}
                setErrors={setEmailFormErrors}
                processing={emailProcessing}
            /> */}
        </Box>
    );
}

export default UserSummary;

import { useEffect, useState } from 'react';
import axios from 'axios';
import {
    Box,
    Checkbox,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    ListItemText,
    MenuItem,
    Select,
    Slider,
    Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useSearchParams } from 'react-router-dom';
import DynamicIcon from '../common/DynamicIcon';

function AuctionFilter({ setAuctions }) {
    const [brokers, setBrokers] = useState([]);

    // TODO: Use search params for filter config
    const [searchParams, setSearchParams] = useSearchParams();

    const [filter, setFilter] = useState({
        city: [],
        price: [0, 1250000],
        property_type_id: [],
        interior_square_feet: [0, 3500],
        bedroom_count: [0, 5],
        bathroom_count: [0, 4],
        parking_count: [0, 3],
        brokerIds: [],
    });

    useEffect(() => {
        axios
            .post('/auction/get', { filter })
            .then((res) => {
                setAuctions(res.data.rows);
            })
            .catch(function () {});

        axios
            .get(`/broker/getBrokers`)
            .then((res) => {
                setBrokers(res.data);
            })
            .catch(function () {});

        // TODO: Populate city list with all cities used in the address list
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter]);

    return null;

    return (
        <Box
            backgroundColor="white"
            padding={2}
            borderRadius={1}
            className="shadow0"
        >
            <Grid container display="flex">
                {/* <Dropdown
                id="property_type_id"
                label="Property Type"
                options={['1', '2', '3']}
                filter={filter}
                setFilter={setFilter}
            /> */}

                <Range
                    id="price"
                    label="Price"
                    min={0}
                    max={1250000}
                    filter={filter}
                    setFilter={setFilter}
                    labelProps={{ money: true }}
                    step={50000}
                />
                <Range
                    id="bathroom_count"
                    label="Bathrooms"
                    min={0}
                    max={4}
                    step={0.5}
                    filter={filter}
                    setFilter={setFilter}
                />
                <Range
                    id="bedroom_count"
                    label="Bedrooms"
                    min={0}
                    max={5}
                    filter={filter}
                    setFilter={setFilter}
                />
                <Range
                    id="interior_square_feet"
                    label="Square Feet"
                    min={0}
                    max={3500}
                    filter={filter}
                    setFilter={setFilter}
                    step={100}
                />
                <FormControl fullWidth>
                    <InputLabel>
                        <Typography
                            color="text.light"
                            backgroundColor="white"
                            paddingLeft={1}
                            paddingRight={2}
                            fontWeight="light"
                        >
                            Broker
                        </Typography>
                    </InputLabel>
                    <Select
                        id="broker"
                        value={filter.brokerIds}
                        multiple
                        onChange={(e) =>
                            setFilter({ ...filter, brokerIds: e.target.value })
                        }
                        fullWidth
                        renderValue={(ids) =>
                            ids.map((id) => {
                                const broker = brokers.filter(
                                    (b) => b.id === id
                                )[0];
                                return `${broker.user.username}, `;
                            })
                        }
                        placeholder=""
                    >
                        {brokers.map((broker) => (
                            <MenuItem value={broker.id} key={broker.id}>
                                <Checkbox
                                    checked={filter.brokerIds.includes(
                                        broker.id
                                    )}
                                />
                                <ListItemText primary={broker.user.username} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
        </Box>
    );
}

const Range = ({ id, label, filter, labelProps, setFilter, ...props }) => {
    // Limit # of requests by only updating the filter when the the drag is finished
    const [value, setValue] = useState(filter[id]);

    return (
        <Grid item xs={12} md={6} lg={3} paddingLeft={2} paddingRight={2}>
            <Box display="flex">
                <Label
                    value={value[0]}
                    textAlign="left"
                    {...labelProps}
                    flex={0.5}
                />
                <Label
                    value={label}
                    color="dark"
                    fontWeight="medium"
                    textAlign="center"
                    flex={1}
                />
                <Label
                    value={value[1]}
                    textAlign="right"
                    {...labelProps}
                    flex={0.5}
                />
            </Box>
            <CustomSlider
                value={value}
                defaultValue={value}
                label={label}
                onChange={(e) => setValue(e.target.value)}
                onChangeCommitted={(e) => setFilter({ ...filter, [id]: value })}
                sx={{
                    borderRadius: 0,
                    width: '100%',
                }}
                size="small"
                valueLabelDisplay="auto"
                {...props}
            />
        </Grid>
    );
};

const Label = ({ value, money, ...props }) => {
    var text = value;

    if (money) {
        text = `$${value?.toLocaleString()}`;
    } else {
        text = value?.toLocaleString();
    }

    return (
        <Typography color="text.light" {...props}>
            {text}
        </Typography>
    );
};

// const Dropdown = ({ id, label, options = [], filter, setFilter, ...props }) => {
//     return (
//         <FormControl
//             fullWidth
//             style={{ marginTop: 10, display: 'flex', flexDirection: 'row' }}
//         >
//             <Box flex={1}>
//                 <InputLabel>
//                     <Typography
//                         color="text.light"
//                         backgroundColor="white"
//                         paddingLeft={1}
//                         paddingRight={2}
//                     >
//                         {label}
//                     </Typography>
//                 </InputLabel>
//                 <Select
//                     value={filter[id]}
//                     label={label}
//                     onChange={(e) => setFilter(e.target.value)}
//                     sx={{
//                         borderRadius: 0,
//                         width: '100%',
//                         height: '100%',
//                     }}
//                     {...props}
//                 >
//                     {options.map((option) => (
//                         <MenuItem key={option} value={option}>
//                             {option}
//                         </MenuItem>
//                     ))}
//                 </Select>
//             </Box>
//         </FormControl>
//     );
// };

const CustomSlider = styled(Slider)({
    color: '#045be0',
    height: 7,
    '& .MuiSlider-track': {
        border: 'none',
    },
    '& .MuiSlider-thumb': {
        height: 20,
        width: 20,
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
            boxShadow: 'inherit',
        },
        '&:before': {
            display: 'none',
        },
    },
    '& .MuiSlider-valueLabel': {
        backgroundColor: '#045be0',
    },
});

export default AuctionFilter;

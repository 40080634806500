import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import { DataGrid } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';
import CollapseContainer from '../../components/common/CollapseContainer';
import { Link } from 'react-router-dom';

function Projects() {
    const [data, setData] = useState([]);

    useEffect(() => {
        axios
            .get(`/project/getAdmin`)
            .then((res) => {
                setData(res.data);
            })
            .catch(function () {});
    }, []);

    const columns = [
        {
            field: 'id',
            headerName: 'ID',
            width: 50,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'title',
            headerName: 'Title',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'slug',
            headerName: 'Slug',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'website_url',
            headerName: 'Website URL',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                return (
                    <div className="tooltip-admin">
                        <div>{params.value}</div>
                        <span className="tooltiptext-admin">
                            {params.value}
                        </span>
                    </div>
                );
            },
        },
        {
            field: 'addresses',
            headerName: 'Addresses',
            width: 120,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'createdAt',
            headerName: 'Created At',
            width: 120,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'edit',
            headerName: 'Edit',
            width: 90,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                return (
                    <IconButton
                        component={Link}
                        to={`/administration/projects/create/${params.row.slug}`}
                    >
                        <EditIcon style={{ color: '#166cdb' }} />
                    </IconButton>
                );
            },
            sortable: false,
        },
    ];

    return (
        <CollapseContainer title={`Project List (${data?.length || 0})`}>
            <div style={{ width: '100%' }}>
                <DataGrid
                    rows={data.map((item) => {
                        return {
                            id: item.id,
                            title: item.title,
                            slug: item.slug,
                            website_url: item.websiteUrl,
                            addresses: item.addresses.length,
                            createdAt: moment(item.createdAt).format(
                                `MMM D[,] h:mm A`
                            ),
                        };
                    })}
                    columns={columns}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    disableSelectionOnClick
                    autoHeight={true}
                />
            </div>
        </CollapseContainer>
    );
}

export default Projects;

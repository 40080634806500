import React, { useContext, useState } from 'react';
import moment from 'moment';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import PublishIcon from '@mui/icons-material/Publish';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import RelistIcon from '@mui/icons-material/Update';
import MassCreateIcon from '@mui/icons-material/AppRegistration';
import CopyIcon from '@mui/icons-material/ContentCopy';

import { toast } from 'react-toastify';
import axios from 'axios';
import { AccountContext } from '../supertokens/Account';
import DynamicIcon from '../common/DynamicIcon';
import PopupForm from '../common/PopupForm';
import { IconButton } from '@mui/material';
import { Link } from 'react-router-dom';

function AuctionAdminControls({ auction, permanent, vertical }) {
    const account = useContext(AccountContext);

    const [showDialog, setShowDialog] = useState(false);
    const [allowDialogClick, setAllowDialogClick] = useState(false);

    const [dialogTitle, setDialogTitle] = useState();
    const [dialogBody, setDialogBody] = useState();
    const [dialogButton, setDialogButton] = useState();
    const [dialogAction, setDialogAction] = useState();

    const [showMassCreate, setShowMassCreate] = useState(false);

    let reason = 'This listing has been disabled by an administrator.';

    const dialogPublish = {
        title: 'Publish listing?',
        body: (
            <div>
                <div>
                    Are you sure you want to publish this listing? Once this
                    listing starts, you will not be able to alter the following
                    fields:
                </div>
                <br />
                <ul>
                    <li>Listing start time</li>
                    <li>Listing end time</li>
                    <li>Listing URL</li>
                    <li>Listing starting bid amount</li>
                    <li>Listing bid increment</li>
                </ul>
            </div>
        ),
        button: 'Publish',
        action: () => handlePublish,
    };

    const dialogDisable = {
        title: 'Disable listing?',
        body: (
            <div>
                <div>
                    Are you sure you want to disable this listing?{' '}
                    <b>Once disabled, it can not be enabled again.</b> The
                    listing will not process any new or existing bids.
                </div>
                {/*<div className="input-label">Reason (public)</div>
                <textarea
                    rows="3"
                    className={`form-control`}
                    defaultValue={reason}
                    onChange={(e) => {
                        reason = e.target.value;
                    }}
                    style={{ color: '#555' }}
                />*/}
            </div>
        ),
        button: 'Disable',
        action: () => handleDisable,
    };

    const dialogEnd = {
        title: 'End listing?',
        body: (
            <div>
                It looks like this listing missed the scheduled end-date. Do you
                want to manually conclude this listing?
            </div>
        ),
        button: 'End',
        action: () => handleEnd,
    };

    // Display nothing if not admin
    if (account.user.role !== 'Admin') {
        return null;
    }

    const handleClose = () => {
        setShowDialog(false);
    };
    const handleShow = (options) => {
        // Set dialog content
        setDialogTitle(options.title);
        setDialogBody(options.body);
        setDialogButton(options.button);
        setDialogAction(options.action);

        // Show the dialog, enable the confirm button after 1000ms
        setShowDialog(true);
        setAllowDialogClick(false);
        setTimeout(() => setAllowDialogClick(true), 1000);
    };

    const handlePublish = () => {
        setShowDialog(false);

        axios
            .post(`/auction/publish`, { id: auction.id })
            .then((res) => {
                window.location.reload();
            })
            .catch(function (err) {
                let message = err.message;
                if (err.response && err.response.data) {
                    message = err.response.data;
                }
                toast.error(message);
            });
    };

    const handleDisable = () => {
        setShowDialog(false);

        axios
            .post(`/auction/disable`, { id: auction.id, reason: reason })
            .then((res) => {
                window.location.reload();
            })
            .catch(function (err) {
                let message = err.message;
                if (err.response && err.response.data) {
                    message = err.response.data;
                }
                toast.error(message);
            });
    };

    const handleEnd = () => {
        setShowDialog(false);

        axios
            .post(`/auction/conclude`, { id: auction.id })
            .then((res) => {
                window.location.reload();
            })
            .catch(function (err) {
                let message = err.message;
                if (err.response && err.response.data) {
                    message = err.response.data;
                }
                toast.error(message);
            });
    };

    function handleMassCreate(form) {
        setShowDialog(false);

        axios
            .post(`/auction/massCreate`, form)
            .then((res) => {
                window.location.href = `/administration/listings`;
            })
            .catch(function (err) {
                let message = err.message;
                if (err.response && err.response.data) {
                    message = err.response.data;
                }
                toast.error(message);
            });
    }

    const btnStyle = {
        borderRadius: '50%',
        width: '50px',
        height: '50px',
        marginLeft: '5px',
        marginBottom: '5px',
    };

    const ButtonPublish = () => {
        if (!auction.visible) {
            return (
                <button
                    style={btnStyle}
                    className="btn btn-success"
                    onClick={() => handleShow(dialogPublish)}
                >
                    <PublishIcon />
                </button>
            );
        } else {
            return null;
        }
    };

    const ButtonEdit = () => {
        return !auction.disabled ? (
            <IconButton
                style={btnStyle}
                variant="blue"
                component={Link}
                to={`/administration/listings/create/${auction.slug}`}
            >
                <EditIcon />
            </IconButton>
        ) : null;
    };

    const ButtonCopy = () => {
        return (
            <IconButton
                style={btnStyle}
                variant="blue"
                component={Link}
                to={`/administration/listings/create/?slug=${auction.slug}`}
            >
                <CopyIcon />
            </IconButton>
        );
    };

    const ButtonRelist = () => {
        return auction.finished && !auction.sold ? (
            <IconButton
                style={btnStyle}
                variant="warning"
                component={Link}
                to={`/administration/listings/create/?slug=${auction.slug}&relist=true`}
            >
                <RelistIcon />
            </IconButton>
        ) : null;
    };

    const ButtonMassCreate = () => {
        return (
            <IconButton
                style={btnStyle}
                variant="warning"
                onClick={() => setShowMassCreate(true)}
            >
                <MassCreateIcon />
            </IconButton>
        );
    };

    const ButtonDisable = () => {
        return auction.visible && !auction.disabled && !auction.finished ? (
            <IconButton
                style={btnStyle}
                variant="danger"
                onClick={() => handleShow(dialogDisable)}
            >
                <CancelIcon />
            </IconButton>
        ) : null;
    };

    const ButtonEnd = () => {
        return !auction.finished &&
            auction.visible &&
            moment().isAfter(auction.endDate) ? (
            <IconButton
                style={btnStyle}
                className="btn btn-danger"
                onClick={() => handleShow(dialogEnd)}
            >
                <DynamicIcon icon="AutoFixHigh" />
            </IconButton>
        ) : null;
    };

    return (
        <div
            className={permanent ? '' : 'property-card-admin-controls'}
            style={{
                margin: '10px',
                display: vertical ? 'flex' : 'block',
                flexDirection: 'column',
            }}
        >
            <ButtonPublish />
            <ButtonEdit />
            <ButtonCopy />
            <ButtonRelist />
            <ButtonMassCreate />
            <ButtonEnd />
            <ButtonDisable />
            <Dialog
                open={showDialog}
                onClose={handleClose}
                style={{ width: '500px', margin: 'auto' }}
            >
                <DialogTitle>{dialogTitle}</DialogTitle>
                <DialogContent style={{ paddingBottom: '5px' }}>
                    <DialogContentText component={'span'}>
                        {dialogBody}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button
                        disabled={!allowDialogClick}
                        onClick={dialogAction}
                        autoFocus
                    >
                        {dialogButton}
                    </Button>
                </DialogActions>
            </Dialog>
            <PopupForm
                title="Mass Create"
                body={
                    <div>
                        <p>
                            This process will duplicate the current listing with
                            the following increments / adjustments. Specify
                            parameters below.
                        </p>
                        <p>
                            Use {`{{fieldName}}`} to place the variable within
                            the title/slug/unit fields.
                        </p>
                        <ul>
                            <li>{`{{unitNumber}}`}</li>
                            <li>{`{{floorNumber}}`}</li>
                        </ul>
                    </div>
                }
                action={handleMassCreate}
                show={showMassCreate}
                setShow={setShowMassCreate}
                yes="Submit"
                width={800}
                inputs={[
                    [
                        { id: 'startFloor', number: true },
                        { id: 'endFloor', number: true },
                        { id: 'floorIncrement', number: true, min: 1 },
                    ],
                    [
                        {
                            id: 'startUnitNumber',
                            number: true,
                            description: `This unit number shoult not exist yet.`,
                            min: 0,
                        },
                        { id: 'unitNumberIncrement', number: true, min: 1 },
                    ],
                    [
                        {
                            id: 'startingBid',
                            number: true,
                            label: 'Start Price',
                            min: 1,
                        },
                        { id: 'priceIncrement', number: true, min: 1 },
                    ],
                    [
                        {
                            id: 'titleFormat',
                            placeholder: 'Something {{unitNumber}} something',
                        },
                        {
                            id: 'slugFormat',
                            placeholder: 'something-{{unitNumber}}-something',
                        },
                        {
                            id: 'unitFormat',
                            placeholder: 'Unit {{unitNumber}}',
                        },
                    ],
                ]}
                defaults={{
                    auctionId: auction.id,
                    startFloor: auction.property.floorNumber + 1,
                    floorIncrement: 1,
                    unitFormat: '{{unitNumber}}',
                    slugFormat: 'unit-{{unitNumber}}',
                    titleFormat: 'Unit {{unitNumber}}',
                }}
            />
        </div>
    );
}

export default AuctionAdminControls;

import {
    Close,
    Replay,
    LockClock,
    CopyAll,
    AddBoxRounded,
} from '@mui/icons-material';
import {
    Box,
    Button,
    Grid,
    IconButton,
    Tooltip,
    Typography,
} from '@mui/material';
import InputForm from '../common/InputForm';
import { extractInputs } from '../../scripts/cms';
import DynamicIcon from '../common/DynamicIcon';

function ArrayInputField({ input, yup }) {
    const { id, inputs, array, title, restrictAddRemove, slim } = input;

    const data = yup.watch(input.id) || [];

    // Add - Main Array
    function handleAddList() {
        const parentSize = data.length || 0;

        // Add empty list with an initial item if sub-array is needed
        const value = {};
        if (array) {
            value[array?.id] = [{}];
        }

        // Update list for this item
        yup.setValue(`${id}.${parentSize}`, value);
    }

    // Remove - Main Array
    function handleRemoveList(i, forceDelete) {
        // Get current value (array)
        let items = yup.getValues()[id];

        // Remove the requested item
        if (items[i].id && !forceDelete) {
            items[i].delete = true;
        } else {
            items.splice(i, 1);
        }

        // Update the entire object with the new list
        yup.setValue(id, items);
    }

    // Restore - Main Array
    function handleRestoreList(i) {
        // Get current value (array)
        let items = yup.getValues()[id];

        // Restore the selected item
        items[i].delete = false;

        // Update the entire object with the new list
        yup.setValue(id, items);
    }

    // Move - Main Array
    function handleMoveList(i, direction) {
        // Get current value (array)
        let items = yup.getValues()[id];

        const newIndex = i + direction;

        // Exit if move is unsafe
        if (newIndex < 0 || newIndex >= items.length) {
            return;
        }

        // Moving Up (-)
        if (newIndex < i) {
            items = [
                ...items.slice(0, newIndex),
                items[i],
                items[newIndex],
                ...items.slice(i + 1),
            ];
        }

        // Moving Down (+)
        else {
            items = [
                ...items.slice(0, i),
                items[newIndex],
                items[i],
                ...items.slice(newIndex + 1),
            ];
        }

        // Update the entire object with the new list
        yup.setValue(id, items);
    }

    // Add - Child Array
    function handleAddItem(i) {
        const childSize = data[i][array?.id]?.length || 0;
        yup.setValue(`${id}.${i}.${array?.id}.${childSize}`, {});
    }

    // Remove - Child Array
    function handleRemoveItem(i, j) {
        // Get current value (array)
        let items = yup.getValues()[id][i][array?.id];

        // Remove the requested item
        if (items[j].id) {
            items[j].delete = true;
        } else {
            items.splice(j, 1);
        }

        // Update the entire object with the new list
        yup.setValue(`${id}.${i}.${array?.id}`, items);
    }

    // Restore - Child Array
    function handleRestoreItem(i, j) {
        // Get current value (array)
        let items = yup.getValues()[id][i][array?.id];

        // Restore the selected item
        items[j].delete = false;

        // Update the entire object with the new list
        yup.setValue(`${id}.${i}.${array?.id}`, items);
    }

    function handleDuplicate(i, expire) {
        // Get current value (array)
        let items = yup.getValues()[id];

        // Duplicate item if it's being expired for the first time
        if (items[i].expire === undefined || !expire) {
            // Copy the item (but only the columns that are part of the form)
            let duplicate = extractInputs(`${id}.${i}`, inputs, yup);

            // Copy children
            if (array) {
                // Setup object
                duplicate[array.id] = [];

                const arrayItems = items[i][array.id];
                for (let j = 0; j < arrayItems.length; j++) {
                    duplicate[array.id].push(
                        extractInputs(
                            `${id}.${i}.${array.id}.${j}`,
                            array.inputs,
                            yup
                        )
                    );
                }
            }

            // Expire the requested item
            if (expire) {
                items[i].expire = !items[i].expire;
            } else {
                items[i].expire = undefined;
            }

            // Insert a new item after the one you're expiring
            items = [...items.slice(0, i), duplicate, ...items.slice(i)];
        }
        // Undo/redo expiration without duplicating
        else if (expire) {
            items[i].expire = !items[i].expire;
        }

        // Update the entire object with the new list
        yup.setValue(id, items);
    }

    return (
        <Box className="input-panel-item">
            <Typography fontWeight="bold" gutterBottom>
                {title}(s)
            </Typography>
            {data.map((item, i) => {
                const locked = item.locked && !input.disableExpiration;

                return (
                    <Box
                        key={`${item.id}.${i}`}
                        padding={slim ? 0 : 2}
                        marginBottom={slim ? 0 : 2}
                        marginTop={slim ? 0 : 2}
                        border={slim ? undefined : '1px solid #ccc'}
                    >
                        {!slim && (
                            <Typography
                                fontWeight="bold"
                                flex={1}
                                margin="auto"
                            >
                                {title} #{i + 1}{' '}
                                {item.delete ? '(Deleted)' : ''}
                            </Typography>
                        )}
                        <Box display="flex">
                            <Box flex={1}>
                                {!item.delete && (
                                    <InputForm
                                        yup={yup}
                                        inputs={inputs}
                                        parentAttribute={`${id}.${i}`}
                                        locked={locked}
                                        slim={slim}
                                    />
                                )}
                            </Box>
                            <Box
                                display="flex"
                                flexDirection={slim ? 'row' : 'column'}
                                marginTop="auto"
                                marginBottom="auto"
                                marginLeft={1}
                            >
                                {!restrictAddRemove && (
                                    <Box>
                                        {!item.delete ? (
                                            <IconButton
                                                size="tiny"
                                                onClick={() =>
                                                    handleRemoveList(i, slim)
                                                }
                                                disabled={locked}
                                            >
                                                <Close />
                                            </IconButton>
                                        ) : (
                                            <IconButton
                                                size="tiny"
                                                onClick={() =>
                                                    handleRestoreList(i)
                                                }
                                                disabled={locked}
                                            >
                                                <Replay />
                                            </IconButton>
                                        )}
                                    </Box>
                                )}

                                {locked && (
                                    <Tooltip
                                        title={
                                            item.expire
                                                ? 'Cancel Expiration'
                                                : 'Expire'
                                        }
                                    >
                                        <IconButton
                                            size="tiny"
                                            onClick={() =>
                                                handleDuplicate(i, true)
                                            }
                                            disabled={item.expired}
                                        >
                                            <LockClock
                                                style={{
                                                    color: item.expire
                                                        ? '#FF1818'
                                                        : undefined,
                                                }}
                                            />
                                        </IconButton>
                                    </Tooltip>
                                )}

                                <Tooltip title="Copy">
                                    <IconButton
                                        onClick={() =>
                                            handleDuplicate(i, false)
                                        }
                                    >
                                        <CopyAll />
                                    </IconButton>
                                </Tooltip>

                                {!item.delete && (
                                    <>
                                        <IconButton
                                            onClick={() =>
                                                handleMoveList(i, -1)
                                            }
                                            disabled={i === 0}
                                        >
                                            <DynamicIcon icon="KeyboardArrowUp" />
                                        </IconButton>
                                        <IconButton
                                            onClick={() => handleMoveList(i, 1)}
                                            disabled={i + 1 >= data.length}
                                        >
                                            <DynamicIcon icon="KeyboardArrowDown" />
                                        </IconButton>
                                    </>
                                )}
                            </Box>
                        </Box>
                        {array && !item.delete && (
                            <>
                                <hr />
                                <Typography fontWeight="bold" gutterBottom>
                                    {array?.title || 'List Items'}
                                </Typography>
                                {item[array?.id]?.map((child, j) => (
                                    <Box key={`${child.id}.${j}`}>
                                        {j > 0 && <hr />}
                                        <Box display="flex">
                                            <Box flex={1}>
                                                {!child.delete && (
                                                    <InputForm
                                                        yup={yup}
                                                        inputs={array?.inputs}
                                                        parentAttribute={`${id}.${i}.${array?.id}.${j}`}
                                                        locked={locked}
                                                    />
                                                )}
                                            </Box>
                                            {!array.restrictAddRemove && (
                                                <Box
                                                    margin="auto"
                                                    paddingLeft={1}
                                                >
                                                    {!child.delete ? (
                                                        <IconButton
                                                            onClick={() =>
                                                                handleRemoveItem(
                                                                    i,
                                                                    j
                                                                )
                                                            }
                                                            disabled={locked}
                                                        >
                                                            <Close />
                                                        </IconButton>
                                                    ) : (
                                                        <IconButton
                                                            onClick={() =>
                                                                handleRestoreItem(
                                                                    i,
                                                                    j
                                                                )
                                                            }
                                                            disabled={locked}
                                                        >
                                                            <Replay />
                                                        </IconButton>
                                                    )}
                                                </Box>
                                            )}
                                        </Box>
                                    </Box>
                                ))}
                                {!array.restrictAddRemove && (
                                    <Button
                                        fullWidth
                                        variant="blue"
                                        size="tiny"
                                        onClick={() => handleAddItem(i)}
                                        disabled={locked}
                                    >
                                        Add Item
                                    </Button>
                                )}
                            </>
                        )}
                    </Box>
                );
            })}
            {!restrictAddRemove && (
                <Button
                    fullWidth
                    variant="dark"
                    onClick={handleAddList}
                    size="tiny"
                >
                    Add Item
                </Button>
            )}
            <hr />
        </Box>
    );
}

export default ArrayInputField;

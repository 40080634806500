import { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AccountContext } from '../supertokens/Account';
import { provinces } from '../../scripts/validation';
import PopupForm from '../common/PopupForm';
import mediaTypes from '../../constants/mediaTypes';

function BusinessModal({
    show,
    onClose,
    onSuccess = () => {
        window.location.reload();
    },
    _defaults,
}) {
    let defaults = _defaults;
    const account = useContext(AccountContext);

    const DEFAULT_TYPE_ID = '8';
    const DEFAULT_CATEGORY_ID = '71';

    if (!defaults) {
        defaults = {
            typeId: DEFAULT_TYPE_ID,
            typeCategoryId: DEFAULT_CATEGORY_ID,
            country: 'Canada',
            province: 'ON',
        };
    }

    const [processing, setProcessing] = useState(false);
    const [errors, setErrors] = useState();

    const inputs = [
        [{ id: 'nature', label: 'Nature of Business' }],
        [{ id: 'firstName' }, { id: 'lastName' }],
        [
            {
                id: 'title',
                label: 'Business Name',
                disabled: _defaults !== undefined,
            },
            { id: 'businessNumber' },
        ],
        [
            { id: 'email', label: 'Business Email' },
            { id: 'phoneNumber', mask: '99999999999' },
        ],
        [
            { id: 'country', disabled: true },
            {
                id: 'province',
                children: provinces,
            },
        ],
        [{ id: 'city' }, { id: 'postalCode', mask: 'a9a 9a9' }],
        [
            { id: 'streetAddress', label: 'Address' },
            { id: 'unit', label: 'Unit', optional: true },
        ],
        [
            {
                id: 'proof',
                label: 'Articles of Incorporation',
                media: { limit: 1, mediaTypeId: mediaTypes.DOCUMENT },
                optional: true,
            },
        ],
    ];

    function handleSubmit(form) {
        setProcessing(true);

        axios
            .post(`/business/${_defaults ? 'update' : 'create'}`, form)
            .then((res) => {
                onClose();
                onSuccess();
            })
            .catch(function (err) {
                setErrors(err?.response?.data?.errors);
            })
            .finally(function () {
                setProcessing(false);
            });
    }

    return (
        <PopupForm
            show={show}
            setShow={onClose}
            title="Create Business"
            inputs={inputs}
            action={handleSubmit}
            processing={processing}
            width={500}
            errors={errors}
            setErrors={setErrors}
            yes="Submit"
            defaults={defaults}
        />
    );
}

export default BusinessModal;

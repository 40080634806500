import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import NotFound from '../../pages/NotFound.js';

import {
    getOwnershipTypes,
    getProjects,
    getPropertyTypes,
    useYup,
} from '../../scripts/cms.js';
import CMSForm from './CMSForm.js';
import mediaTypes from '../../constants/mediaTypes.js';

function ModelForm() {
    const { id } = useParams();
    const [valid, setValid] = useState(true);
    const [data, setData] = useState();

    useEffect(() => {
        // Get existing model data (if you are modifying one)
        if (id) {
            axios
                .get(`/model/get/${id}`)
                .then((res) => {
                    const model = res.data;
                    setData({
                        ...res.data,
                        projectMedia: model.project.projectMedia,
                        floorplan: { media: model.floorplan },
                        floorplanImage: { media: model.floorplanImage },
                    });
                })
                .catch(function () {
                    setValid(false);
                });
        }
    }, [id]);

    const inputs = [
        [
            {
                id: 'projectId',
                source: getProjects,
                label: 'Select Project',
                table: true,
                restricted: true,
            },
        ],
        [
            {
                id: 'propertyTypeId',
                source: getPropertyTypes,
                restricted: true,
            },
            {
                id: 'ownershipTypeId',
                source: getOwnershipTypes,
            },
            { id: 'layout', label: 'Layout Name' },
        ],
        [
            {
                id: 'floorplan',
                media: {
                    limit: 1,
                    mediaTypeId: mediaTypes.FLOOR_PLAN,
                },
                label: 'Floorplan (Listing Page)',
            },
            {
                id: 'floorplanImage',
                media: {
                    limit: 1,
                    mediaTypeId: mediaTypes.IMAGE,
                },
                label: 'Floorplan Image (Contract)',
            },
        ],
        [
            {
                id: 'modelMedia',
                optional: true,
                media: {},
            },
        ],
        [
            {
                id: 'projectMedia',
                optional: true,
                media: {
                    reference: true,
                },
            },
        ],
        [
            { id: 'identifier', label: 'Model Identifier (Internal)' },
            { id: 'title', label: 'Model Title' },
        ],
        [
            { id: 'bedroomCount', number: true },
            { id: 'bathroomCount', number: true },
            { id: 'denCount', number: true },
        ],
        [
            { id: 'interiorSquareFeet', number: true },
            { id: 'exteriorSquareFeet', number: true },
        ],
        [
            {
                id: 'maintenance',
                label: 'Maintenance Fees',
                paragraph: true,
                optional: true,
            },
        ],
        [
            {
                id: 'propertyTax',
                paragraph: true,
                optional: true,
            },
        ],
        [
            {
                id: 'purchaseProcedure',
                paragraph: true,
                optional: true,
            },
        ],
        [
            {
                id: 'mortgageRequirements',
                paragraph: true,
                optional: true,
            },
        ],
    ];

    const yup = useYup(inputs, {});
    const projectId = yup.watch('projectId');

    // Set default fields from project config
    useEffect(() => {
        if (projectId && !id) {
            axios
                .get(`/project/getConfig/${projectId}`)
                .then((res) => {
                    const model = res.data?.model;
                    if (model) {
                        for (let key in model) {
                            yup.setValue(key, model[key]);
                        }
                    }
                })
                .catch(function () {});
        }
    }, [projectId]);

    // Exit if bad data
    if (!valid) {
        return <NotFound />;
    }

    // Return the CMS form with the necessary inputs
    return (
        <CMSForm
            api="model"
            adminSlug="models"
            data={data}
            inputs={inputs}
            _yup={yup}
        />
    );
}

export default ModelForm;

import {
    Box,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
    useMediaQuery,
} from '@mui/material';
import DataScroller from '../common/DataScroller';
import PropertyCard from '../cards/PropertyCard';
import CardList from '../common/CardList';
import Panels from '../common/Panels';
import NoData from '../common/NoData';
import { useRef } from 'react';
import DynamicIcon from '../common/DynamicIcon';
import { DataGrid } from '@mui/x-data-grid';
import { getPriceString } from '../../scripts/language';
import Client from '../common/Client';

function ExclusiveUnits() {
    return (
        <DataScroller
            api={'/broker/getExclusive'}
            // body={{ [config.paramId]: param }}
            placeholder="Unit Number"
            Child={BrokerCard}
            // childParams={{ config }}
            Parent={CardList}
            parentParams={{
                xs: 12,
                md: 12,
                lg: 12,
                noCenter: true,
                padding: 0,
                vertical: 2,
                spacing: 2,
                noData: false,
            }}
        />
    );
}

function BrokerCard({ item: auction }) {
    const PADDING = 2;
    const HEIGHT = 550;

    const heightRef = useRef(null);
    const stacked = useMediaQuery((theme) => theme.breakpoints.down('lg'));

    return (
        <Grid
            container
            display="flex"
            backgroundColor="tintDark"
            padding={PADDING}
            borderRadius={7}
        >
            <Grid
                item
                xs={12}
                md={12}
                lg={4}
                paddingRight={{ xs: 0, lg: 2 }}
                paddingBottom={{ xs: 2, lg: 0 }}
            >
                <PropertyCard item={auction} height={HEIGHT} _ref={heightRef} />
            </Grid>
            <Grid
                item
                xs={12}
                md={12}
                lg={8}
                backgroundColor="tintLight"
                borderRadius={6}
            >
                <Panels
                    panels={[
                        {
                            label: 'Client Access',
                            index: 0,
                            children: (
                                <ClientAccess
                                    auction={auction}
                                    auctionInvites={auction.auctionInvites}
                                />
                            ),
                        },
                        {
                            label: 'Incentives',
                            index: 1,
                            children: (
                                <CardList
                                    items={auction.auctionIncentives}
                                    Component={Incentive}
                                    xs={12}
                                    md={12}
                                    lg={12}
                                    spacing={1}
                                />
                            ),
                        },
                        {
                            label: 'Commission Structure',
                            index: 2,
                            children: (
                                <Commission commission={auction.commission} />
                            ),
                        },
                    ]}
                    maxHeight={
                        stacked
                            ? 500
                            : heightRef?.current?.clientHeight || HEIGHT
                    }
                    discrete={false}
                />
            </Grid>
        </Grid>
    );
}

function ClientAccess({ auction, auctionInvites = [] }) {
    return (
        <Box padding={{ xs: 1, md: 2, lg: 3 }}>
            {auction.brokerId && auctionInvites.length === 0 && (
                <Box marginBottom={1}>
                    <Typography fontWeight="bold" fontSize={20} gutterBottom>
                        Exclusive
                    </Typography>
                    <Typography fontSize={18}>
                        This listing is exclusive to all of your clients.
                    </Typography>
                </Box>
            )}

            {auctionInvites.map((auctionInvite) => (
                <Box key={auctionInvite.id} marginTop={1}>
                    <Client
                        invite={auctionInvite.invite}
                        user={auctionInvite.invite.user}
                        pairing={auctionInvite.invite.user?.brokerPairings?.[0]}
                    />
                </Box>
            ))}

            {auctionInvites.length < auction.totalAuctionInvites && (
                <Typography
                    width="100%"
                    textAlign="center"
                    padding={1}
                    color="text.secondary"
                >
                    {`There are ${
                        auction.totalAuctionInvites - auctionInvites.length
                    } additional users with access to this listing.`}
                </Typography>
            )}
        </Box>
    );
}

function Incentive({ item: auctionIncentive }) {
    const { incentive } = auctionIncentive;

    return (
        <Box marginBottom={2}>
            <Box display="flex">
                {incentive.disableListing && (
                    <Tooltip title="Private" placement="top">
                        <Box sx={{ marginRight: 1, marginBottom: 0.2 }}>
                            <DynamicIcon
                                icon="Star"
                                sx={{ marginBottom: 'auto' }}
                            />
                        </Box>
                    </Tooltip>
                )}
                <Typography fontWeight="bold" marginTop="auto">
                    {incentive.title}
                </Typography>
            </Box>
            <Typography gutterBottom>{incentive.description}</Typography>
        </Box>
    );
}

function Commission({ commission }) {
    if (!commission) {
        return <NoData />;
    }

    const columnWidths = {
        num: '10%',
        amount: '20%',
        date: '70%',
    };

    return (
        <TableContainer sx={{ padding: { xs: 1, md: 2 } }}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell
                            width={columnWidths.num}
                            sx={{ fontWeight: 'bold' }}
                        >
                            #
                        </TableCell>
                        <TableCell
                            width={columnWidths.amount}
                            sx={{ fontWeight: 'bold' }}
                        >
                            Amount
                        </TableCell>
                        <TableCell
                            width={columnWidths.date}
                            sx={{ fontWeight: 'bold' }}
                        >
                            Date
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {commission.commissionPayments.map((cp, i) => (
                        <TableRow key={cp.id}>
                            <TableCell width={columnWidths.num}>
                                {cp.number}
                            </TableCell>
                            <TableCell width={columnWidths.amount}>
                                {cp.fixedAmount
                                    ? getPriceString(cp.fixedAmount)
                                    : `${cp.percent}%`}
                            </TableCell>
                            <TableCell width={columnWidths.date}>
                                {cp.eventType.title}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default ExclusiveUnits;

import React, { useState, useEffect } from 'react';
import { Box, Button, Grid, Tooltip } from '@mui/material';
import Input from './Input';
import axios from 'axios';
import moment from 'moment';
import IDVModal from '../../modal/IDVModal';

function IdentityInfo({ user, setUser }) {
    const [showIDVModal, setShowIDVModal] = useState(false);
    const [verificationStatus, setVerificationStatus] = useState({});

    useEffect(() => {
        refreshVerificationStatus();
    }, []);

    function handleVerification(data) {
        setUser({ ...user, ...data });
        refreshVerificationStatus();
    }

    function refreshVerificationStatus() {
        axios
            .get(`/user/idv/status`)
            .then((res) => {
                setVerificationStatus(res.data);
            })
            .catch(function () {});
    }

    return (
        <Box>
            <Grid container spacing={1}>
                <Input label="First Name" value={user?.firstName} disabled />
                <Input label="Middle Name" value={user?.middleName} disabled />
            </Grid>
            <Grid container spacing={1}>
                <Input label="Last Name" value={user?.lastName} disabled />
                <Input label="Date of Birth" value={user?.birthdate} disabled />
            </Grid>
            <Grid container spacing={1}>
                <Input label="Country" value={user?.country} disabled />
                <Input label="Province" value={user?.province} disabled />
            </Grid>
            <Grid container spacing={1}>
                <Input label="City" value={user?.city} disabled />
                <Input label="Postal Code" value={user?.postalCode} disabled />
            </Grid>
            <Grid container spacing={1}>
                <Input
                    label="Street Address"
                    value={user?.streetAddress}
                    disabled
                />
                <Input label="Unit" value={user?.unit} disabled />
            </Grid>
            <Tooltip
                title={
                    verificationStatus.allowVerification
                        ? ''
                        : `Re-verification allowed ${moment(
                              verificationStatus.reverificationDate
                          ).format('MMMM D, YYYY')}.`
                }
            >
                <span>
                    <Button
                        variant="dark"
                        onClick={() => setShowIDVModal(true)}
                        disabled={!verificationStatus.allowVerification}
                    >
                        Update
                    </Button>
                </span>
            </Tooltip>
            <IDVModal
                show={showIDVModal}
                onClose={() => setShowIDVModal(false)}
                callback={() => window.location.reload()}
            />
        </Box>
    );
}

export default IdentityInfo;

import React, { useState, useEffect } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import axios from 'axios';
import { toast } from 'react-toastify';
import PopupForm from '../common/PopupForm';
import Client from '../common/Client';

function ClientManagement() {
    const [requests, setRequests] = useState([]);
    const [clients, setClients] = useState([]);
    const [invites, setInvites] = useState([]);

    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
    const [dialogTitle, setDialogTitle] = useState();
    const [dialogBody, setDialogBody] = useState();
    const [dialogAction, setDialogAction] = useState();

    useEffect(() => {
        axios
            .get(`/broker/getPairingRequests`)
            .then((res) => {
                setRequests(res.data);
            })
            .catch(function () {});

        axios
            .get(`/broker/getClients`)
            .then((res) => {
                setClients(res.data);
            })
            .catch(function () {});

        axios
            .get(`/invite/getClientInvites`)
            .then((res) => {
                setInvites(res.data);
            })
            .catch(function () {});
    }, []);

    const handleApproveClient = (pairing) => {
        showDialog(
            'Approve Client Request?',
            <div>
                Are you sure you want to register{' '}
                <strong>{`${pairing.user.username}`}</strong> as your client?
            </div>,
            () => {
                axios
                    .post(`/broker/approvePairing/${pairing.userId}`)
                    .then(() => {
                        addClient(pairing);
                        removeRequest(pairing);

                        setShowConfirmationDialog(false);
                    })
                    .catch(function () {});
            }
        );
    };

    const handleRejectClient = (pairing) => {
        const body = pairing.approved ? (
            <div>
                Are you sure you want to remove{' '}
                <strong>{`${pairing.user.username}`}</strong> from your list of
                clients?
            </div>
        ) : (
            <div>
                Are you sure you want to reject the pairing request from{' '}
                <strong>{`${pairing.user.username}`}</strong>?
            </div>
        );
        showDialog('Remove Client?', body, () => {
            axios
                .post(`/broker/rejectPairing/${pairing.userId}`)
                .then(() => {
                    pairing.approved
                        ? removeClient(pairing)
                        : removeRequest(pairing);

                    setShowConfirmationDialog(false);
                })
                .catch(function (err) {
                    toast.error(err?.response?.data || err?.message);
                    setShowConfirmationDialog(false);
                });
        });
    };

    const handleDeleteInvite = (invite) => {
        const body = (
            <div>
                Are you sure you want to uninvite{' '}
                <strong>{`${invite.firstName} ${invite.lastName}`}</strong> from
                joining Upside?
            </div>
        );

        showDialog('Uninvite Client?', body, () => {
            axios
                .post(`/broker/deleteInvite/${invite.id}`)
                .then(() => {
                    removeInvite(invite);
                    setShowConfirmationDialog(false);
                })
                .catch(function (err) {
                    toast.error(err?.response?.data || err?.message);
                    setShowConfirmationDialog(false);
                });
        });
    };

    const addClient = (client) => {
        const _clients = [...clients];
        const _client = { ...client };
        _client.approved = true;

        _clients.unshift(_client);
        setClients(_clients);
    };

    const removeRequest = (item) => {
        setRequests(requests.filter((request) => request.id !== item.id));
    };

    const removeClient = (item) => {
        setClients(clients.filter((client) => client.id !== item.id));
    };

    const removeInvite = (item) => {
        setInvites(invites.filter((invite) => invite.id !== item.id));
    };

    const showDialog = (title, body, action) => {
        setShowConfirmationDialog(true);
        setDialogTitle(title);
        setDialogBody(body);
        setDialogAction(() => action);
    };

    return (
        <Grid container padding={2} spacing={{ xs: 2, lg: 8 }}>
            <Grid item xs={12} lg={6}>
                <ClientRequests
                    requests={requests}
                    invites={invites}
                    handleApproveClient={handleApproveClient}
                    handleRejectClient={handleRejectClient}
                    handleDeleteInvite={handleDeleteInvite}
                />
            </Grid>
            <Grid item xs={12} lg={6}>
                <YourClients
                    clients={clients}
                    handleApproveClient={handleApproveClient}
                    handleRejectClient={handleRejectClient}
                />
            </Grid>
            <PopupForm
                title={dialogTitle}
                body={dialogBody}
                action={dialogAction}
                show={showConfirmationDialog}
                setShow={setShowConfirmationDialog}
            />
        </Grid>
    );
}

const ClientRequests = ({
    requests,
    invites,
    handleApproveClient,
    handleRejectClient,
    handleDeleteInvite,
}) => {
    return (
        <Box>
            <Typography fontSize={20} fontWeight="medium" marginBottom={1}>
                Client Pairing Requests
            </Typography>
            {requests.map((pairing) => (
                <Client
                    key={`request-${pairing.id}`}
                    pairing={pairing}
                    user={pairing.user}
                    handleApproveClient={handleApproveClient}
                    handleRejectClient={handleRejectClient}
                />
            ))}
            {requests.length === 0 && (
                <Typography color="text.secondary">
                    You have no pending pairing requests.
                </Typography>
            )}

            {invites.length > 0 && (
                <Box marginBottom={2} marginTop={4}>
                    <Typography
                        fontSize={20}
                        fontWeight="medium"
                        marginBottom={1}
                    >
                        Invites
                    </Typography>
                    {invites.map((invite) => (
                        <Client
                            key={`invite-${invite.id}`}
                            invite={invite}
                            handleDeleteInvite={handleDeleteInvite}
                        />
                    ))}
                </Box>
            )}
        </Box>
    );
};

const YourClients = ({ clients, handleApproveClient, handleRejectClient }) => {
    return (
        <Box>
            <Typography fontSize={20} fontWeight="medium" marginBottom={1}>
                Clients
            </Typography>
            {clients.map((pairing) => (
                <Client
                    key={`pairing-${pairing.id}`}
                    pairing={pairing}
                    user={pairing.user}
                    handleApproveClient={handleApproveClient}
                    handleRejectClient={handleRejectClient}
                />
            ))}
            {clients.length === 0 && (
                <Typography color="text.secondary">
                    You have no clients to display.
                </Typography>
            )}
        </Box>
    );
};

export default ClientManagement;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import { DataGrid } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';
import CollapseContainer from '../../components/common/CollapseContainer';
import DynamicIcon from '../../components/common/DynamicIcon';
import { Link } from 'react-router-dom';

function UpgradeTypes() {
    const [data, setData] = useState([]);

    useEffect(() => {
        axios
            .get(`/upgrade/getTypes`)
            .then((res) => {
                setData(res.data);
            })
            .catch(function () {});
    }, []);

    const columns = [
        {
            field: 'id',
            headerName: 'ID',
            width: 50,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'title',
            headerName: 'Title',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'description',
            headerName: 'Description',
            flex: 2,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'deleted',
            headerName: 'Enabled',
            width: 120,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                return params.row.deleted ? (
                    <DynamicIcon icon="Close" />
                ) : (
                    <DynamicIcon icon="Check" />
                );
            },
        },
        {
            field: 'createdAt',
            headerName: 'Created At',
            width: 120,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'edit',
            headerName: 'Edit',
            width: 90,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                return (
                    <IconButton
                        component={Link}
                        to={`/administration/upgradeTypes/create/${params.row.id}`}
                    >
                        <EditIcon style={{ color: '#166cdb' }} />
                    </IconButton>
                );
            },
            sortable: false,
        },
    ];

    return (
        <CollapseContainer title={`Upgrade Type List (${data?.length || 0})`}>
            <div style={{ height: 410, width: '100%' }}>
                <DataGrid
                    rows={data.map((item) => {
                        return {
                            id: item.id,
                            title: item.title,
                            description: item.description,
                            deleted: item.deleted,
                            createdAt: moment(item.createdAt).format(
                                `MMM D[,] h:mm A`
                            ),
                        };
                    })}
                    columns={columns}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                    disableSelectionOnClick
                />
            </div>
        </CollapseContainer>
    );
}

export default UpgradeTypes;

import { useState, useEffect, useRef } from 'react';
import { Box, Typography } from '@mui/material';
import SearchBar from './SearchBar';

function ArraySearchBar({
    items,
    setItems,
    config = {},
    columns,
    length,
    count,
}) {
    const [input, setInput] = useState('');

    useEffect(() => {
        const query = input.toLowerCase();

        // Filter rows
        if (query.length > 0) {
            setItems(
                items.filter((item) => {
                    for (let i in columns) {
                        const column = columns[i];

                        // Default field value
                        let value = item[column.field];

                        // Get value from the cell renderer function if one is being used
                        if (column.renderCell) {
                            value = column.renderCell({ row: item });
                        }

                        // Check if query found a match
                        if (value?.toString().toLowerCase().includes(query)) {
                            return true;
                        }
                    }

                    return false;
                })
            );
        }

        // Don't filter results if empty
        else {
            setItems(items);
        }
    }, [input]);

    return (
        <Box display="flex" position="relative">
            <SearchBar
                callback={(input) => setInput(input.search)}
                placeholder={config.placeholder}
                sx={{ marginBottom: 1, marginTop: 0.5 }}
                backgroundColor="#eee"
                delay={1}
                clearButton={true}
                length={length}
                count={count}
            />
        </Box>
    );
}

export default ArraySearchBar;

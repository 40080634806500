import { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import DraggableMedia from '../components/cms/DraggableMedia';
import { useForm, useWatch } from 'react-hook-form';
import axios from 'axios';

import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

function readFileAsText(file) {
    return new Promise(function (resolve) {
        var reader = new FileReader();

        reader.onload = function (e) {
            resolve(e.target.result);
        };

        reader.readAsDataURL(file);
    });
}

const uploadFile = (setValue, id, fileInputId = 'file-input', mediaType) => {
    const files = document.getElementById(fileInputId).files;

    let readers = [];

    for (let i = 0; i < files.length; i++) {
        readers.push(readFileAsText(files[i]));
    }

    Promise.all(readers).then((values) => {
        const formattedValues = [];
        for (let i = 0; i < values.length; i++) {
            const url = values[i];

            formattedValues.push({
                media: {
                    url: url,
                    id: null,
                    mediaTypeId: mediaType?.id,
                    title: files[i].name,
                    mediaType: mediaType,
                },
            });
        }

        setValue(id, formattedValues[0]);
    });
};

const uploadFiles = (newMedia, setNewMedia, mediaType) => {
    const files = document.getElementById('file-input').files;
    var list = newMedia;

    let readers = [];

    for (let i = 0; i < files.length; i++) {
        readers.push(readFileAsText(files[i]));
    }

    Promise.all(readers).then((values) => {
        const formattedValues = [];
        for (let i = 0; i < values.length; i++) {
            const url = values[i];

            formattedValues.push({
                media: {
                    url: url,
                    id: null,
                    mediaTypeId: mediaType?.id,
                    title: files[i].name,
                    mediaType: mediaType,
                },
            });
        }
        list = list.concat(formattedValues);
        setNewMedia([...new Set(list)]);
    });
};

const add = (column, list, getValues, setValue) => {
    const item = getValues()[column];
    const items = getValues()[list];
    if (item && item.length > 0) {
        if (!items.includes(item)) {
            items.push(item);
            setValue(list, items);
            setValue(column, '');
        }
    }
};

const remove = (value, list, getValues, setValue) => {
    const items = getValues()[list];
    const index = items.indexOf(value);
    if (index > -1) {
        items.splice(index, 1);
    }
    setValue(list, items);
};

const SingleMediaPicker = ({ yup, item, id, label, mediaTypeId }) => {
    const [mediaType, setMediaType] = useState();

    useEffect(() => {
        // Get the media type data for the required media type
        if (mediaTypeId) {
            axios
                .get(`/media/getMediaType/${mediaTypeId}`)
                .then((res) => {
                    setMediaType(res.data);
                })
                .catch(function () {});
        }
    }, [mediaTypeId]);

    const { setValue, errors } = yup;

    const fileInputId = `file-input-${id}`;

    return (
        <div className="hover-li">
            <DraggableMedia
                item={item}
                handleRemove={() => yup.setValue(id, undefined)}
            />
            <div>
                <label
                    className="btn btn-primary btn-wide"
                    htmlFor={fileInputId}
                    style={{ marginTop: 5 }}
                >
                    {`Upload ${label}`}
                </label>
                <input
                    id={fileInputId}
                    type="file"
                    accept={mediaType?.fileType}
                    onChange={() =>
                        uploadFile(setValue, id, fileInputId, mediaType)
                    }
                />
            </div>
        </div>
    );
};

const getMediaType = (type) => {
    const types = [
        { id: 1, title: 'Image' },
        { id: 2, title: 'Video' },
        { id: 3, title: 'Floor Plan' },
        { id: 3, title: 'Site Plan' },
        { id: 4, title: 'Document' },
        { id: 5, title: 'Logo' },
    ];

    return types.filter((curr) => curr.title === type)[0];
};

const getMediaElements = (elementId) => {
    const el = document
        .getElementById(elementId)
        .getElementsByClassName('media-image');
    const list = [];
    for (let i = 0; i < el.length; i++) {
        const li = el[i];
        const item = JSON.parse(li.getAttribute('item'));
        list.push(item);
    }
    return list;
};

const formatMediaSubmission = (ul) => {
    const list = [];
    for (let i = 0; i < ul.length; i++) {
        const li = ul[i];
        const item = {
            url: li.media.url,
            id: li.media.id,
            mediaTypeId: li.media.mediaTypeId,
            title: li.media.title,
        };
        list.push(item);
    }
    return list;
};

// Converts config string into array
// ex. { { 1: "50", 5: "3" } } --> [{key: 1, value: "50"}]
function mapConfig(object, _key, _value) {
    if (!object) {
        return [];
    }
    return (Object.entries(object) || []).map(([key, value]) => ({
        [_key]: key,
        [_value]: value,
    }));
}

// Get property types
function getPropertyTypes(setState) {
    axios
        .get(`/propertyType/getAll`)
        .then((res) => {
            setState(res.data);
        })
        .catch(function () {
            setState(null);
        });
}

// Get ownership types
function getOwnershipTypes(setState) {
    axios
        .get(`/property/getOwnershipTypes`)
        .then((res) => {
            setState(res.data);
        })
        .catch(function () {
            setState(null);
        });
}

// Get list of projects
function getProjects(setState) {
    axios
        .get(`/project/getAll`)
        .then((res) => {
            setState(res.data);
        })
        .catch(function () {
            setState(null);
        });
}

function getDeveloperProjects(setState, body) {
    axios
        .post(`/project/getDeveloper`, body)
        .then((res) => {
            setState(res.data);
        })
        .catch(function () {
            setState(null);
        });
}

// Get all manual payment options
function getManualPaymentOptions(setState) {
    axios
        .get(`/postPurchase/getAllManualPaymentOptions`)
        .then((res) => {
            setState(res.data);
        })
        .catch(function () {});
}

// Get amendment types
function getAmendmentTypes(setState, body) {
    axios
        .post(`/amendment/getTypes`, body)
        .then((res) => {
            setState(res.data);
        })
        .catch(function () {
            setState([]);
        });
}

// Get docupilot types
function getDocupilotTemplateTypes(setState, body) {
    axios
        .post(`/project/getDocupilotTemplateTypes`)
        .then((res) => {
            setState(res.data);
        })
        .catch(function () {
            setState([]);
        });
}

// Get list of builders
function getBuilders(setState) {
    axios
        .get(`/builder/getAll`)
        .then((res) => {
            setState(res.data);
        })
        .catch(function () {
            setState(null);
        });
}

// Get upgrade types
function getUpgradeTypes(setState) {
    axios
        .get(`/upgrade/getTypes`)
        .then((res) => {
            setState(res.data);
        })
        .catch(function () {
            setState(null);
        });
}

// Get upgrade subtypes
function getUpgradeSubtypes(setState, body) {
    axios
        .post(`/upgrade/getSubtypes`, body)
        .then((res) => {
            setState(res.data);
        })
        .catch(function () {
            setState([]);
        });
}

// Get event types
function getEventTypes(setState, body) {
    axios
        .post(`/event/getTypes`, body)
        .then((res) => {
            setState(res.data);
        })
        .catch(function () {
            setState(null);
        });
}

// Get amenities
function getAmenities(setState) {
    axios
        .get(`/amenity/getAdmin`)
        .then((res) => {
            setState(res.data);
        })
        .catch(function () {});
}

// Get docupilot templates
function getDocupilotTemplates(setState, body) {
    axios
        .post(`/project/getDocupilotTemplates`, body)
        .then((res) => {
            setState(res.data);
        })
        .catch(function () {
            setState([]);
        });
}

// Get additional signers
function getAdditionalSigners(setState, body) {
    axios
        .post(`/amendment/getAdditionalSigners`, body)
        .then((res) => {
            setState(res.data);
        })
        .catch(function () {
            setState([]);
        });
}

// Construct the validation for a single input field
function getValidationItem(input, validation, prefix = '', condition) {
    const id = `${prefix}${input.id}`;

    // Standard text field input
    if (
        !input.disabled &&
        !input.optional &&
        !input.toggle &&
        !input.Component
    ) {
        const message = `${input.label || camelToString(id)} is required`;
        const standard = Yup.string().nullable().required(message);

        // Conditional requirement
        if (condition) {
            validation[id] = Yup.string().nullable().when(condition.key, {
                is: condition.value,
                then: standard,
            });
        }
        // Standard validation
        else {
            validation[id] = standard;
        }
    }
}

function camelToString(variable) {
    const text = variable.replace(/([A-Z])/g, ' $1').trim();
    return text.charAt(0).toUpperCase() + text.slice(1);
}

function buildValidationSchemaWorker(
    inputs,
    _validation,
    prefix = '',
    condition
) {
    let validation = _validation || {};
    for (var i in inputs) {
        const inputGroup = inputs[i];
        for (var j in inputGroup) {
            const input = inputGroup[j];

            // Toggle inputs
            if (input.toggle) {
                for (let item in input.toggle) {
                    buildValidationSchemaWorker(
                        input.toggle[item].inputs,
                        validation,
                        undefined,
                        { key: input.id, value: item }
                    );
                }
            }

            // Array input
            else if (input.inputs) {
                // Multidimensional array support
                const childArray = input.array
                    ? {
                          [input.array?.id]: Yup.array().of(
                              Yup.object().shape({
                                  ...buildValidationSchemaWorker(
                                      input.array.inputs
                                  ),
                              })
                          ),
                      }
                    : undefined;

                validation[input.id] = Yup.array().of(
                    Yup.object().shape({
                        ...buildValidationSchemaWorker(input.inputs),
                        ...childArray,
                    })
                );
            }

            // Media
            else if (input.media && !input.optional) {
                if (input.media.limit === 1) {
                    validation[input.id] = Yup.object()
                        .nullable()
                        .required(
                            `${
                                input.label || camelToString(input.id)
                            } is required`
                        );
                }
            }

            // Table
            else if (input.table && input.table.multi && !input.optional) {
                validation[input.id] = Yup.array()
                    .of(Yup.string())
                    .min(
                        1,
                        `${input.label || camelToString(input.id)} is required`
                    )
                    .required(
                        `${input.label || camelToString(input.id)} is required`
                    );
            }

            // Checkbox
            else if (input.checkbox && !input.optional && !input.disabled) {
                validation[input.id] = Yup.boolean()
                    .nullable()
                    .required(
                        `${input.label || camelToString(input.id)} is required`
                    );
            }

            // Standard input
            else {
                getValidationItem(input, validation, prefix, condition);
            }
        }
    }

    return validation;
}

function buildValidationSchema(inputs) {
    const schema = buildValidationSchemaWorker(inputs);
    return Yup.object().shape(schema);
}

// Build the input validation schema using the provided inputs
function buildFormOptions(inputs, defaultForm) {
    const validationSchema = buildValidationSchema(inputs);
    const formOptions = {
        resolver: yupResolver(validationSchema),
        defaultValues: defaultForm,
    };

    return formOptions;
}

function useYup(inputs, defaultForm = {}, _yup) {
    const formOptions = buildFormOptions(inputs, defaultForm);
    const {
        register,
        handleSubmit,
        formState,
        getValues,
        setValue,
        control,
        watch,
        clearErrors,
        setError,
        reset,
    } = useForm(formOptions);

    // Allow the function to use an existing yup object (required because useYup/useForm can't be called conditionally)
    if (_yup) {
        return _yup;
    }

    const { errors } = formState;
    return {
        register,
        getValues,
        setValue,
        errors,
        control,
        handleSubmit,
        watch,
        clearErrors,
        setError,
        formState,
        reset,
    };
}

// Path through a JSON array using a period delimited string
function inspectArrayWithString(items, str) {
    const path = str.split('.');
    let value = items;

    for (let i = 0; i < path.length; i++) {
        value = value?.[path[i]];
    }

    return value;
}

function buildTableColumns(columns, data) {
    if (!columns) {
        return null;
    }

    const result = [];

    for (let i = 0; i < columns.length; i++) {
        const column = columns[i];
        let curr = {
            field: column.field,
            headerName: column.headerName,
            headerAlign: 'center',
            align: 'center',
            flex: column.flex || 1,
            sortable: column.sortable,
            renderCell: column.renderCell,
        };

        // Load a nested child value
        if (column.field.split('.')?.length > 1) {
            curr.renderCell = (params) => {
                return inspectArrayWithString(params.row, column.field);
            };
        }

        // Custom component
        if (column.Component) {
            // Build a component for this column if needed
            curr.renderCell = (params) => {
                const value = data.filter((a) => a.id === params.row.id)[0];
                const props = {};
                for (let j = 0; j < column.props?.length; j++) {
                    const prop = column.props[j];
                    props[prop] = value[prop];
                }
                return <column.Component {...props} />;
            };
        }

        result.push(curr);
    }

    return result;
}

function extractInputs(id, inputs, yup) {
    let result = {};

    for (let i = 0; i < inputs.length; i++) {
        const values = inspectArrayWithString(yup.getValues(), id);
        for (let j = 0; j < inputs[i].length; j++) {
            const input = inputs[i][j];
            const value = values[input.id];
            result[`${input.id}`] = value;

            // Bring in the input values for toggle fields (nested input array within each toggle option)
            if (input.toggle) {
                for (let toggle in input.toggle) {
                    const inputs = input.toggle[toggle].inputs;
                    for (let ii = 0; ii < inputs.length; ii++) {
                        for (let jj = 0; jj < inputs[ii].length; jj++) {
                            const input = inputs[ii][jj];
                            result[`${input.id}`] = values[input.id];
                        }
                    }
                }
            }
        }
    }

    return result;
}

function getAlphabet() {
    return [
        { id: 'A' },
        { id: 'B' },
        { id: 'C' },
        { id: 'D' },
        { id: 'E' },
        { id: 'F' },
        { id: 'G' },
        { id: 'H' },
        { id: 'I' },
        { id: 'J' },
        { id: 'K' },
        { id: 'L' },
        { id: 'M' },
        { id: 'N' },
        { id: 'O' },
        { id: 'P' },
        { id: 'Q' },
        { id: 'R' },
        { id: 'S' },
        { id: 'T' },
        { id: 'U' },
        { id: 'V' },
        { id: 'W' },
        { id: 'X' },
        { id: 'Y' },
        { id: 'Z' },
    ];
}

function getTitles() {
    return [
        { id: 'Mr' },
        { id: 'Ms' },
        { id: 'Mrs' },
        { id: 'Miss' },
        { id: 'Dr' },
        { id: 'Rev' },
        { id: 'Fr' },
        { id: 'M' },
        { id: 'Mlle' },
        { id: 'Mme' },
        { id: 'Rév' },
    ];
}

function getDevelopers(setState) {
    axios
        .get(`/developer/getAdmin`)
        .then((res) => {
            setState(res.data);
        })
        .catch(function () {
            setState(null);
        });
}

function getBrokers(setState) {
    axios
        .get(`/broker/getAdmin`)
        .then((res) => {
            setState(res.data);
        })
        .catch(function () {
            setState(null);
        });
}

function getInvites(setState, body) {
    axios
        .post(`/invite/getInvites`, body)
        .then((res) => {
            setState(res.data);
        })
        .catch(function () {
            setState(null);
        });
}

function getConnectors(setState) {
    axios
        .get(`/hotglue/getConnectors`)
        .then((res) => {
            setState(res.data);
        })
        .catch(function () {
            setState(null);
        });
}

function loadForm(data, setValue) {
    if (data) {
        for (var item in data) {
            setValue(item, data[item]);
        }
    }
}

function getIncentives(setState, body) {
    axios
        .post(`/project/getIncentives`, body)
        .then((res) => {
            setState(res.data);
        })
        .catch(function () {});
}

function getExistingIncentives(setState, body) {
    axios
        .post(`/amendment/getExistingIncentives`, body)
        .then((res) => {
            setState(res.data);
        })
        .catch(function () {});
}

function getAvailableIncentives(setState, body) {
    axios
        .post(`/amendment/getAvailableIncentives`, body)
        .then((res) => {
            setState(res.data);
        })
        .catch(function () {});
}

function getAvailableDepositSchedules(setState, body) {
    axios
        .post(`/deposit/getAvailableDepositSchedules`, body)
        .then((res) => {
            setState(res.data);
        })
        .catch(function () {});
}

function getUpgradeOptions(setState, body) {
    axios
        .post(`/project/getUpgradeOptions`, body)
        .then((res) => {
            setState(res.data);
        })
        .catch(function () {});
}

function getUpgradeOptionsByUpgrade(setState, body) {
    axios
        .post(`/upgrade/getOptionsByUpgrade`, body)
        .then((res) => {
            setState(res.data);
        })
        .catch(function () {});
}

function getUpgradeOptionsWithProperty(setState, body) {
    axios
        .post(`/upgrade/getOptionsWithProperty`, body)
        .then((res) => {
            setState(res.data);
        })
        .catch(function () {});
}
function getUpgradeOptionsWithoutProperty(setState, body) {
    axios
        .post(`/upgrade/getOptionsWithoutProperty`, body)
        .then((res) => {
            setState(res.data);
        })
        .catch(function () {});
}

function getExistingDeposits(setState, body) {
    axios
        .post(`/deposit/getExistingDepositSchedule`, body)
        .then((res) => {
            setState(res.data);
        })
        .catch(function () {});
}

function getPaymentTypes(setState) {
    axios
        .post(`/deposit/getPaymentTypes`)
        .then((res) => {
            setState(res.data);
        })
        .catch(function () {});
}

// TODO: All of these state setters should be optimized/simplified, just need strings for urls stored in a JSON
export {
    readFileAsText,
    uploadFiles,
    add,
    remove,
    SingleMediaPicker,
    getMediaType,
    getMediaElements,
    formatMediaSubmission,
    mapConfig,
    getPropertyTypes,
    getOwnershipTypes,
    getDeveloperProjects,
    getProjects,
    getAmendmentTypes,
    getUpgradeTypes,
    getUpgradeSubtypes,
    getEventTypes,
    getAmenities,
    getDocupilotTemplates,
    getAdditionalSigners,
    buildValidationSchema,
    buildFormOptions,
    useYup,
    inspectArrayWithString,
    camelToString,
    buildTableColumns,
    extractInputs,
    getAlphabet,
    getTitles,
    getDevelopers,
    getBrokers,
    getInvites,
    getConnectors,
    getBuilders,
    loadForm,
    getIncentives,
    getAvailableIncentives,
    getExistingIncentives,
    getUpgradeOptions,
    getUpgradeOptionsByUpgrade,
    getUpgradeOptionsWithProperty,
    getUpgradeOptionsWithoutProperty,
    getAvailableDepositSchedules,
    getDocupilotTemplateTypes,
    getManualPaymentOptions,
    getExistingDeposits,
    getPaymentTypes,
};

import modalStatuses from './constants/modalStatuses';
import './assets/fonts/optima/style.css';

const DARK = '#212529';

const BRAND = '#a18e75';
const BRAND_DARK = '#8a7860';

const TINT = '#f0ece6';
const TINT_LIGHT = '#faf8f5';
const TINT_DARK = '#e8e3da';
const TINT_DARKER = '#d9d4ca';
const TINT_FONT = '#635641';

const DISABLED = '#777';
const INFO = '#1874FF';

const buttonVariants = [
    {
        props: { variant: 'dark' },
        style: {
            color: 'white',
            backgroundColor: DARK,
            ':hover': {
                color: 'white',
                backgroundColor: '#111',
            },
            '&:disabled': {
                color: DISABLED,
            },
        },
    },
    {
        props: { variant: 'warning' },
        style: {
            color: 'white',
            backgroundColor: '#f09c02',
            ':hover': {
                color: 'white',
                backgroundColor: '#de9000',
            },
            '&:disabled': {
                color: DISABLED,
            },
        },
    },
    {
        props: { variant: 'danger' },
        style: {
            color: 'white',
            backgroundColor: '#c92828',
            ':hover': {
                color: 'white',
                backgroundColor: '#ba2222',
            },
            '&:disabled': {
                color: DISABLED,
            },
        },
    },
    {
        props: { variant: 'dark-transparent' },
        style: {
            color: 'white',
            backgroundColor: `${DARK}90`,
            ':hover': {
                color: 'white',
                backgroundColor: '#ffffff10',
            },
            '&:disabled': {
                color: DISABLED,
            },
        },
    },
    {
        props: { variant: 'light' },
        style: {
            color: DARK,
            backgroundColor: '#dbdbdb',
            ':hover': {
                color: DARK,
                backgroundColor: '#fff',
            },
        },
    },
    {
        props: { variant: 'grey' },
        style: {
            color: DARK,
            backgroundColor: '#dbdbdb',
            ':hover': {
                color: DARK,
                backgroundColor: '#ccc',
            },
        },
    },
    {
        props: { variant: 'blue' },
        style: {
            color: 'white',
            backgroundColor: INFO,
            ':hover': {
                color: 'white',
                backgroundColor: '#0052cf',
            },
            '&:disabled': {
                color: '#a6a6a6',
                backgroundColor: '#e0e0e0',
            },
        },
    },
    {
        props: { variant: 'white' },
        style: {
            color: DARK,
            backgroundColor: 'white',
            ':hover': {
                color: DARK,
                backgroundColor: '#eaeaea',
            },
        },
    },
    {
        props: { variant: 'white-transparent' },
        style: {
            color: 'white',
            backgroundColor: 'transparent',
            WebkitTransition: 'color 50ms ease-out',
            MozTransition: 'color 50ms ease-out',
            OTransition: 'color 50ms ease-out',
            transition: 'color 50ms ease-out',
            ':hover': {
                color: 'white',
                backgroundColor: 'transparent',
                textDecoration: 'underline',
                WebkitTransition: 'text-decoration 100ms ease-in',
                MozTransition: 'text-decoration 100ms ease-in',
                OTransition: 'text-decoration 100ms ease-in',
                transition: 'text-decoration 100ms ease-in',
            },
        },
    },
    {
        props: { variant: 'yellow' },
        style: {
            color: 'white',
            backgroundColor: '#ffb129',
            ':hover': {
                color: 'white',
                backgroundColor: '#f5a720',
            },
            border: '1px solid #ffb129 !important',
        },
    },
    {
        props: { variant: 'brand' },
        style: {
            color: 'white',
            backgroundColor: BRAND,
            ':hover': {
                color: 'white',
                backgroundColor: BRAND_DARK,
            },
            '&:disabled': {
                color: '#917660',
            },
        },
    },
    {
        props: { variant: 'blue-transparent' },
        style: {
            color: INFO,
            ':hover': {
                color: '#1057c2',
                backgroundColor: 'transparent',
            },
        },
    },
    {
        props: { variant: 'plain' },
        style: {
            backgroundColor: 'transparent',
            ':hover': {},
        },
    },
    {
        props: { size: 'tiny' },
        style: {
            fontSize: 13,
            padding: '0.6em 2em',
        },
    },
    {
        props: { size: 'small' },
        style: {
            fontSize: 15,
            padding: '0.8em 1em 0.8em 1em',
        },
    },
    {
        props: { size: 'medium' },
        style: {
            fontSize: 16,
        },
    },
    {
        props: { size: 'large' },
        style: {
            fontSize: 17,
        },
    },
    {
        props: { type: 'border' },
        style: {
            border: '1px solid',
        },
    },
    {
        props: { type: 'shadow' },
        style: {
            boxShadow: '0px 6px 7px 1px #00000062',
        },
    },
];

const muiTheme = {
    breakpoints: {
        values: {
            xs: 0,
            md: 900,
            lg: 1500,
        },
    },
    typography: {
        fontFamily: ['urbanist', 'sans-serif'].join(','),
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        fontWeightBold: 700,
        color: DARK,
        heading: {
            fontFamily: 'superior-title',
        },
    },
    palette: {
        dark: DARK,
        darker: '#222222',
        blue: '#045be0',
        tint: TINT,
        tintLight: TINT_LIGHT,
        tintDark: TINT_DARK,
        tintDarker: TINT_DARKER,
        brand: BRAND,
        brandDark: BRAND_DARK,
        tintFont: TINT_FONT,
        disabled: DISABLED,
        status: {
            ok: DARK,
            info: INFO,
            success: '#14B157',
            warning: '#f09c02',
            danger: '#C40B37',
            disabled: DISABLED,
            progress: '#7c14de',
        },
        background: {
            ok: '#fff',
            info: '#eaf2fd',
            success: '#eaf7ef',
            warning: '#fdffd1',
            danger: '#fff2f2',
        },
        text: {
            light: '#bbb',
        },
    },
    components: {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    boxShadow: '0px 3px 7px rgba(0, 0, 0, 0.2)',
                    color: 'white',
                },
            },
            variants: [
                {
                    props: { variant: 'transparent' },
                    style: {
                        backgroundColor: '#00000000',
                        boxShadow: 'none',
                    },
                },
                {
                    props: { variant: 'dark' },
                    style: {
                        backgroundColor: DARK,
                    },
                },
                {
                    props: { variant: 'admin' },
                    style: {
                        backgroundColor: '#3b1717',
                    },
                },
                {
                    props: { variant: 'development' },
                    style: {
                        backgroundColor: 'black',
                    },
                },
            ],
        },
        MuiIconButton: {
            variants: buttonVariants,
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    borderRadius: 100,
                    fontFamily: 'urbanist',
                    padding: '15px 45px',
                    fontWeight: '500',
                },
            },
            variants: buttonVariants,
        },
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    borderRadius: 10,
                },
            },
        },
        MuiListItem: {
            styleOverrides: {
                root: {
                    backgroundColor: 'white',
                    boxShadow:
                        '0 1px 1px rgba(0,0,0,0.01), 0 2px 2px rgba(0,0,0,0.04)',
                    borderRadius: 10,
                    ':hover': {
                        backgroundColor: '#ddd',
                    },
                },
            },
            variants: [
                {
                    props: { variant: 'dark' },
                    style: {
                        color: 'white',
                        backgroundColor: DARK,
                        ':hover': {
                            color: 'white',
                            backgroundColor: '#111',
                        },
                        '&:disabled': {
                            color: DISABLED,
                        },
                    },
                },
                {
                    props: { variant: 'brand' },
                    style: {
                        color: 'white',
                        backgroundColor: BRAND,
                        ':hover': {
                            color: 'white',
                            backgroundColor: BRAND_DARK,
                        },
                        '&:disabled': {
                            color: DISABLED,
                        },
                    },
                },
                {
                    props: { variant: 'tint' },
                    style: {
                        color: DARK,
                        backgroundColor: TINT,
                        ':hover': {
                            color: DARK,
                            backgroundColor: TINT_DARK,
                        },
                        '&:disabled': {
                            color: DISABLED,
                        },
                    },
                },
                {
                    props: { variant: 'tintDark' },
                    style: {
                        color: DARK,
                        backgroundColor: TINT_DARK,
                        ':hover': {
                            color: DARK,
                            backgroundColor: TINT_DARKER,
                        },
                        '&:disabled': {
                            color: DISABLED,
                        },
                    },
                },
            ],
        },
        MuiCard: {
            variants: [
                {
                    props: { variant: 'modal' },
                    style: {
                        /*
                        position: 'fixed',
                        CAUTION: Causes the stripe modal content to blur:
                        transform: 'translate(-50%, -50%)',
                        */
                        position: 'relative',
                        backgroundColor: 'white',
                        boxShadow: 24,
                        maxHeight: '100%',
                        maxWidth: '100%',
                        overflowY: 'auto',
                        outline: 'none',
                    },
                },
                {
                    props: { type: modalStatuses.SUCCESS.value },
                    style: {
                        backgroundColor: '#f4fff8',
                    },
                },
                {
                    props: { type: modalStatuses.DANGER.value },
                    style: {
                        backgroundColor: '#fff2f2',
                    },
                },
                {
                    props: { type: modalStatuses.INFO.value },
                    style: {
                        backgroundColor: '#f4faff',
                    },
                },
            ],
        },
        MuiMenu: {
            styleOverrides: {
                list: {
                    paddingTop: 0,
                    paddingBottom: 0,
                },
            },
            variants: [
                {
                    props: { type: 'dropdown' },
                    style: {
                        '> .MuiPaper-root': {
                            border: '1px solid #ccc',
                            borderRadius: 20,
                            marginTop: 5,
                            boxShadow: 'rgba(0, 0, 0, 0.2) 0px 0px 5px 1px',
                        },
                        ul: {
                            width: '100%',
                        },
                    },
                },
                {
                    props: { border: 'split' },
                    style: {
                        ul: {
                            '> *:not(:last-child)': {
                                borderBottom: '1px solid #ccc',
                            },
                        },
                    },
                },
            ],
        },
        MuiBadge: {
            variants: [
                {
                    props: { type: 'notifications' },
                    style: {
                        '> span': {
                            paddingTop: 2,
                            backgroundColor: INFO,
                        },
                    },
                },
            ],
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    borderColor: `${DARK} !important`,
                },
            },
            variants: [
                {
                    props: { variant: 'light' },
                    style: {
                        borderColor: '#ccc !important',
                    },
                },
            ],
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    backgroundColor: 'white',
                    color: DARK,
                    borderRadius: 20,
                    marginTop: 5,
                    boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 3px 1px',
                },
            },
        },
        MuiStepIcon: {
            styleOverrides: {
                root: {
                    backgroundColor: `${DARK} !important`,
                    color: 'white !important',
                    padding: 1,
                    borderRadius: 200,
                    marginRight: 20,
                    fontSize: 34,
                },
                text: {
                    fill: DARK,
                    fontWeight: 500,
                },
            },
        },
        MuiStepLabel: {
            styleOverrides: {
                label: {
                    fontSize: 18,
                    fontWeight: '600 !important',
                    marginTop: 2,
                    marginLeft: 5,
                },
                '&.Mui-completed': {
                    fontSize: 18,
                    fontWeight: '600 !important',
                },
            },
        },
        MuiStepContent: {
            styleOverrides: {
                root: {
                    marginLeft: 16,
                },
                transition: {
                    marginLeft: 26,
                },
            },
        },
        MuiStepConnector: {
            styleOverrides: {
                root: {
                    marginLeft: 16,
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    fontSize: 15,
                    color: `${TINT_FONT} !important`,
                    '&.Mui-selected': {
                        color: `${DARK} !important`,
                    },
                    textTransform: 'none',
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    color: `${DARK} !important`,
                    backgroundColor: `${TINT_DARK} !important`,
                },
                indicator: {
                    backgroundColor: `${DARK} !important`,
                },
            },
        },
    },
};

export default muiTheme;

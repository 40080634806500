import {
    Box,
    Button,
    Divider,
    Grid,
    IconButton,
    Tooltip,
    Typography,
    useMediaQuery,
} from '@mui/material';
import { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import PopupForm from '../common/PopupForm';
import { formatText, formatTypes } from '../../scripts/style';
import { auctionTypesById } from '../../constants/auctionTypes';
import CollapseContainer from '../common/CollapseContainer';
import NoData from '../common/NoData';
import Envelope from '../common/Envelope';
import DynamicIcon from '../common/DynamicIcon';
import { provinces } from '../../scripts/validation';
import SegmentedProgressBar from '../common/SegmentedProgressBar';
import mediaTypes from '../../constants/mediaTypes';
import { getPaymentTypes, useYup } from '../../scripts/cms';
import Field from '../cms/Field';
import { Link } from 'react-router-dom';
import DataScroller from '../common/DataScroller';
import Panels from '../common/Panels';
import HTMLParse from '../common/HTMLParse';

function PurchaseTracker({ config = {}, param }) {
    const body = { [config.paramId]: param };

    return (
        <DataScroller
            api={config.api}
            body={body}
            placeholder="Unit Number"
            Child={PurchaseItem}
            childParams={{ config, body }}
            attributes={
                config.admin
                    ? [
                          {
                              id: 'purchaseId',
                              label: 'Purchase ID',
                          },
                          {
                              id: 'auctionId',
                              label: 'Auction ID',
                          },
                          {
                              id: 'propertyId',
                              label: 'Property ID',
                          },
                      ]
                    : []
            }
        />
    );
}

function PurchaseItem({ config = {}, item, body = {} }) {
    const [auction, setAuction] = useState(item);
    const [loading, setLoading] = useState(false);

    // - Tidy up variable usage -
    const { property, purchase } = auction;

    const primary = purchase?.signatures?.[0];
    const primaryPurchaserName = primary
        ? primary.idv
            ? `${primary.idv.firstName} ${primary.idv.lastName}`
            : `${primary.firstName} ${primary.lastName}`
        : auction.bids[0].user.contactName;
    const business = purchase?.business;

    function refresh() {
        setLoading(true);

        axios
            .post(config.api, {
                query: { auctionId: auction.id },
                ...body,
            })
            .then((res) => {
                const data = res.data.rows[0];
                if (data) {
                    setAuction(data);
                }
            })
            .catch(function () {})
            .finally(function () {
                setLoading(false);
            });
    }

    return (
        <CollapseContainer
            title={`Unit #${property.unitNumber}`}
            description={`${
                business ? ` ${business.title}, ` : ''
            } ${primaryPurchaserName}`}
            expandDefault={item.expand || false}
            containerBackgroundColor="tintLight"
            backgroundColor="tintDark"
            color="dark"
            barHeight={50}
            handleRefresh={() => {
                refresh(auction?.id);
            }}
            loading={loading}
        >
            <Panels
                panels={[
                    {
                        label: 'Overview',
                        index: 0,
                        children: (
                            <Overview
                                auction={auction}
                                config={config}
                                setAuction={setAuction}
                            />
                        ),
                    },
                    {
                        label: 'Amendments',
                        index: 1,
                        children: <NoData description="Coming Soon" />,
                        admin: true,
                    },
                ]}
                config={config}
            />
        </CollapseContainer>
    );
}

function Overview({ auction, config, setAuction }) {
    // - Tidy up variable usage -
    const { purchase, timing } = auction;

    return (
        <Box>
            <Details auction={auction} config={config} />
            <Divider sx={{ marginBottom: 3, marginTop: 2 }} />
            <Box display="flex">
                <Box flex={1}>
                    <Section title="Timeline">
                        <SegmentedProgressBar
                            inputs={[
                                {
                                    title: 'Post-Purchase',
                                    ...timing.postPurchase,
                                },
                                {
                                    title: 'IDV',
                                    ...timing.idv,
                                },
                                {
                                    title: 'Docusign',
                                    ...timing.docusign,
                                },
                            ]}
                        />
                    </Section>
                    <Information
                        auction={auction}
                        setAuction={setAuction}
                        config={config}
                    />
                    {purchase && (
                        <Section title="Deposit Payments" lock={!purchase}>
                            {!purchase && (
                                <NoData
                                    paddingTop={8}
                                    paddingBottom={8}
                                    description="Available after post-purchase."
                                />
                            )}
                            <SegmentedProgressBar
                                inputs={timing.deposits.map((deposit, i) => ({
                                    ...deposit,
                                    title: `Deposit #${i + 1}`,
                                }))}
                                reverse
                                Child={DepositInfo}
                                childAttribute="deposit"
                                childProps={{ purchase, config }}
                            />
                        </Section>
                    )}
                    {purchase?.purchaseCommissionPayments?.length > 0 && (
                        <Section title="Commissions" lock={false}>
                            <SegmentedProgressBar
                                inputs={purchase.purchaseCommissionPayments.map(
                                    (purchaseCommissionPayment, i) => ({
                                        purchaseCommissionPayment,
                                        title: `Commission #${i + 1}`,
                                        status: purchaseCommissionPayment.paid
                                            ? 'done'
                                            : undefined,
                                    })
                                )}
                                reverse
                                Child={CommissionInfo}
                                childAttribute="purchaseCommissionPayment"
                                childProps={{ purchase, config }}
                            />
                        </Section>
                    )}
                    {config.admin && (
                        <PurchaseMedia
                            purchaseMedia={purchase?.purchaseMedia}
                            purchaseId={purchase?.id}
                            auctionId={auction?.id}
                        />
                    )}
                    {config.admin && <NoteInput auction={auction} />}
                </Box>
                {config.admin && (
                    <Actions auction={auction} setAuction={setAuction} />
                )}
            </Box>
        </Box>
    );
}

function Actions({ auction, setAuction }) {
    // - Tidy up variable usage -
    const { purchase } = auction;

    // APS
    const activeAPS =
        purchase?.envelope &&
        (!purchase.envelope.cancellationDate || purchase.envelope.signDate);
    // ICA
    const activeICA =
        purchase?.commissionEnvelope &&
        (!purchase.commissionEnvelope.cancellationDate ||
            purchase.commissionEnvelope.signDate);

    function handleCreateAPS(auctionId) {
        axios
            .post(`/postPurchase/createEnvelope`, { auctionId })
            .then((res) => {
                toast.success('APS Created');
                setAuction({
                    ...auction,
                    purchase: {
                        ...auction.purchase,
                        envelope: res.data,
                    },
                });
            })
            .catch(function (err) {
                toast.error(
                    err.response.data?.message || err.response?.statusText
                );
            });
    }
    function handleVoidAPS(auctionId, body) {
        axios
            .post(`/postPurchase/voidAPS`, { auctionId, ...body })
            .then((res) => {
                toast.success('APS Voided');
            })
            .catch(function (err) {
                toast.error(
                    err.response.data?.message || err.response?.statusText
                );
            });
    }

    function handleCreateICA(auctionId) {
        axios
            .post(`/postPurchase/createCommissionEnvelope`, { auctionId })
            .then((res) => {
                toast.success('ICA Created');
                setAuction({
                    ...auction,
                    purchase: {
                        ...auction.purchase,
                        commissionEnvelope: res.data,
                    },
                });
            })
            .catch(function (err) {
                toast.error(
                    err.response.data?.message || err.response?.statusText
                );
            });
    }
    function handleVoidICA(auctionId, body) {
        axios
            .post(`/postPurchase/voidICA`, { auctionId, ...body })
            .then((res) => {
                toast.success('ICA Voided');
            })
            .catch(function (err) {
                toast.error(
                    err.response.data?.message || err.response?.statusText
                );
            });
    }

    function handleCreatePayment(auctionId, body) {
        axios
            .post(`/postPurchase/createPayment`, { auctionId, ...body })
            .then((res) => {
                window.location.reload();
            })
            .catch(function (err) {
                toast.error(
                    err.response.data?.message || err.response?.statusText
                );
            });
    }

    function handleExtendPostPurchase(auctionId) {
        axios
            .post(`/postPurchase/extend`, { auctionId })
            .then((res) => {
                window.location.reload();
            })
            .catch(function (err) {
                toast.error(
                    err.response.data?.message || err.response?.statusText
                );
            });
    }

    function handleCancelPurchase(auctionId) {
        axios
            .post(`/postPurchase/cancel`, { auctionId })
            .then((res) => {
                window.location.reload();
            })
            .catch(function (err) {
                toast.error(
                    err.response.data?.message || err.response?.statusText
                );
            });
    }

    function handleRefreshAmounts(auctionId) {
        axios
            .post(`/postPurchase/refreshAmounts`, { auctionId })
            .then((res) => {
                window.location.reload();
            })
            .catch(function (err) {
                toast.error(
                    err.response.data?.message || err.response?.statusText
                );
            });
    }

    function handleUpdateCRM(auctionId, target) {
        axios
            .post(`/hotglue/update${target}/${auctionId}`)
            .then((res) => {
                toast.success('CRM Updated');
            })
            .catch(function (err) {
                toast.error(
                    err.response.data?.message || err.response?.statusText
                );
            });
    }

    function handleDealSummary(auctionId) {
        axios
            .post(`/developer/createDealSummary/${auctionId}`)
            .then((res) => {
                toast.success('Deal Summary sent');
            })
            .catch(function (err) {
                toast.error(
                    err.response.data?.message || err.response?.statusText
                );
            });
    }

    function handleToggleStaffPurchase(purchaseId) {
        axios
            .post(`/postPurchase/toggleStaffPurchase`, { purchaseId })
            .then((res) => {
                setAuction({
                    ...auction,
                    purchase: {
                        ...auction.purchase,
                        isStaffPurchase: res.data,
                    },
                });
                toast.success(`Staff purchase: ${res.data}`);
            })
            .catch(function (err) {
                toast.error(
                    err.response.data?.message || err.response?.statusText
                );
            });
    }

    return (
        <Box
            width={200}
            borderLeft="1px solid #eee"
            marginLeft={2}
            paddingLeft={2}
        >
            <Typography fontSize={16} fontWeight="bold" gutterBottom>
                Actions
            </Typography>
            <ButtonList
                groups={[
                    {
                        title: 'Purchase',
                        inputs: [
                            {
                                title: 'Extend Post-Purchase',
                                disabled:
                                    !!purchase ||
                                    !auction.purchaseExpirationDate,
                                handleClick: () =>
                                    handleExtendPostPurchase(auction.id),
                            },
                            {
                                title: 'Schedule Deposits',
                                disabled:
                                    purchase?.scheduleDate !== null ||
                                    (purchase?.clientAccountId === null &&
                                        purchase?.manualPaymentOption ===
                                            null) ||
                                    !purchase?.envelope?.signDate,
                                inputs: [
                                    [
                                        {
                                            id: 'allowLate',
                                            label: 'Reschedule late deposits',
                                            checkbox: true,
                                            optional: true,
                                            description: `If checked, deposits that are due on/before today's date will be scheduled for tomorrow (at the earliest) instead.`,
                                        },
                                    ],
                                ],
                                handleClick: (body) =>
                                    handleCreatePayment(auction.id, body),
                            },
                            {
                                title: 'Cancel Purchase',
                                disabled:
                                    !auction.isReleased &&
                                    !auction.isRescinded &&
                                    purchase &&
                                    purchase?.envelope?.signDate,
                                handleClick: () =>
                                    handleCancelPurchase(auction.id),
                            },
                            {
                                title: 'Refresh Amounts',
                                body: (
                                    <>
                                        The following fields will be
                                        re-calculated:
                                        <ul>
                                            {!activeAPS && <li>Deposits</li>}
                                            {!activeICA && <li>Commission</li>}
                                        </ul>
                                    </>
                                ),
                                disabled: !purchase || (activeAPS && activeICA),
                                handleClick: () =>
                                    handleRefreshAmounts(auction.id),
                            },
                            {
                                title: 'Toggle Staff Purchase',
                                handleClick: () =>
                                    handleToggleStaffPurchase(purchase.id),
                                disabled: !purchase || purchase.brokerId,
                            },
                        ],
                    },
                    {
                        title: 'DocuSign - APS',
                        inputs: [
                            {
                                title: 'Send APS',
                                disabled:
                                    !purchase ||
                                    !auction.sold ||
                                    (purchase.envelopeId !== null &&
                                        !purchase.envelope?.cancellationDate) ||
                                    purchase.envelope?.pending,
                                handleClick: () => handleCreateAPS(auction.id),
                            },
                            {
                                title: 'Void APS',
                                disabled:
                                    !!purchase?.envelope?.signDate ||
                                    !purchase?.envelopeId ||
                                    !!purchase?.envelope?.cancellationDate ||
                                    purchase?.envelope?.pending,
                                handleClick: (body) =>
                                    handleVoidAPS(auction.id, body),
                                inputs: [
                                    [
                                        {
                                            id: 'reason',
                                            rows: 4,
                                            optional: true,
                                            description:
                                                'This reason will be visible to all signers.',
                                        },
                                    ],
                                ],
                            },
                        ],
                    },
                    {
                        title: 'DocuSign - ICA',
                        inputs: [
                            {
                                title: 'Send ICA',
                                disabled:
                                    !purchase ||
                                    !auction.sold ||
                                    (purchase.commissionEnvelopeId !== null &&
                                        !purchase.commissionEnvelope
                                            ?.cancellationDate) ||
                                    !purchase.brokerId ||
                                    purchase.commissionEnvelope?.pending,
                                handleClick: () => handleCreateICA(auction.id),
                            },
                            {
                                title: 'Void ICA',
                                disabled:
                                    !!purchase?.commissionEnvelope?.signDate ||
                                    !purchase?.commissionEnvelopeId ||
                                    !!purchase?.commissionEnvelope
                                        ?.cancellationDate ||
                                    purchase?.commissionEnvelope?.pending,
                                handleClick: (body) =>
                                    handleVoidICA(auction.id, body),
                                inputs: [
                                    [
                                        {
                                            id: 'reason',
                                            rows: 4,
                                            optional: true,
                                            description:
                                                'This reason will be visible to all signers.',
                                        },
                                    ],
                                ],
                            },
                        ],
                    },
                    {
                        title: 'Other',
                        inputs: [
                            {
                                title: 'Update Salesforce',
                                handleClick: () =>
                                    handleUpdateCRM(auction.id, 'Salesforce'),
                            },
                            {
                                title: 'Update Google Sheets',
                                handleClick: () =>
                                    handleUpdateCRM(auction.id, 'GoogleSheets'),
                                disabled:
                                    process.env.REACT_APP_ENV === 'production',
                            },
                            {
                                title: 'Send Deal Summary',
                                handleClick: () =>
                                    handleDealSummary(auction.id),
                                disabled: !purchase?.envelope?.signDate,
                            },
                        ],
                    },
                ]}
            />
        </Box>
    );
}

function ButtonList({ groups = [] }) {
    const [curr, setCurr] = useState();

    return (
        <Box marginBottom={3}>
            {groups.map((group, i) => (
                <Box key={i} flex={1}>
                    <Typography marginBottom={1}>{group.title}</Typography>
                    {group.inputs.map((input, j) => (
                        <Button
                            key={j}
                            variant="white"
                            size="tiny"
                            disabled={!!input.disabled}
                            onClick={() => setCurr(input)}
                            fullWidth
                            sx={{ marginBottom: 1 }}
                        >
                            {input.title}
                        </Button>
                    ))}
                </Box>
            ))}
            <PopupForm
                title={`Are you sure you want to '${curr?.title}'`}
                body={curr?.body}
                action={(body) => {
                    curr?.handleClick(body);
                    setCurr(null);
                }}
                show={!!curr}
                setShow={setCurr}
                inputs={curr?.inputs}
            />
        </Box>
    );
}

function PurchaseInfo({ auction, config }) {
    const { property, purchase, timing, depositSchedule } = auction;
    const { address } = property;
    const { project } = address;

    return (
        <Box padding={1} marginTop={1} paddingBottom={8}>
            <Line
                title="User"
                value={formatText(auction.bids[0].user.username)}
                bold
                gap
            />

            <Line
                title="Purchase Amount"
                value={formatText(auction.bids[0].amount, formatTypes.MONEY)}
            />
            <Line
                title="Upgrade Price"
                value={formatText(purchase?.upgradePrice, formatTypes.MONEY)}
            />
            <Line
                title="Final Price"
                value={formatText(purchase?.finalPrice, formatTypes.MONEY)}
                bold
                gap
            />
            {config.admin && (
                <Box>
                    <Line
                        title="Listing Type"
                        value={auctionTypesById[auction.auctionTypeId]}
                    />
                    <Line
                        title="Initial Price"
                        value={formatText(
                            auction.startingBid,
                            formatTypes.MONEY
                        )}
                    />
                    <Line
                        title="Reserve Amount"
                        value={formatText(
                            auction.reserveAmount,
                            formatTypes.MONEY
                        )}
                    />
                    <Line
                        title="Bid Increment"
                        value={formatText(
                            auction.bidIncrement,
                            formatTypes.MONEY
                        )}
                    />
                    <Line
                        title="Time Increment"
                        value={auction.timeIncrement}
                        gap
                    />
                </Box>
            )}

            <Line
                title="Listing Start"
                value={formatText(auction.startDate, formatTypes.DATE)}
                tooltip={formatText(auction.startDate, formatTypes.DATETIME)}
            />
            <Line
                title="Listing Expiry"
                value={formatText(auction.endDate, formatTypes.DATE)}
                tooltip={formatText(auction.endDate, formatTypes.DATETIME)}
            />

            <Line
                title="Post-Purchase Form Expires"
                value={formatText(
                    auction.purchaseExpirationDate,
                    formatTypes.DATE
                )}
                tooltip={formatText(
                    auction.purchaseExpirationDate,
                    formatTypes.DATETIME
                )}
                gap
            />
            <Line
                title="APS Sent"
                value={formatText(
                    purchase?.envelope?.sendDate,
                    formatTypes.DATE
                )}
                tooltip={formatText(
                    purchase?.envelope?.sendDate,
                    formatTypes.DATETIME
                )}
            />
            <Line
                title="APS Signed"
                value={formatText(
                    purchase?.envelope?.signDate,
                    formatTypes.DATE
                )}
                tooltip={formatText(
                    purchase?.envelope?.signDate,
                    formatTypes.DATETIME
                )}
                gap={config.admin}
            />

            {config.admin && (
                <Box>
                    <Line
                        title="Schedule Date"
                        value={formatText(
                            purchase?.scheduleDate,
                            formatTypes.DATE
                        )}
                        tooltip={formatText(
                            purchase?.scheduleDate,
                            formatTypes.DATETIME
                        )}
                        gap
                    />

                    <Line title="Purchase ID" value={purchase?.id} minor />
                    <Line title="Auction ID" value={auction.id} minor />
                    <Line title="Property ID" value={property.id} minor />
                    <Line title="Model ID" value={property.model.id} minor />
                    <Line title="Address ID" value={address.id} minor />
                    <Line title="Project ID" value={project.id} minor gap />

                    <Line
                        title="DepositSchedule ID"
                        value={auction.depositScheduleId}
                        minor
                    />
                    <Line
                        title="Docupilot Template ID"
                        value={auction.docupilotTemplateId}
                        minor
                    />
                    <Line
                        title="Listing Broker ID"
                        value={auction.listingBrokerId}
                        minor
                    />
                    <Line
                        title="Business ID"
                        value={purchase?.businessId}
                        minor
                    />
                    <Line
                        title="Envelope ID"
                        value={purchase?.envelopeId}
                        minor
                    />
                    <Line
                        title="Commission Envelope ID"
                        value={purchase?.commissionEnvelopeId}
                        minor
                    />
                    <Line
                        title="Client Account ID"
                        value={purchase?.clientAccount?.zumId}
                        minor
                    />
                </Box>
            )}
        </Box>
    );
}

function Line({
    title,
    value,
    minor,
    gap,
    noDivider,
    tooltip,
    description,
    bold,
    size = 14,
    colour = 'dark',
}) {
    // Return a gap if no values are given
    if (!title && !value) {
        return <Box marginTop={2} />;
    }

    const useTooltip = tooltip && value && value !== '---';

    return (
        <Box
            display="flex"
            color={minor ? '#aaa' : colour}
            borderBottom={
                gap ? '1px solid #bbb' : noDivider ? '' : '1px dotted #ccc'
            }
            padding={0.2}
            paddingTop={0.4}
            marginBottom={gap ? 0.8 : undefined}
            paddingBottom={gap ? 1 : undefined}
        >
            <Tooltip title={description} placement="right">
                <Typography
                    fontSize={14}
                    fontWeight={bold ? 'bold' : 'medium'}
                    marginRight="auto"
                >
                    {title}:
                </Typography>
            </Tooltip>
            <Tooltip
                title={
                    useTooltip ? (
                        <Typography fontSize={size}>
                            <HTMLParse value={tooltip} />
                        </Typography>
                    ) : null
                }
                placement="left"
            >
                <Box display="flex">
                    <Typography
                        fontSize={size}
                        fontWeight={bold ? 'bold' : 'regular'}
                    >
                        {value === null || value === undefined
                            ? formatText(value)
                            : value}
                    </Typography>
                    {useTooltip && (
                        <DynamicIcon
                            icon="StickyNote2Outlined"
                            size={20}
                            sx={{ marginLeft: 0.5 }}
                        />
                    )}
                </Box>
            </Tooltip>
        </Box>
    );
}

function Details({ auction, config }) {
    const [showInfo, setShowInfo] = useState(false);

    const { property } = auction;
    const { address } = property;
    const { project } = address;

    const margins = { marginTop: 'auto', marginBottom: 'auto' };

    return (
        <Grid container marginTop={1.5}>
            <Grid item xs={10} md={5.5} display="flex" sx={margins}>
                <Box marginTop="auto" marginBottom="auto">
                    <IconButton
                        component={Link}
                        to={`/listing/${auction.slug}`}
                        target="_blank"
                    >
                        <DynamicIcon icon="OpenInNew" colour="dark" />
                    </IconButton>
                </Box>
                <Box marginTop={1}>
                    <Typography
                        fontWeight="bold"
                        fontSize={18}
                        marginLeft={1}
                        display="inline-block"
                    >
                        {project.title}
                    </Typography>
                    <Typography
                        fontSize={18}
                        marginLeft={1}
                        display="inline-block"
                    >
                        {auction.title}
                    </Typography>
                </Box>
            </Grid>
            <Grid
                item
                xs={2}
                md={1}
                textAlign="center"
                sx={margins}
                position="relative"
            >
                <IconButton onClick={() => setShowInfo(true)}>
                    <DynamicIcon icon="Info" colour="dark" size={30} />
                </IconButton>
            </Grid>
            <Grid
                item
                xs={12}
                md={5.5}
                textAlign={{ xs: 'left', md: 'right' }}
                marginLeft={{ xs: 6, md: 0 }}
                sx={margins}
            >
                <Typography
                    fontSize={14}
                    color="text.secondary"
                >{`${address.province}, ${address.city}, ${address.postalCode}`}</Typography>
                <Typography>{`${address.streetAddress}${
                    property.unit ? ` - ${property.unit}` : ''
                }`}</Typography>
            </Grid>

            <PopupForm
                title="Additional Info"
                show={showInfo}
                setShow={setShowInfo}
                body={<PurchaseInfo auction={auction} config={config} />}
                width={450}
                height={500}
            />
        </Grid>
    );
}

function Information({ auction, setAuction, config }) {
    const [showPaymentConfig, setShowPaymentConfig] = useState(false);
    const [showFINTRAC, setShowFINTRAC] = useState(false);

    const { property, purchase, timing, depositSchedule } = auction;
    const { address } = property;
    const { project } = address;

    const broker = auction.broker || purchase?.broker;

    function handleSetPaymentMethod({ zumId }) {
        axios
            .post(`/postPurchase/adminSelectBank`, {
                zumId,
                purchaseId: purchase.id,
            })
            .then((res) => {
                toast.success(
                    'Bank account set. Press Schedule Deposits when ready.'
                );
                setShowPaymentConfig(false);
                setAuction({
                    ...auction,
                    purchase: {
                        ...auction.purchase,
                        clientAccountId: res.data.id,
                        clientAccount: res.data,
                    },
                });
            })
            .catch(function (err) {
                toast.error(
                    err.response.data?.message || err.response?.statusText
                );
                setShowPaymentConfig(false);
            });
    }

    function handleSendFINTRAC() {
        setShowFINTRAC(false);

        axios
            .post(`/postPurchase/fintrac/send`, {
                auctionId: auction.id,
            })
            .then((res) => {
                toast.success('FINTRAC Sent');
            })
            .catch(function (err) {
                toast.error(
                    err.response.data?.message || err.response?.statusText
                );
            });
    }

    return (
        <Grid container columnSpacing={2} marginBottom={2}>
            <Section title="Summary">
                {purchase?.isStaffPurchase && (
                    <Status
                        title={'Staff Purchase'}
                        value={purchase?.isStaffPurchase}
                    />
                )}
                <Status
                    title={
                        broker
                            ? `Broker: ${broker.firstName} ${broker.lastName}`
                            : purchase
                            ? 'No Broker'
                            : 'Broker: TBD'
                    }
                    value={!!broker}
                    icon="AccountBox"
                />
                <Status title="Post Purchase" value={purchase !== null} />
                <Status
                    title={
                        purchase?.envelopeId &&
                        purchase?.envelope?.cancellationDate
                            ? 'APS Sent (Voided)'
                            : 'APS Sent'
                    }
                    value={
                        purchase?.envelopeId &&
                        !purchase?.envelope?.cancellationDate
                    }
                />
                <Status
                    title="APS Completed"
                    value={timing.docusign.status === 'done'}
                />
                <Box display="flex">
                    <Status
                        title={'Payment Method Setup'}
                        value={
                            purchase?.clientAccountId ||
                            purchase?.manualPaymentOptionId
                        }
                    />
                    {config.admin &&
                        !purchase?.clientAccountId &&
                        !purchase?.manualPaymentOptionId &&
                        purchase?.envelope?.signDate && (
                            <Button
                                size="tiny"
                                variant="white"
                                type="border"
                                sx={{
                                    padding: 0,
                                    marginLeft: 1,
                                }}
                                onClick={() => setShowPaymentConfig(true)}
                            >
                                Update
                            </Button>
                        )}
                </Box>
                <Status
                    title="Deposit Payments Scheduled"
                    value={purchase?.scheduleDate}
                />
                {auction.isRescinded && (
                    <Status
                        title="Purchase Cancelled (Rescinded)"
                        value={!auction.isRescinded}
                    />
                )}
                {auction.isReleased && (
                    <Status
                        title="Purchase Cancelled (Released)"
                        value={!auction.isReleased}
                    />
                )}
                <Status
                    title="Builder Commission Agreement"
                    value={!!purchase?.commissionEnvelope?.signDate}
                />
                {!!purchase?.envelope?.signDate && (
                    <Box display="flex">
                        <Status
                            title="FINTRAC Purchaser Information Record"
                            value={!!purchase?.fintrac?.deliveredAt}
                        />

                        {config.admin && (
                            <Button
                                variant={
                                    purchase?.fintrac
                                        ? !purchase?.fintrac?.deliveredAt
                                            ? 'danger'
                                            : 'white'
                                        : 'dark'
                                }
                                type="border"
                                sx={{
                                    padding: 0,
                                    fontSize: 13,
                                    marginLeft: 1,
                                }}
                                onClick={() => setShowFINTRAC(true)}
                            >
                                Send
                            </Button>
                        )}
                    </Box>
                )}
            </Section>
            <Section title="Purchasers">
                <Verifications purchase={purchase} config={config} />
            </Section>
            <Section
                title="Agreements"
                maxHeight={400}
                sx={{ marginBottom: 0 }}
                innerSx={{ paddingLeft: 0, paddingRight: 0 }}
            >
                {!purchase?.envelope &&
                    !purchase?.commissionEnvelope &&
                    !purchase?.amendments && (
                        <NoData
                            description="There are no envelopes yet."
                            paddingTop={8}
                            paddingBottom={8}
                        />
                    )}
                <Envelope
                    title="Agreement of Purchase and Sale"
                    envelope={purchase?.envelope}
                    admin={config.admin}
                    broker={config.broker}
                    developer={config.developer}
                    auctionId={purchase?.auctionId}
                />
                <Envelope
                    title="Builder Commission Agreement"
                    envelope={purchase?.commissionEnvelope}
                    admin={config.admin}
                    broker={config.broker}
                    developer={config.developer}
                    auctionId={purchase?.auctionId}
                />
                {purchase?.amendments?.map((amendment) => (
                    <Envelope
                        key={amendment.id}
                        title={amendment?.amendmentType?.title}
                        envelope={amendment.envelope}
                        admin={config.admin}
                        broker={config.broker}
                        developer={config.developer}
                        auctionId={purchase?.auctionId}
                    />
                ))}
            </Section>

            <PopupForm
                title="Update Payment Method"
                body={
                    <div>
                        <p>
                            Carefully select the bank account that should be
                            used for this purchase.
                        </p>
                        <p>
                            After submitting this form, you will need to{' '}
                            <b>Schedule Deposits</b> using the button in the
                            action panel on the right.
                        </p>
                    </div>
                }
                show={showPaymentConfig}
                setShow={setShowPaymentConfig}
                inputs={[
                    [
                        {
                            id: 'zumId',
                            description:
                                'This ID comes from the parameter in the URL of a Zum user.',
                        },
                    ],
                ]}
                action={handleSetPaymentMethod}
            />
            <PopupForm
                title="Send FINTRAC?"
                body={
                    <div>
                        <p>This will send the FINTRAC document to:</p>
                        {!!broker && <b>Broker</b>}
                        {!broker && !!auction?.listingBroker && (
                            <b>Listing Broker</b>
                        )}
                        {!broker && !auction?.listingBroker && <b>Developer</b>}
                    </div>
                }
                show={showFINTRAC}
                setShow={setShowFINTRAC}
                action={handleSendFINTRAC}
            />
        </Grid>
    );
}

function Section({
    title,
    children,
    md = 6,
    lg = 4,
    maxHeight,
    sx = {},
    innerSx = {},
    props = {},
    lock = true,
}) {
    const xs = useMediaQuery((theme) => theme.breakpoints.down('md'));

    return (
        <Grid
            item
            xs={12}
            md={md}
            lg={lg}
            marginBottom={1}
            maxHeight={maxHeight}
            sx={{ overflowY: 'auto' }}
        >
            <CollapseContainer
                title={title}
                backgroundColor={lock ? 'tintLight' : 'tint'}
                color="dark"
                containerBackgroundColor="tintLight"
                lock={lock}
                boxShadow={null}
                expandDefault={lock ? true : xs ? false : true}
                sx={{ marginBottom: 0, ...sx }}
                innerSx={{
                    paddingTop: lock ? 0 : 1,
                    paddingRight: 1,
                    paddingLeft: 1,
                    ...innerSx,
                }}
                {...props}
            >
                {children}
            </CollapseContainer>
        </Grid>
    );
}

function Status({ title, value, icon, tooltip }) {
    return (
        <Box display="flex">
            <Tooltip title={tooltip}>
                <Box>
                    <DynamicIcon
                        icon={icon || (!!value ? 'CheckCircle' : 'Cancel')}
                        sx={{ marginRight: 1 }}
                        colour={!!value ? 'status.success' : 'status.danger'}
                    />
                </Box>
            </Tooltip>
            <Typography marginTop="auto" marginBottom="auto">
                {title}
            </Typography>
        </Box>
    );
}

function Verifications({ purchase, config }) {
    const [disclosure, setDisclosure] = useState(false);
    const [manualIDV, setManualIDV] = useState(false);

    if (!purchase) {
        return (
            <NoData
                description="Available after post-purchase."
                paddingTop={8}
                paddingBottom={8}
            />
        );
    }

    function sendDisclosure(signatureId) {
        setDisclosure(false);
        axios
            .post(`/project/sendDisclosurePackage`, { signatureId })
            .then((res) => {
                toast.success('Disclosure package sent successfully');
            })
            .catch(function (err) {
                toast.error(
                    err?.response?.data?.message ||
                        err?.message ||
                        'Failed to send disclosure package'
                );
            });
    }

    function openIDV(idvId) {
        // Exit if no IDV ID
        if (!idvId) {
            toast.error('No IDV available');
            return;
        }

        window
            .open(
                `https://dashboard.plaid.com${
                    process.env.REACT_APP_ENV === 'production' ? '' : '/sandbox'
                }/identity_verification/sessions/${idvId}`,
                '_blank'
            )
            .focus();
    }

    function submitManualIDV(form) {
        axios
            .post(`/idv/simulateIDV`, {
                signatureId: manualIDV.id,
                userId: manualIDV.userId,
                ...form,
            })
            .then((res) => {
                toast.success('IDV Updated successfully');
                setManualIDV(false);
            })
            .catch(function (err) {
                toast.error(
                    err?.response?.data?.message ||
                        err?.message ||
                        'Failed to update IDV'
                );
            });
    }

    return (
        <Box>
            {purchase.signatures.map((signature, i) => {
                const idvId = signature.idvId;

                return (
                    <Box marginBottom={1} key={signature.id}>
                        <Typography fontWeight="bold">
                            {purchase.business && signature.officer
                                ? `${purchase.business.title}, `
                                : ''}
                            {signature.idv
                                ? `${signature.idv.firstName} ${signature.idv.lastName}`
                                : `${signature.firstName} ${signature.lastName}`}
                            {signature.officer
                                ? ` - ${signature.position}`
                                : ''}
                        </Typography>
                        <Box display="flex">
                            <Status
                                title={`ID Verification`}
                                value={signature.idv?.status === 'success'}
                                tooltip={signature.idv?.status}
                            />
                            {config.admin && !!idvId && (
                                <Button
                                    variant="white"
                                    type="border"
                                    sx={{
                                        padding: 0,
                                        fontSize: 13,
                                        marginLeft: 1,
                                    }}
                                    onClick={() => openIDV(idvId)}
                                >
                                    Open
                                </Button>
                            )}
                            {config.admin &&
                                signature.idv?.status !== 'success' && (
                                    <Button
                                        variant="white"
                                        type="border"
                                        sx={{
                                            padding: 0,
                                            fontSize: 13,
                                            marginLeft: 1,
                                        }}
                                        onClick={() => {
                                            setManualIDV(signature);
                                        }}
                                    >
                                        Manual
                                    </Button>
                                )}
                        </Box>
                        <Box display="flex">
                            <Status
                                title={`Disclosure - ${signature.email}`}
                                value={signature.disclosure?.deliveredAt}
                                tooltip={signature.disclosure?.deliveredAt}
                            />
                            {config.admin && (
                                <Button
                                    variant={
                                        signature.disclosure
                                            ? !signature.disclosure.deliveredAt
                                                ? 'danger'
                                                : 'white'
                                            : 'dark'
                                    }
                                    type="border"
                                    sx={{
                                        padding: 0,
                                        fontSize: 13,
                                        marginLeft: 1,
                                    }}
                                    onClick={() => setDisclosure(signature)}
                                >
                                    Send
                                </Button>
                            )}
                        </Box>
                        {purchase.business && i === 0 && (
                            <Box display="flex">
                                <Status
                                    title={`Articles of Incorporation`}
                                    value={purchase.business.proof}
                                />
                                {config.admin && !!idvId && (
                                    <Button
                                        variant="white"
                                        type="border"
                                        sx={{
                                            padding: 0,
                                            fontSize: 13,
                                            marginLeft: 1,
                                        }}
                                        onClick={() =>
                                            openPrivateMedia(
                                                purchase.business.proof
                                            )
                                        }
                                        disabled={!purchase.business.proof}
                                    >
                                        Open
                                    </Button>
                                )}
                            </Box>
                        )}
                    </Box>
                );
            })}

            <PopupForm
                title="Send Disclosure Package"
                body={
                    <>
                        <p>
                            Are you sure you want to send the disclosure package
                            to this email?
                        </p>
                        <b>{disclosure?.email}</b>
                    </>
                }
                action={() => sendDisclosure(disclosure?.id)}
                show={disclosure}
                setShow={setDisclosure}
            />

            <PopupForm
                title="Submit Manual IDV"
                action={(form) => submitManualIDV(form)}
                body={
                    <Typography
                        fontSize={18}
                        fontWeight="medium"
                        color="status.danger"
                        marginBottom={2}
                        marginTop={2}
                        textAlign="center"
                    >
                        WARNING: Manual IDV submissions should be handled with
                        caution.
                    </Typography>
                }
                show={manualIDV}
                setShow={setManualIDV}
                inputs={[
                    [
                        {
                            id: 'sinType',
                            children: [
                                {
                                    id: 'ca_sin',
                                    title: `Canada`,
                                },
                            ],
                            placeholder: 'None',
                            optional: true,
                            label: 'SIN Type',
                        },
                        {
                            id: 'sin',
                            label: 'SIN',
                            optional: true,
                            mask: '999999999',
                        },
                    ],
                    [
                        {
                            id: 'idType',
                            children: [
                                {
                                    id: 'drivers_license',
                                    title: `Driver's Licence`,
                                },
                                { id: 'id_card', title: 'ID Card' },
                                { id: 'passport', title: 'Passport' },
                                {
                                    id: 'resident_card',
                                    title: 'Permanent Resident Card',
                                },
                            ],
                        },
                        { id: 'idNumber' },
                        { id: 'expirationDate', date: true, optional: true },
                    ],
                    [{ id: 'firstName' }, { id: 'lastName' }],
                    [
                        { id: 'birthdate', date: true },
                        { id: 'phoneNumber', mask: '+99999999999' },
                    ],
                    [
                        { id: 'country', disabled: true },
                        { id: 'province', children: provinces },
                        { id: 'city' },
                    ],
                    [
                        { id: 'postalCode', mask: 'a9a9a9' },
                        { id: 'streetAddress' },
                        { id: 'unit', optional: true },
                    ],
                ]}
                width={800}
                defaults={{
                    country: 'CA',
                    province: 'ON',
                    firstName: manualIDV?.firstName,
                    lastName: manualIDV?.lastName,
                    sinType: 'ca_sin',
                }}
            />
        </Box>
    );
}

function openPrivateMedia(media) {
    if (!media?.key) {
        return;
    }

    axios
        .post(`/media/private`, { key: media.key })
        .then((res) => {
            window.open(res.data, '_blank');
        })
        .catch(function (err) {
            toast.error(err?.response?.data?.message || 'Request failed');
        });
}

function PurchaseMedia({
    purchaseMedia: _purchaseMedia = [],
    purchaseId,
    auctionId,
}) {
    const [purchaseMedia, setPurchaseMedia] = useState(_purchaseMedia);
    const [showUpload, setShowUpload] = useState(false);

    function upload(form) {
        axios
            .post(`postPurchase/media/upload`, form)
            .then((res) => {
                toast.success('Upload successful');
                setShowUpload(false);
                setPurchaseMedia([...purchaseMedia, res.data]);
            })
            .catch(function (err) {
                toast.error(err?.response?.data?.message || 'Request failed');
            });
    }

    function handleDelete(form) {
        axios
            .post(`postPurchase/media/delete`, form)
            .then((res) => {
                setPurchaseMedia(
                    purchaseMedia.filter(
                        (item) => item.id !== form.purchaseMediaId
                    )
                );
            })
            .catch(function (err) {
                toast.error(err?.response?.data?.message || 'Request failed');
            });
    }

    return (
        <Section title="Purchaser Documents">
            <Box display="flex" flexWrap="wrap" gap={1}>
                <Button variant="dark" onClick={() => setShowUpload(true)}>
                    Add
                </Button>
                {purchaseMedia.map((curr) => (
                    <MediaItem
                        purchaseMedia={curr}
                        key={curr.id}
                        handleDelete={handleDelete}
                    />
                ))}
            </Box>
            <PopupForm
                title="Upload Purchaser Document"
                action={upload}
                show={showUpload}
                setShow={setShowUpload}
                yes="Upload"
                inputs={[
                    [
                        {
                            id: 'title',
                            children: [
                                { id: 'Finance Letter' },
                                { id: 'Tarion Deposit Receipt' },
                            ],
                        },
                    ],
                    [
                        {
                            id: 'description',
                            label: 'Description (Optional, appended to title)',
                            optional: true,
                        },
                    ],
                    [
                        {
                            id: 'media',
                            media: {
                                limit: 1,
                                mediaTypeId: mediaTypes.DOCUMENT,
                            },
                        },
                    ],
                ]}
                defaults={{
                    purchaseId: purchaseId,
                    auctionId: auctionId,
                }}
            />
        </Section>
    );
}

function MediaItem({ purchaseMedia, handleDelete }) {
    const [showDelete, setShowDelete] = useState(false);

    const { media } = purchaseMedia;

    function handleOpen() {
        openPrivateMedia(media);
    }

    return (
        <Box display="flex" backgroundColor="tint" borderRadius={2} padding={1}>
            <Button
                onClick={handleOpen}
                variant="white"
                type="border"
                size="small"
                sx={{
                    paddingLeft: 3,
                    paddingRight: 3,
                    marginRight: 1,
                    paddingTop: 0,
                    paddingBottom: 0,
                }}
            >
                {media?.title || 'Missing Title'}
            </Button>
            <Box display="flex" margin="auto">
                <IconButton onClick={() => setShowDelete(true)}>
                    <DynamicIcon icon="Close" />
                </IconButton>
            </Box>
            <PopupForm
                title="Delete?"
                body="Are you sure you want to delete this media?"
                action={handleDelete}
                show={showDelete}
                setShow={setShowDelete}
                defaults={{
                    purchaseMediaId: purchaseMedia.id,
                }}
            />
        </Box>
    );
}

function DepositInfo({ data: deposit, props = {} }) {
    // const [showCancel, setShowCancel] = useState(false);
    const [showPaymentType, setShowPaymentType] = useState(false);
    const [showStatus, setShowStatus] = useState(false);

    const { depositPayment } = deposit;
    const { fund, withdraw, refund, manualPayment, paymentTypeId } =
        depositPayment;

    const { purchase, config } = props;

    // Exit if no data
    if (!deposit || !purchase) {
        return null;
    }

    let isDepositLocked = false;
    let isPAD = false;
    let isManual = false;
    let dueDateMismatch = false;

    switch (depositPayment.paymentTypeId) {
        case 'PAD':
            // Locked?
            if (
                fund &&
                fund?.status !== 'Cancelled' &&
                fund?.status !== 'Failed'
            ) {
                isDepositLocked = true;
            }

            // Overdue
            if (
                fund &&
                fund.scheduleDate &&
                fund.scheduleDate !== depositPayment.dueDate
            ) {
                dueDateMismatch = true;
            }

            isPAD = true;
            break;

        case 'MANUAL':
            // Locked?
            if (manualPayment?.depositedDate) {
                isDepositLocked = true;
            }

            isManual = true;
            break;

        default:
            break;
    }

    // function handleCancel() {
    //     axios
    //         .post(`/deposit/cancel`, {
    //             depositPaymentId: depositPayment.id,
    //         })
    //         .then((res) => {
    //             setShowCancel(false);
    //         })
    //         .catch(function (err) {
    //             toast.error(err?.response?.data?.message || 'Request failed');
    //         });
    // }

    function handleStatus(changes) {
        axios
            .post(`/deposit/updateStatus`, changes)
            .then((res) => {
                setShowStatus(false);
                toast.success('Deposit updated successfully');
            })
            .catch(function (err) {
                toast.error(err?.response?.data?.message || 'Request failed');
            });
    }

    function handleChangePaymentType(changes) {
        axios
            .post(`/deposit/changePaymentType`, changes)
            .then((res) => {
                setShowPaymentType(false);
            })
            .catch(function (err) {
                toast.error(err?.response?.data?.message || 'Request failed');
            });
    }

    return (
        <Box padding={2} marginTop={1} backgroundColor="tint" borderRadius={4}>
            <Line title="Payment Method" value={depositPayment.paymentTypeId} />
            <Line
                title="Amount"
                value={`${formatText(
                    depositPayment.amount,
                    formatTypes.MONEY
                )} (${
                    deposit.percent !== null ? `${deposit.percent}%` : 'Fixed'
                })`}
                tooltip={
                    deposit.subtraction
                        ? `${deposit.percent}% Less ${formatText(
                              deposit.subtraction,
                              formatTypes.MONEY
                          )}`
                        : null
                }
            />
            <Line
                title="Due"
                value={formatText(
                    deposit.dueDays !== null
                        ? `${deposit.dueDays} days after ${
                              deposit.scheduleFromFirm ? 'firm' : 'signing'
                          }`
                        : deposit.dueString
                )}
            />
            <Line
                title="Scheduled Date"
                value={formatText(
                    fund?.scheduleDate || depositPayment.dueDate,
                    formatTypes.DATE
                )}
                colour={dueDateMismatch && 'status.danger'}
                bold={dueDateMismatch && true}
                tooltip={
                    dueDateMismatch &&
                    `<b>Overdue:</b> ${depositPayment.dueDate}`
                }
                gap
            />

            {/* <Line
                title={dueDateMismatch ? 'Date' : 'Scheduled Date'}
                value={formatText(
                    dueDateMismatch
                        ? `${fund?.scheduleDate} (${depositPayment.dueDate})`
                        : depositPayment.dueDate
                )}
                gap
            /> */}

            <Line
                title="Electronic Deposits"
                value=""
                minor={!isPAD}
                bold
                noDivider
            />
            <Line
                title="Received Status"
                value={formatText(fund?.status)}
                minor={!isPAD}
            />
            <Line
                title="Received Date"
                value={formatText(fund?.completedAt, formatTypes.DATE)}
                tooltip={formatText(fund?.completedAt, formatTypes.DATETIME)}
                minor={!isPAD}
            />

            {depositPayment.retried && (
                <Line
                    title="Retry Date"
                    value={formatText(fund?.scheduleDate, formatTypes.DATE)}
                    minor={!isPAD}
                />
            )}

            <Line
                title="Deposited Status"
                value={formatText(
                    withdraw?.status ||
                        (depositPayment.disableWithdraw
                            ? 'DISABLED'
                            : undefined)
                )}
                minor={!isPAD}
            />
            <Line
                title="Deposited Date"
                value={formatText(withdraw?.completedAt, formatTypes.DATE)}
                tooltip={formatText(
                    withdraw?.completedAt,
                    formatTypes.DATETIME
                )}
                minor={!isPAD}
                gap
            />

            {/* <Line
                title="Refund Status"
                value={formatText(refund?.status)}
                minor={!refund}
            />
            <Line
                title="Refund Date"
                value={formatText(
                    refund?.completedAt,
                    formatTypes.DATE
                )}
                tooltip={formatText(
                    refund?.completedAt,
                    formatTypes.DATETIME
                )}
                minor={!refund}
                gap
            /> */}

            <Line
                title="Manual Deposits"
                value=""
                minor={!isManual}
                bold
                noDivider
            />
            <Line
                title={`${
                    manualPayment?.manualPaymentOption?.title || 'Manual'
                } Status`}
                value={formatText(manualPayment?.status)}
                minor={!isManual}
            />
            <Line
                title={`${
                    manualPayment?.manualPaymentOption?.title || 'Manual'
                } Received`}
                value={formatText(
                    manualPayment?.receivedDate,
                    formatTypes.DATE
                )}
                minor={!isManual}
            />
            <Line
                title={`${
                    manualPayment?.manualPaymentOption?.title || 'Manual'
                } Deposited`}
                value={formatText(
                    manualPayment?.depositedDate,
                    formatTypes.DATE
                )}
                gap={config.admin}
                minor={!isManual}
                noDivider={!config.admin}
            />

            {config.admin && (
                <>
                    <Line
                        title="Deposit ID"
                        value={formatText(deposit.id)}
                        minor
                    />
                    <Line
                        title="Deposit Payment ID"
                        value={formatText(depositPayment.id)}
                        minor
                    />
                    <Line
                        title="Manual Payment ID"
                        value={formatText(manualPayment?.id)}
                        minor
                        gap
                    />
                    <Line
                        title="Fund ID"
                        value={formatText(fund?.id)}
                        size={10}
                        minor
                    />
                    <Line
                        title="Withdraw ID"
                        value={formatText(withdraw?.id)}
                        size={10}
                        minor
                        noDivider
                    />
                    {/* <Line
                        title="Refund ID"
                        value={formatText(refund?.id)}
                        size={10}
                        minor
                     /> */}
                </>
            )}

            {config.admin && isManual && (
                <Button
                    size="tiny"
                    variant="white"
                    fullWidth
                    disabled={!manualPayment?.media}
                    sx={{ marginTop: 1 }}
                    onClick={() => openPrivateMedia(manualPayment?.media)}
                >
                    View Cheque
                </Button>
            )}

            <Box flexGrow={1}>
                {config.admin && (
                    <DepositButton
                        title="Change Type"
                        handleClick={() => setShowPaymentType(true)}
                        condition={
                            !isDepositLocked &&
                            (isPAD || !depositPayment.paymentTypeId) &&
                            purchase?.envelope?.signDate
                        }
                    />
                )}
                {config.admin && (
                    <DepositButton
                        title="Cheque Status"
                        handleClick={() => setShowStatus(true)}
                        condition={isManual}
                    />
                )}
                {/* <DepositButton
                    title="Cancel"
                    handleClick={() => setShowCancel(true)}
                    condition={depositPayment.canCancel}
                    variant="danger"
                /> */}
            </Box>

            <PopupForm
                title="Change Cheque Status"
                action={handleStatus}
                show={showStatus}
                setShow={setShowStatus}
                yes="Submit"
                inputs={[
                    [{ id: 'receivedDate', date: true, optional: true }],
                    [
                        {
                            id: 'depositedDate',
                            date: true,
                            optional: true,
                            disabled: true,
                        },
                    ],
                    [
                        {
                            id: 'media',
                            media: {
                                limit: 1,
                                mediaTypeId: mediaTypes.IMAGE,
                            },
                            optional: true,
                        },
                    ],
                ]}
                defaults={{
                    depositPaymentId: depositPayment.id,
                    status: manualPayment?.status || '',
                    receivedDate: manualPayment?.receivedDate,
                    depositedDate: manualPayment?.depositedDate,
                    media: { media: manualPayment?.media },
                }}
            />

            <PopupForm
                title="Change Payment Type"
                action={handleChangePaymentType}
                show={showPaymentType}
                setShow={setShowPaymentType}
                inputs={[
                    [
                        {
                            id: 'paymentTypeId',
                            source: getPaymentTypes,
                        },
                    ],
                ]}
                defaults={{
                    depositPaymentId: depositPayment.id,
                    paymentTypeId: paymentTypeId,
                }}
            />

            {/* <PopupForm
                title="Cancel Deposit?"
                body="Are you sure you want to cancel this upcoming deposit?"
                action={handleCancel}
                show={showCancel}
                setShow={setShowCancel}
            /> */}
        </Box>
    );
}

function CommissionInfo({ data: pcp, props = {} }) {
    const { config } = props;

    return (
        <Box padding={2} marginTop={1} backgroundColor="tint" borderRadius={4}>
            <Line
                title="Payment Milestone"
                value={pcp.commissionPayment?.eventType?.title}
                gap
            />
            <Line
                title="Amount"
                value={`${formatText(pcp.amount, formatTypes.MONEY)} (${
                    pcp.commissionPayment.percent !== null
                        ? `${pcp.commissionPayment.percent}%`
                        : 'Fixed'
                })`}
            />

            {!config.admin && (
                <Line
                    title="Expected Date"
                    value={`${formatText(
                        pcp.event?.completedAt ||
                            pcp.event?.scheduleDate ||
                            pcp.event?.expectedDate,
                        formatTypes.DATE
                    )}`}
                    gap
                />
            )}
            {config.admin && (
                <Box>
                    <Line
                        title="Expected Date"
                        value={`${formatText(
                            pcp.event?.expectedDate,
                            formatTypes.DATE
                        )}`}
                    />
                    <Line
                        title="Scheduled Date"
                        value={`${formatText(
                            pcp.event?.scheduleDate,
                            formatTypes.DATE
                        )}`}
                    />
                    <Line
                        title="Completed At"
                        value={`${formatText(
                            pcp.event?.completedAt,
                            formatTypes.DATE
                        )}`}
                        gap
                    />
                </Box>
            )}

            <Line title="Paid Status" value={pcp?.paid ? 'Yes' : 'No'} />
            <Line
                title="Paid Date"
                value={pcp?.paidDate}
                gap={config.admin}
                noDivider={!config.admin}
            />
            {config.admin && (
                <>
                    <Line title="PCP ID" value={formatText(pcp.id)} minor />
                    <Line
                        title="CP ID"
                        value={formatText(pcp.commissionPaymentId)}
                        minor
                        noDivider
                    />
                </>
            )}
        </Box>
    );
}

function NoteInput({ auction }) {
    const yup = useYup([[{ id: 'notes' }]], { notes: auction.notes });

    function handleSubmit() {
        axios
            .post(`/auction/updateNotes`, {
                auctionId: auction.id,
                notes: yup.getValues().notes,
            })
            .then((res) => {
                toast.success('Updated notes');
            })
            .catch(function (err) {
                toast.error(
                    err.response.data?.message || err.response?.statusText
                );
            });
    }

    return (
        <Box marginTop={3}>
            <CollapseContainer
                title="Notes"
                expandDefault={auction.notes !== null}
                backgroundColor="tintDark"
                color="dark"
                containerBackgroundColor="tint"
            >
                <Box padding={2}>
                    <Box backgroundColor="white">
                        <Field
                            id="notes"
                            yup={yup}
                            input={{
                                rows: 8,
                                hideLabel: true,
                            }}
                        />
                        <Button
                            fullWidth
                            variant="dark"
                            size="tiny"
                            onClick={handleSubmit}
                            sx={{ borderRadius: 0 }}
                        >
                            Update
                        </Button>
                    </Box>
                </Box>
            </CollapseContainer>
        </Box>
    );
}

function DepositButton({ title, variant = 'white', handleClick, condition }) {
    if (!condition) {
        return null;
    }

    return (
        <Button
            size="tiny"
            variant={variant}
            onClick={handleClick}
            sx={{ marginTop: 1, marginRight: 1 }}
        >
            {title}
        </Button>
    );
}

export default PurchaseTracker;

import { useState, useEffect, useRef } from 'react';
import { Box, Button, Collapse, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DynamicIcon from './DynamicIcon';
import Field from '../cms/Field';
import { useYup } from '../../scripts/cms';

function SearchBar({
    callback = () => {},
    delay = 600,
    length,
    count,
    placeholder = 'Search',
    backgroundColor = 'white',
    clearButton,
    hideCounter,
    sx,
    attributes = [],
}) {
    const [focused, setFocused] = useState(false);
    const timeout = useRef(null);

    // Attributes
    const [showAttributes, setShowAttributes] = useState(false);
    const yup = useYup(attributes, {});
    const query = yup.watch();

    useEffect(() => {
        if (JSON.stringify(query) !== '{}') {
            if (timeout.current) {
                clearTimeout(timeout.current);
            }

            timeout.current = setTimeout(() => {
                callback(query);
            }, delay);

            return () => {
                clearTimeout(timeout.current);
            };
        }
    }, [JSON.stringify(query)]);

    return (
        <Box width="100%">
            <Box display="flex" sx={sx}>
                <Box display="flex" position="relative" width="100%">
                    <Box
                        sx={{
                            borderRadius: 100,
                            backgroundColor: backgroundColor,
                            width: '100%',
                            paddingLeft: 2.5,
                            paddingRight: 12,
                            display: 'flex',
                            boxShadow:
                                '0 1px 1px rgba(0,0,0,0.05), 0 2px 2px rgba(0,0,0,0.05)',
                        }}
                        className={
                            focused || query?.search?.length > 0
                                ? 'input-focus'
                                : 'input-unfocused'
                        }
                    >
                        <DynamicIcon
                            icon="Search"
                            size={28}
                            sx={{ margin: 1.5, marginLeft: 0 }}
                            colour="inherit"
                        />
                        <Typography
                            fontSize={18}
                            component="input"
                            sx={{
                                paddingTop: 1.5,
                                paddingBottom: 1.5,
                                outline: 'none',
                                border: 'none',
                                width: '100%',
                            }}
                            placeholder={placeholder}
                            value={query?.search || ''}
                            onChange={(e) => {
                                yup.setValue('search', e.target.value);
                            }}
                            onFocus={() => setFocused(true)}
                            onBlur={() => setFocused(false)}
                            backgroundColor={backgroundColor}
                        />
                    </Box>
                    <Box
                        position="absolute"
                        display="flex"
                        top={1}
                        right={7}
                        height="100%"
                    >
                        {length > 0 && count > 0 && !hideCounter && (
                            <Typography
                                color="text.secondary"
                                marginTop="auto"
                                marginBottom="auto"
                                marginRight={1}
                            >
                                {length} / {count}
                            </Typography>
                        )}
                        {clearButton && (
                            <IconButton
                                onClick={() => yup.setValue('search', '')}
                                sx={{
                                    marginTop: 0.8,
                                    marginBottom: 'auto',
                                }}
                                disabled={
                                    !query?.search ||
                                    query?.search?.length === 0
                                }
                            >
                                <CloseIcon />
                            </IconButton>
                        )}
                    </Box>
                </Box>
                {attributes.length > 0 && (
                    <Box margin="auto" display="flex" marginLeft={1}>
                        <IconButton
                            onClick={() => setShowAttributes(!showAttributes)}
                        >
                            <DynamicIcon
                                size={28}
                                icon="Tune"
                                colour="inherit"
                            />
                        </IconButton>
                    </Box>
                )}
            </Box>
            <Collapse
                style={{
                    flexDirection: 'column',
                    display: 'flex',
                    overflow: 'auto',
                }}
                in={showAttributes}
                timeout="auto"
                unmountOnExit
                className="hidden-scrollbar"
                onExited={() => setShowAttributes(false)}
            >
                <AttributeFilter yup={yup} attributes={attributes} />
            </Collapse>
        </Box>
    );
}

function AttributeFilter({ yup, attributes }) {
    return (
        <Box gap={1} display="flex">
            {attributes.map((attribute, i) => (
                <Field
                    key={i}
                    yup={yup}
                    id={attribute.id}
                    input={{ optional: true, ...attribute }}
                />
            ))}
        </Box>
    );
}

export default SearchBar;

import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/material';

function Loading({ height, colour = '#0060ba' }) {
    return (
        <Box height={height} position={height > 0 ? 'relative' : 'initial'}>
            <CircularProgress
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: `calc(50% - 20px)`,
                    color: colour,
                }}
            />
        </Box>
    );
}

export default Loading;

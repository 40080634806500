import React, { useState, useEffect, createContext } from 'react';
import { socket } from '../../scripts/socket';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';
import { identify } from '../../scripts/segment';

const AccountContext = createContext();

const Account = (props) => {
    const session = useSessionContext();

    var _user = localStorage.getItem('user') || {};
    var _signedIn = localStorage.getItem('signedIn') || false;

    try {
        _user = JSON.parse(_user);
        _signedIn = JSON.parse(_signedIn);
    } catch (err) {}

    const [user, setUser] = useState(_user);
    const [signedIn, setSignedIn] = useState(_signedIn);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!session.loading) {
            const {
                username,
                verified,
                role,
                email,
                contactName,
                developerId,
                brokerId,
                jwt,
            } = session.accessTokenPayload;

            const signedIn =
                session.doesSessionExist && session.invalidClaims.length === 0;

            const user = {
                uuid: session.userId,
                username,
                verified,
                role,
                developerId,
                brokerId,
            };
            // Set user info
            setUser(user);
            setSignedIn(signedIn);

            // Store user info for instant access between reloads
            localStorage.setItem('user', JSON.stringify(user));
            localStorage.setItem('signedIn', JSON.stringify(signedIn));

            if (signedIn) {
                // Segment indentify
                identify(session.userId, {
                    username,
                    email,
                    contactName,
                });

                // Tell socket who you are
                socket.emit('set-username', jwt);
            }
        }

        setLoading(session.loading);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [session.loading, session.accessTokenPayload?.jwt]);

    const logout = () => {};

    const verify = () => {
        const _user = {
            ...user,
            verified: true,
        };
        setUser(_user);
        localStorage.setItem('user', JSON.stringify(_user));
    };

    function isAdmin() {
        return user.role === 'Admin';
    }

    return (
        <AccountContext.Provider
            value={{
                user,
                signedIn,
                loading,
                logout,
                verify,
                isAdmin,
            }}
        >
            {props.children}
        </AccountContext.Provider>
    );
};

export { Account, AccountContext };

import { Box, List } from '@mui/material';
import SearchBar from '../common/SearchBar';
import { useEffect, useRef, useState } from 'react';
import Loading from '../common/Loading';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import axios from 'axios';
import NoData from './NoData';

function DataScroller({
    api = '',
    body = {},
    placeholder = 'Search',
    limit = 10,
    Child,
    childParams = {},
    Parent,
    parentParams = {},
    noData,
    attributes = [],
}) {
    const [data, setData] = useState([]);
    const [count, setCount] = useState(0);
    const [query, setQuery] = useState(null);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [offset, setOffset] = useState(0);
    const [prev, setPrev] = useState({});

    // Keep track of how many requests have been made (used to discard stale queries)
    const [num, setNum] = useState(0);
    const numRef = useRef(num);

    const hasNextPage = data.length < count;

    const [sentryRef] = useInfiniteScroll({
        loading,
        hasNextPage,
        onLoadMore: () => getData(page + 1),
        rootMargin: '0px 0px 400px 0px',
    });

    useEffect(() => {
        getData(1);
    }, [query]);

    useEffect(() => {
        numRef.current = num;
    }, [num]);

    function getData(page) {
        setLoading(true);

        const curr = numRef.current + 1;
        setNum(curr);

        axios
            .post(api, {
                limit,
                page,
                query,
                offset: page === 1 ? 0 : offset,
                ...body,
            })
            .then((res) => {
                // Only save this result if this is the most recent request the user made
                if (curr === numRef.current) {
                    const { rows, count, offset } = res.data;

                    // Determine whether query is an extension of the existing data, or something new
                    const append =
                        (!prev.page || prev.page === page - 1) &&
                        (!prev.query || prev.query === query);

                    // Combine new listings into existing list
                    let result = append ? [...data, ...rows] : rows;

                    // Is this the only item? Auto-expand dropdown
                    if (result.length === 1) {
                        result[0].expand = true;
                    }

                    // Re-render list
                    setData(result);
                    setCount(count);
                    setPage(page);
                    setOffset(offset);
                    setPrev({ page, query });
                }
            })
            .catch(function () {})
            .finally(function () {
                setLoading(false);
            });
    }

    return (
        <Box>
            <SearchBar
                callback={(query) => setQuery(query)}
                length={data.length}
                count={count}
                hideCounter={offset > 0}
                sx={{ marginBottom: 1 }}
                placeholder={placeholder}
                clearButton={true}
                attributes={attributes}
            />
            <Container
                data={data}
                Child={Child}
                childParams={childParams}
                Parent={Parent}
                parentParams={parentParams}
            />

            {!loading && data.length === 0 && <NoData description={noData} />}

            {(loading || hasNextPage) && (
                <Box ref={sentryRef}>
                    <Loading height={400} colour="tintDarker" />
                </Box>
            )}
        </Box>
    );
}

function Container({ data, Child, childParams, Parent, parentParams }) {
    // Custom Renderer
    if (Parent) {
        return <Parent items={data} Component={Child} {...parentParams} />;
    }

    // Standard Renderer
    return (
        <List>
            {data?.map((item) => (
                <Child key={item.id} item={item} {...childParams} />
            ))}
        </List>
    );
}

export default DataScroller;

import { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Box,
    Button,
    Collapse,
    IconButton,
    Tooltip,
    Typography,
} from '@mui/material';
import DynamicIcon from '../../components/common/DynamicIcon';
import moment from 'moment';
import { toast } from 'react-toastify';
import CollapseContainer from './CollapseContainer';
import PopupForm from './PopupForm';

function Envelope({
    auctionId,
    title,
    envelope,
    admin,
    openDocusign = true,
    user,
    broker,
    developer,
}) {
    const [expand, setExpand] = useState(envelope?.status === 'sent');

    const [showCancelPending, setShowCancelPending] = useState(false);
    function cancelPending(type) {
        axios
            .post(`/docusign/cancelPendingEnvelope`, {
                type,
                auctionId,
            })
            .then((res) => {
                toast.success('Pending envelope cancelled.');
            })
            .catch(function (err) {
                toast.error(
                    err?.response?.data?.message ||
                        err?.message ||
                        'Failed to cancel pending envelope.'
                );
            })
            .finally(() => {
                setShowCancelPending(false);
            });
    }

    if (!envelope) {
        return null;
    }

    return (
        <Box>
            <CollapseContainer
                title={
                    <Box flex={1} display="flex">
                        <DocusignStatus
                            status={envelope.status}
                            tooltip={envelope.id}
                            envelopeId={envelope.id}
                            openDocusign={openDocusign}
                            pending={envelope.pending}
                            admin={admin}
                            broker={broker}
                            developer={developer}
                            handleCancelPending={() =>
                                setShowCancelPending(true)
                            }
                        />
                        <Box
                            display="flex"
                            marginTop="auto"
                            marginBottom="auto"
                            onClick={() => setExpand(!expand)}
                            className="clickable"
                        >
                            <Typography fontWeight="bold">{title}</Typography>
                        </Box>
                    </Box>
                }
                backgroundColor="tint"
                color="dark"
                containerBackgroundColor="tint"
                boxShadow={null}
                expandDefault={envelope?.status === 'sent'}
                innerSx={{ paddingBottom: 1 }}
                sx={{ marginBottom: 1 }}
                lock={envelope?.pending}
            >
                <Box borderLeft="1px solid #ccc" marginLeft={2}>
                    {envelope?.recipients?.map((recipient) => (
                        <Box display="flex" key={recipient.id} marginLeft={1}>
                            <DocusignStatus
                                status={recipient.status}
                                number={recipient.routingOrder}
                                tooltip={
                                    recipient.signDate
                                        ? `Signed: ${moment(
                                              recipient.signDate
                                          ).format('MMM DD, YYYY @ h:mm A')}`
                                        : recipient.status
                                }
                                admin={admin}
                                broker={broker}
                                developer={developer}
                            />
                            <Typography>{recipient.name}</Typography>
                        </Box>
                    ))}
                </Box>
            </CollapseContainer>
            <PopupForm
                title="Cancel Pending Envelope?"
                body={
                    <div>
                        WARNING: This should only be used if necessary, and may
                        result in duplicate envelopes if used incorrectly.
                    </div>
                }
                action={() => cancelPending(envelope.type)}
                show={showCancelPending}
                setShow={setShowCancelPending}
            />
        </Box>
    );
}

function DocusignStatus({
    envelopeId,
    status,
    number,
    tooltip,
    openDocusign,
    pending,
    admin,
    broker,
    developer,
    handleCancelPending = () => {},
}) {
    let colour = 'status.disabled';
    let icon = 'QuestionMark';

    tooltip = tooltip || envelopeId || status;

    switch (status) {
        // Completed
        case 'completed':
            colour = 'status.success';
            icon = 'CheckCircle';
            break;

        // Delivered (Copy of Sent)
        case 'delivered':
            colour = 'status.info';
            icon = number ? (admin ? `Filter${number}` : 'Edit') : 'Assignment';
            break;

        // Sent
        case 'sent':
            colour = 'status.info';
            icon = number ? (admin ? `Filter${number}` : 'Edit') : 'Assignment';
            break;

        // Created (Blocked by others)
        case 'created':
            colour = 'status.disabled';
            icon = number ? (admin ? `Filter${number}` : 'Edit') : 'Pending';
            break;

        // Voided
        case 'voided':
            colour = 'status.danger';
            icon = 'Block';
            break;

        // Default
        default:
            // Pending (Envelope creation in-progress)
            if (pending) {
                colour = 'status.disable';
                icon = 'Autorenew';
                if (admin) {
                    tooltip = 'Cancel Pending Envelope';
                }
            }
            break;
    }

    return (
        <Tooltip
            title={
                tooltip && (
                    <Box display="flex">
                        {openDocusign && !pending && (
                            <DynamicIcon
                                icon="Launch"
                                size={15}
                                sx={{
                                    padding: 0,
                                    marginTop: 'auto',
                                    marginBottom: 'auto',
                                    marginRight: 0.5,
                                }}
                            />
                        )}
                        <Typography
                            fontSize={13}
                            marginTop="auto"
                            marginBottom="auto"
                        >
                            {tooltip}
                        </Typography>
                    </Box>
                )
            }
            placement="left"
        >
            <IconButton
                sx={{ marginRight: 1, padding: 0 }}
                onClick={(e) => {
                    // Stop parent from receiving click
                    e.stopPropagation();

                    // Cancel pending agreement
                    if (pending) {
                        if (admin) {
                            handleCancelPending();
                        }
                    }
                    // Open docusign file
                    else if (openDocusign) {
                        window.open(
                            `https://${
                                process.env.REACT_APP_ENV === 'production'
                                    ? 'apps'
                                    : 'apps-d'
                            }.docusign.com/send/documents/details/${envelopeId}`,
                            '_blank'
                        );
                    }
                }}
            >
                <DynamicIcon icon={icon} colour={colour} />
            </IconButton>
        </Tooltip>
    );
}

export default Envelope;

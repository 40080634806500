import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import LeftIcon from '@mui/icons-material/ArrowBackIos';
import RightIcon from '@mui/icons-material/ArrowForwardIos';
import MapIcon from '@mui/icons-material/Map';
import FloorplanIcon from '@mui/icons-material/Dashboard';

import WatchButton from './WatchButton';
import GalleryMedia from './GalleryMedia';
import Timer, { renderUntilDone, renderWhenDone } from '../common/Timer';
import mediaTypes from '../../constants/mediaTypes';
import { Link } from 'react-router-dom';

const SelectedImage = ({
    auction,
    setFullscreenMedia,
    images,
    selectedIndex,
    setSelectedIndex,
    setMapShow,
    handleOpen,
}) => {
    const floorplan = auction.property.model.floorplan;

    const clickArrow = (direction) => {
        const length = images.length;
        if (length > 1) {
            let i = selectedIndex + direction;
            if (i < 0) {
                i = length - 1;
            } else if (i >= length) {
                i = 0;
            }

            setSelectedIndex(i);
        }
    };

    return (
        <div className="auction-image-container">
            <div className="auction-arrow auction-arrow-left">
                <div className="btn" onClick={() => clickArrow(-1)}>
                    <LeftIcon style={{ color: '#333' }} />
                </div>
            </div>
            <div className="auction-arrow auction-arrow-right">
                <div className="btn" onClick={() => clickArrow(1)}>
                    <RightIcon style={{ color: '#333', marginLeft: '-3px' }} />
                </div>
            </div>
            {images &&
                images.length > 0 &&
                images[selectedIndex].mediaTypeId === mediaTypes.IMAGE && (
                    <div>
                        <Banner auction={auction} />
                        <Box
                            backgroundColor="white"
                            sx={{ display: 'flex' }}
                            position="absolute"
                            bottom={0}
                            right={0}
                        >
                            {floorplan && (
                                <MenuButton
                                    title="FLOOR PLAN"
                                    Icon={FloorplanIcon}
                                    onClick={() =>
                                        setFullscreenMedia(floorplan)
                                    }
                                    sx={{ marginRight: '1px' }}
                                />
                            )}
                            <MenuButton
                                title="MAP VIEW"
                                onClick={() => setMapShow(true)}
                                Icon={MapIcon}
                            />
                        </Box>
                    </div>
                )}
            {images.length > selectedIndex && (
                <GalleryMedia
                    media={images[selectedIndex]}
                    handleOpen={handleOpen}
                />
            )}
        </div>
    );
};

const MenuButton = ({ title, Icon, onClick, sx }) => {
    return (
        <Box
            display="flex"
            flexDirection="column"
            backgroundColor="dark"
            onClick={onClick}
            sx={{
                height: { xs: 70, md: 120 },
                width: { xs: 70, md: 110 },
                ...sx,
            }}
            style={{ cursor: 'pointer' }}
        >
            <Typography
                fontSize={13}
                letterSpacing={1}
                sx={{
                    paddingTop: { xs: 2, md: 2 },
                    display: { xs: 'none', md: 'block' },
                }}
                textAlign="center"
                color="white"
                fontWeight="light"
                marginBottom={-0.5}
            >
                {title}
            </Typography>
            <Box
                flex={1}
                color="white"
                display="flex"
                sx={{ fontSize: { xs: 45, md: 60 } }}
            >
                <Icon style={{ margin: 'auto' }} fontSize="inherit" />
            </Box>
        </Box>
    );
};

const BannerContent = ({ auction, text }) => {
    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            position="absolute"
            top={0}
            left={0}
            width="100%"
            backgroundColor="#222222dd"
            sx={{
                paddingTop: { xs: 2.5, md: 4, lg: 5 },
                paddingBottom: { xs: 2.5, md: 4, lg: 5 },
            }}
        >
            <Typography
                sx={{
                    fontSize: { xs: 20, md: 23, lg: 25 },
                    paddingBottom: { xs: 2, md: 2, lg: 3 },
                }}
                color="white"
                fontWeight="medium"
                textTransform="uppercase"
                letterSpacing={1.5}
            >
                {text}
            </Typography>
            <Box display="flex" gap={2}>
                <WatchButton
                    groupId={auction.auctionHistory.auctionHistoryGroup.id}
                    watching={auction.watching}
                    scaleSize
                />
                {auction.slug !== auction.currentAuctionSlug && (
                    <Button
                        variant="white"
                        component={Link}
                        to={`/listing/${auction.currentAuctionSlug}`}
                    >
                        <Typography
                            fontSize={16}
                            fontWeight="bold"
                            letterSpacing={1.25}
                            fontFamily="urbanist"
                        >
                            View New Listing
                        </Typography>
                    </Button>
                )}
            </Box>
        </Box>
    );
};

const Banner = ({ auction }) => {
    if (auction.disabled) {
        return <BannerContent text="Listing Disabled" auction={auction} />;
    }

    if (!auction.visible) {
        return null;
    }

    return (
        <>
            <Timer endDate={auction.startDate} renderer={renderUntilDone}>
                <BannerContent text="Coming Soon" auction={auction} />
            </Timer>
            <Timer endDate={auction.endDate} renderer={renderWhenDone}>
                <BannerContent
                    text={auction.sold ? 'Listing Sold' : 'Listing Unsold'}
                    auction={auction}
                />
            </Timer>
            <Timer endDate={auction.startDate} renderer={renderWhenDone}>
                <Timer endDate={auction.endDate} renderer={renderUntilDone}>
                    <Box
                        position="absolute"
                        top={0}
                        left={0}
                        sx={{ margin: { xs: 2, md: 4, lg: 5 } }}
                    >
                        <WatchButton
                            groupId={
                                auction.auctionHistory.auctionHistoryGroup.id
                            }
                            watching={auction.watching}
                            xsSize="small"
                            scaleSize
                        />
                    </Box>
                </Timer>
            </Timer>
        </>
    );
};

export default SelectedImage;

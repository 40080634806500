import { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import NotFound from './NotFound';
import {
    Box,
    Button,
    Checkbox,
    Step,
    StepContent,
    StepLabel,
    Stepper,
    Typography,
} from '@mui/material';
import Footer from '../components/common/Footer';

import { formatText, formatTypes, setTitle } from '../scripts/style';
import PaddedBox from '../components/common/PaddedBox';
import Loading from '../components/common/Loading';
import DynamicIcon from '../components/common/DynamicIcon';
import ClientAccountCard from '../components/payment/ClientAccountCard';
import AddBankButton from '../components/payment/AddBankButton';
import { AccountContext } from '../components/supertokens/Account';
import IDVModal from '../components/modal/IDVModal';
import PopupForm from '../components/common/PopupForm';
import { toast } from 'react-toastify';
import DepositSchedule from '../components/payment/DepositSchedule';
import StatusModal from '../components/modal/StatusModal';
import modalStatuses from '../constants/modalStatuses';
import Envelope from '../components/common/Envelope';

function PurchasePage() {
    setTitle('Purchase Summary');

    const { slug } = useParams();
    const [data, setData] = useState();
    const [valid, setValid] = useState(true);
    const [activeStep, setActiveStep] = useState({});

    useEffect(() => {
        refresh();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [slug]);

    function refresh() {
        // Empty current data
        setData();

        // Get the auction data
        axios
            .get(`/postPurchase/get/${slug}`)
            .then((res) => {
                setData(res.data);
            })
            .catch(function () {
                setValid(false);
            });
    }

    // Loading
    if (!data && valid) {
        return <Loading />;
    }

    // Invalid
    if (!valid) {
        return <NotFound />;
    }

    const steps = [
        {
            label: 'Confirm Your Purchase',
            descriptions: [
                `We need some additional information in order to confirm your purchase details. It's important that you complete this step within 24 hours of your purchase to help ensure you successfully secure your unit and can add all of your desired options.`,
            ],
            children: <PostPurchaseInfo data={data} />,
            completed: data.purchase !== null,
        },
        {
            label: 'Verify your Identity',
            descriptions: [
                `All purchasers must verify their identity in order to fulfill FINTRAC requirements when you buy or sell real estate in Canada. Our automatic process easily and securely verifies your identity in minutes to help fulfill this requirement. You, as the primary buyer, can complete your verification below and any additional buyers will be sent an email with instructions on how to compete their verification.`,
                `Please ensure all purchasers complete their verification within 24 hours of purchase.`,
            ],
            children: <IDVInfo data={data} />,
            completed: data.purchase && data.purchase?.idvCompleted === true,
            disabled: !data.purchase,
        },
        {
            label: 'Document Signing',
            descriptions: [
                `Once all purchasers have verified their identity, the purchase agreement will be sent out via DocuSign for all parties to complete. Once all purchasers have signed the purchase agreement it will be sent to the developer for their signature and final acceptance.`,
            ],
            children: <DocusignInfo data={data} />,
            completed:
                data.purchase &&
                data.purchase?.envelope?.signDate &&
                !data.purchase.activeDocusign,
            disabled:
                (!data.purchase || data.purchase?.idvCompleted !== true) &&
                !data.purchase?.envelope,
        },
        {
            label: 'Payment Details',
            descriptions: [
                `Once the purchase agreement has been signed by all parties, you'll be able to view your deposit schedule here and securely connect your bank account to enable payment of your deposits via automatic pre-authorized debit.`,
            ],
            children: <BankInfo data={data} refresh={refresh} />,
            completed:
                data.purchase &&
                (data.purchase.clientAccountId !== null ||
                    data.purchase.manualPaymentOptionId !== null),
            disabled: !data.purchase || !data.purchase.envelope?.signDate,
        },
    ];

    return (
        <Box backgroundColor="tint">
            <PaddedBox
                left={{ xs: 0, md: '8vw', lg: '12vw' }}
                right={{ xs: 0, md: '8vw', lg: '12vw' }}
                bottom={{ xs: 4, md: 0 }}
            >
                <Box
                    maxWidth={{ xs: '100%', md: 800 }}
                    display="flex"
                    margin="auto"
                    padding={{ xs: 0, md: 6 }}
                    backgroundColor="tint"
                    minHeight="calc(100vh - 70px)"
                >
                    <Box
                        marginLeft="auto"
                        marginRight="auto"
                        marginBottom="auto"
                        backgroundColor="white"
                        padding={{ xs: 2, md: 10 }}
                        paddingBottom={4}
                        position="relative"
                        width="100%"
                        borderRadius={4}
                    >
                        <Typography
                            fontWeight="bold"
                            textAlign="center"
                            fontSize={25}
                            marginBottom={5}
                        >
                            Your Purchase Tasks
                        </Typography>
                        <Stepper nonLinear orientation="vertical">
                            {steps.map((step, i) => (
                                <Step
                                    key={i}
                                    completed={!!step.completed}
                                    active={
                                        activeStep[i] === undefined
                                            ? (!step.disabled &&
                                                  !step.completed) ||
                                              (step.completed &&
                                                  i === steps.length - 1)
                                            : activeStep[i]
                                    }
                                >
                                    <StepLabel
                                        onClick={() =>
                                            setActiveStep({
                                                ...activeStep,
                                                [i]: !activeStep[i],
                                            })
                                        }
                                        className="clickable"
                                        icon={
                                            <DynamicIcon
                                                icon={
                                                    !!step.completed
                                                        ? 'CheckCircle'
                                                        : !!step.disabled
                                                        ? 'Pending'
                                                        : 'Error'
                                                }
                                                colour={
                                                    !!step.completed
                                                        ? 'status.success'
                                                        : !step.disabled
                                                        ? 'status.warning'
                                                        : 'status.disabled'
                                                }
                                                size={35}
                                            />
                                        }
                                    >
                                        {step.label}
                                    </StepLabel>
                                    <StepContent>
                                        {!step.completed &&
                                            step.descriptions.map(
                                                (description, j) => (
                                                    <Typography
                                                        marginBottom={2}
                                                        key={j}
                                                    >
                                                        {description}
                                                    </Typography>
                                                )
                                            )}
                                        {step.children}
                                    </StepContent>
                                </Step>
                            ))}
                        </Stepper>
                    </Box>
                </Box>
            </PaddedBox>
            <Footer />
        </Box>
    );
}

function PostPurchaseInfo({ data }) {
    const completed = data.purchase !== null;
    return (
        <Box>
            <Button
                type="border"
                variant="white"
                fullWidth
                display="flex"
                justifyContent="center"
                component={Link}
                to={`/post-purchase/${data.auction.slug}`}
                size="small"
            >
                <DynamicIcon
                    icon={completed ? 'CheckCircle' : 'Cancel'}
                    colour={completed ? 'status.success' : 'status.danger'}
                />
                <Typography color="dark" marginLeft={1}>
                    Post-Purchase Form
                </Typography>
            </Button>
        </Box>
    );
}

function BankInfo({ data, refresh }) {
    const [manual, setManual] = useState(false);

    const [manualOption, setManualOption] = useState();
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [showPreConfirmation, setShowPreConfirmation] = useState(false);
    const [manualOptions, setManualOptions] = useState([]);

    const [statusModalValues, setStatusModalValues] = useState();
    const [showStatusModal, setShowStatusModal] = useState(false);

    const [agreement, setAgreement] = useState(false);

    // Get the list of manual payment options
    useEffect(() => {
        if (
            !data.purchase?.manualPaymentOption &&
            !data.purchase?.clientAccount
        ) {
            axios
                .get(`/postPurchase/getManualPaymentOptions/${data.auction.id}`)
                .then((res) => {
                    setManualOptions(res.data);
                })
                .catch(function () {});
        }
    }, []);

    // Set the manual payment method for this purchase
    function handleManualSelection(id) {
        axios
            .post(`/postPurchase/setManualPaymentOption`, {
                manualOptionId: id,
                purchaseId: data.purchase?.id,
            })
            .then((res) => {
                refresh();
            })
            .catch(function (err) {
                setShowConfirmation(false);
                toast.error(
                    err?.response?.data?.message ||
                        'Unable to select payment option.'
                );
            });
    }
    function handleSuccessfulBank() {
        setStatusModalValues({
            status: modalStatuses.SUCCESS,
            title: 'Congratulations!',
            body: `You've completed all the required steps to complete you purchase at this time. Any additional requirements that come up between now and the completion of your unit will be sent to you via email and posted here so you can complete them with ease.`,
            action: () => refresh(),
        });
        setShowStatusModal(true);
    }

    function handleIncompleteSelection() {
        setStatusModalValues({
            status: modalStatuses.INFO,
            title: 'Almost there!',
            body: `Please select your bank account to finalize your purchase.`,
            action: () => refresh(),
        });
        setShowStatusModal(true);
    }

    // Exit if not ready to load yet
    if (!data.purchase) {
        return null;
    }

    // Deposit schedule unavailable
    if (!data.purchase.envelope?.signDate) {
        return (
            <Box>
                <Typography>
                    Payment details can be handled once the purchase agreement
                    has been signed by all parties.
                </Typography>
            </Box>
        );
    }

    // Payment method OK
    if (data.purchase.clientAccount) {
        return (
            <Box>
                <DepositSchedule
                    depositSchedule={data.depositSchedule}
                    firmDate={data.purchase.firmDate}
                />
                <Typography></Typography>
                <ClientAccountCard
                    clientAccount={data.purchase.clientAccount}
                    onSubmit={refresh}
                    borderColour="#03C419"
                />
                <ChangeBankAccount />
            </Box>
        );
    }

    // Manual payment chosen
    if (data.purchase.manualPaymentOption) {
        return (
            <Box>
                <DepositSchedule
                    depositSchedule={data.depositSchedule}
                    firmDate={data.purchase.firmDate}
                />
                <ManualPaymentOptionCard
                    option={data.purchase.manualPaymentOption}
                />
                <Typography marginTop={1}>
                    You have selected a manual payment method. We will shortly
                    contact you via email to confirm the payment details and
                    instructions.
                </Typography>
            </Box>
        );
    }

    // Choose default payment method
    return (
        <Box>
            <DepositSchedule
                depositSchedule={data.depositSchedule}
                firmDate={data.purchase.firmDate}
            />
            {manual && manualOptions.length > 0 && (
                <Box rowGap={1} display="flex" flexDirection="column">
                    {manualOptions.map((option) => (
                        <Button
                            key={option.id}
                            variant="white"
                            type="border"
                            size="small"
                            display="block"
                            onClick={() => {
                                setManualOption(option);
                                setShowConfirmation(true);
                            }}
                        >
                            {`Pay by ${option.title} - ${formatText(
                                option.price,
                                formatTypes.MONEY
                            )}`}
                        </Button>
                    ))}
                    <PopupForm
                        title={`Pay by ${manualOption?.title}?`}
                        body={
                            <div>
                                <div>
                                    Are you sure you want to choose this manual
                                    payment option? All future payments will be
                                    handled manually.
                                </div>
                                <div style={{ marginTop: 8 }}>
                                    {`There will be a fee of ${formatText(
                                        manualOption?.price,
                                        formatTypes.MONEY
                                    )}.`}
                                </div>
                            </div>
                        }
                        action={() => handleManualSelection(manualOption.id)}
                        show={showConfirmation && manualOption !== null}
                        setShow={setShowConfirmation}
                    />
                </Box>
            )}
            {!manual && (
                <>
                    <Typography fontWeight="medium" marginBottom={1}>
                        Connect an Account
                    </Typography>

                    <Box display="flex" marginBottom={1}>
                        <Box
                            marginTop="auto"
                            marginBottom="auto"
                            paddingRight={1}
                        >
                            <Checkbox
                                onClick={(e) => setAgreement(e.target.checked)}
                                checked={agreement}
                            />
                        </Box>
                        <Typography marginTop="auto" marginBottom="auto">
                            I agree to the{' '}
                            <a
                                href="https://cdn.upside.re/the-platform/Pre-Authorized%20Debit%20(PAD)%20Agreement.pdf"
                                target="_blank"
                            >
                                Terms of the Pre-Authorized Debit (PAD)
                                Agreement
                            </a>{' '}
                            included within the Agreement of Purchase and Sale
                            for this transaction.
                        </Typography>
                    </Box>

                    <Box marginBottom={1}>
                        {data.clientAccounts.map((clientAccount, i) => (
                            <ClientAccountCard
                                key={i}
                                clientAccount={clientAccount}
                                purchaseId={data.purchase.id}
                                onSubmit={handleSuccessfulBank}
                                disabled={!agreement}
                            />
                        ))}
                    </Box>

                    <AddBankButton
                        businessId={data.purchase?.business?.id}
                        handleExit={(zumId) => {
                            setTimeout(() => {
                                axios
                                    .post(`/postPurchase/selectBank`, {
                                        zumId: zumId,
                                        purchaseId: data.purchase.id,
                                    })
                                    .then((res) => {
                                        handleSuccessfulBank();
                                    })
                                    .catch(function (err) {
                                        handleIncompleteSelection();
                                    });
                            }, 1000);
                        }}
                        disabled={!agreement}
                    />
                </>
            )}
            {/* {manualOptions.length > 0 && (
                <Typography
                    component={Link}
                    fontSize={14}
                    sx={{ marginTop: 2, display: 'block' }}
                    underline="hover"
                    color="dark"
                    className="clickable"
                    onClick={() =>
                        manual
                            ? setManual(!manual)
                            : setShowPreConfirmation(true)
                    }
                >
                    {manual
                        ? 'Connect your bank account for automatic pre-authorized debit payments'
                        : 'Pay manually by another method (extra charges may apply)'}
                </Typography>
            )} */}
            <PopupForm
                title={`Make Payments Manually?`}
                body={
                    <div>
                        <div>
                            Are you sure you don't want to connect a bank
                            account to handle your deposit payments
                            automatically? It's easier, secure and may avoid
                            other charges incurred by manual methods such as
                            cheque.
                        </div>
                    </div>
                }
                action={() => {
                    setManual(true);
                    setShowPreConfirmation(false);
                }}
                show={showPreConfirmation}
                setShow={setShowPreConfirmation}
            />
            <StatusModal
                show={showStatusModal}
                onClose={() => setShowStatusModal(false)}
                values={statusModalValues}
            />
        </Box>
    );
}

function ChangeBankAccount() {
    return (
        <Box display="flex" marginTop={1}>
            <Typography fontSize={15}>Change bank account?</Typography>
            <Typography
                fontSize={15}
                paddingLeft={1}
                component={Link}
                underline="hover"
                fontWeight="bold"
                className="clickable"
                to="/contact-us"
            >
                Contact Support
            </Typography>
        </Box>
    );
}

function IDVInfo({ data }) {
    const account = useContext(AccountContext);
    const [showIDVModal, setShowIDVModal] = useState(false);

    if (!data.purchase) {
        return null;
    }

    return (
        <Box>
            {data.purchase.signatures.map((signature) => {
                const verified = signature.idv?.status === 'success';
                return (
                    <Box key={signature.id} display="flex" gap={1}>
                        <DynamicIcon
                            icon={verified ? 'CheckCircle' : 'Cancel'}
                            colour={
                                verified ? 'status.success' : 'status.danger'
                            }
                        />
                        <Typography>{`${
                            signature.idv?.firstName || signature.firstName
                        } ${
                            signature.idv?.lastName || signature.lastName
                        }`}</Typography>
                        {!verified &&
                            signature.user?.username ===
                                account.user.username && (
                                <Button
                                    size="tiny"
                                    variant="white"
                                    type="border"
                                    sx={{ padding: 0 }}
                                    onClick={() => setShowIDVModal(true)}
                                >
                                    Verify
                                </Button>
                            )}
                    </Box>
                );
            })}
            <IDVModal
                show={showIDVModal}
                onClose={() => setShowIDVModal(false)}
                callback={() => window.location.reload()}
            />
        </Box>
    );
}

function DocusignInfo({ data }) {
    if (!data.purchase?.envelope) {
        return null;
    }

    const { amendments } = data.purchase;

    return (
        <Box>
            <Envelope
                title="Agreement of Purchase and Sale"
                envelope={data.purchase?.envelope}
                user={true}
            />
            {amendments.map((amendment) => (
                <Envelope
                    key={amendment.id}
                    title={`Amendment: ${amendment.amendmentType.title}`}
                    envelope={amendment.envelope}
                    user={true}
                />
            ))}
        </Box>
    );
}

function ManualPaymentOptionCard({ option }) {
    // Exit if data wasn't provided
    if (!option) {
        return null;
    }

    return (
        <Box
            padding={1}
            paddingLeft={2.5}
            paddingRight={2.5}
            border="1px solid #03C419"
            marginBottom={1}
            display="inline-block"
            borderRadius={100}
        >
            <Typography marginTop="auto" marginBottom="auto">
                {`Pay by ${option.title}`}
            </Typography>
        </Box>
    );
}

export default PurchasePage;

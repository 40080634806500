import React, { useState, useEffect, useContext } from 'react';
import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import { Nav, Navbar } from 'react-bootstrap';
import {
    Routes,
    Route,
    useParams,
    NavLink,
    useLocation,
} from 'react-router-dom';
import Avatar from '../components/user/Avatar';
import NotFound from './NotFound';
import './User.css';
import Footer from '../components/common/Footer';

import MyAccount from '../components/user/MyAccount';
import Preferences from '../components/user/Preferences';
import BidsAndOffers from '../components/user/BidsAndOffers';
import Following from '../components/user/Following';
import Comments from '../components/user/Comments';
import Listings from '../components/user/Listings';
import Notifications from '../components/user/Notifications';
import axios from 'axios';
import Loading from '../components/common/Loading';
import RequireUser from '../components/routing/RequireUser';
import { AccountContext } from '../components/supertokens/Account';
import { setTitle } from '../scripts/style';
import PaddedBox from '../components/common/PaddedBox';

import { styled } from '@mui/material/styles';
import UserBadges from '../components/common/UserBadges';
import Business from '../components/user/Business';

function User() {
    const account = useContext(AccountContext);

    const { username } = useParams();
    const location = useLocation();

    const isSelf = account.user.username === username;

    const [user, setUser] = useState();
    const [loading, setLoading] = useState(true);
    const [valid, setValid] = useState(true);

    setTitle(username);

    const AVATAR_SIZE = 100;

    useEffect(() => {
        axios
            .get(`/user/getPublic/${username}`)
            .then((res) => {
                setUser(res.data);
                setLoading(false);
            })
            .catch(function () {
                setLoading(false);
                setValid(false);
            });
    }, [username]);

    const Header = () => {
        return (
            <Box
                sx={{
                    paddingTop: { xs: 3, md: '8vh' },
                    paddingBottom: { xs: 1, md: '4vh' },
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <div style={{ width: AVATAR_SIZE, height: AVATAR_SIZE }}>
                    <Avatar username={username} lg />
                </div>
                <Box display="flex">
                    <Typography
                        marginTop={2}
                        fontSize={26}
                        fontWeight="bold"
                        letterSpacing={1}
                        fontFamily="urbanist"
                    >
                        {`@${username}`}
                    </Typography>
                    <Box marginTop="auto" marginBottom={0.75} marginLeft={0.5}>
                        <UserBadges username={username} height={30} />
                    </Box>
                </Box>
            </Box>
        );
    };

    const Path = ({ name, path }) => {
        return (
            <Grid
                item
                xs={6}
                md={12}
                lg={12}
                display="flex"
                sx={{
                    margin: { xs: '10px auto', md: '1.5vh' },
                    marginLeft: { md: 0 },
                    marginRight: { md: 0 },
                    justifyContent: { xs: 'center', md: 'left' },
                }}
            >
                <NavLink
                    className={({ isActive }) =>
                        isActive ? 'user-active user-path' : 'user-path'
                    }
                    to={`/user/${username}/${path}`}
                >
                    <Typography
                        fontSize={18}
                        fontWeight="inherit"
                        letterSpacing={1}
                        fontFamily="urbanist"
                        textAlign={{ xs: 'center', md: 'left' }}
                    >
                        {name}
                    </Typography>
                </NavLink>
            </Grid>
        );
    };

    const xs = useMediaQuery((theme) => theme.breakpoints.down('md'));

    const Navigation = ({ user }) => {
        return (
            <Navbar>
                <Grid
                    component={Nav}
                    activeKey={location.pathname}
                    className="user-path-container"
                    container
                    paddingLeft={{
                        xs: 0,
                        md: `calc(50% - ${AVATAR_SIZE / 2}px)`,
                    }}
                >
                    {isSelf && <Path name="My Account" path="account" />}
                    {isSelf && <Path name="Business" path="business" />}
                    {isSelf && (
                        <Path name="Email Preferences" path="preferences" />
                    )}
                    {/* {user.developer && <Path name="Listings" path="listings" />} */}
                    {isSelf && <Path name="Following" path="following" />}
                    <Path name="My Purchases" path="purchases" />
                    {/* <Path name="Comments" path="comments" /> */}
                    {isSelf && (
                        <Path name="Notifications" path="notifications" />
                    )}
                </Grid>
            </Navbar>
        );
    };

    const _default = {
        path: isSelf ? 'account' : 'purchases',
        element: isSelf ? <MyAccount /> : <BidsAndOffers />,
    };

    function handleInvalid() {
        setValid(false);
    }

    if (loading || account.loading) {
        return <Loading />;
    } else if (!user || !valid) {
        return <NotFound />;
    } else {
        return (
            <Box>
                <Grid container>
                    <Panel>
                        <Box
                            sx={{
                                flex: 1,
                                position: 'sticky',
                                top: 70,
                                height: {
                                    md: 'calc(100vh - 70px)',
                                },
                                backgroundColor: 'tint',
                                overflow: 'auto',
                                boxShadow: '5px 0px 10px rgba(0, 0, 0, 0.1)',
                            }}
                        >
                            <Header />
                            <Navigation user={user} />
                        </Box>
                    </Panel>

                    <Grid item flex={1}>
                        <PaddedBox left={xs ? undefined : { md: 8, lg: 15 }}>
                            <Routes>
                                <Route path="/" element={_default.element} />
                                <Route
                                    path="/account"
                                    element={
                                        <RequireUser
                                            username={username}
                                            onLoad={handleInvalid}
                                        >
                                            <MyAccount />
                                        </RequireUser>
                                    }
                                />
                                <Route
                                    path="/business"
                                    element={
                                        <RequireUser
                                            username={username}
                                            onLoad={handleInvalid}
                                        >
                                            <Business />
                                        </RequireUser>
                                    }
                                />
                                <Route
                                    path="/preferences"
                                    element={
                                        <RequireUser
                                            username={username}
                                            onLoad={handleInvalid}
                                        >
                                            <Preferences />
                                        </RequireUser>
                                    }
                                />
                                <Route
                                    path="/purchases"
                                    element={<BidsAndOffers />}
                                />
                                <Route
                                    path="/following"
                                    element={
                                        <RequireUser
                                            username={username}
                                            onLoad={handleInvalid}
                                        >
                                            <Following />
                                        </RequireUser>
                                    }
                                />
                                {/* <Route
                                    path="/comments"
                                    element={<Comments />}
                                /> */}
                                {/* <Route
                                    path="/listings"
                                    element={<f />}
                                /> */}
                                <Route
                                    path="/notifications"
                                    element={
                                        <RequireUser
                                            username={username}
                                            onLoad={handleInvalid}
                                        >
                                            <Notifications />
                                        </RequireUser>
                                    }
                                />
                                <Route
                                    path="*"
                                    element={
                                        <NotFound onLoad={handleInvalid} />
                                    }
                                />
                            </Routes>
                        </PaddedBox>
                    </Grid>
                </Grid>

                <Footer />
            </Box>
        );
    }
}

const Panel = styled('div')(({ theme }) => ({
    [theme.breakpoints.down('md')]: {
        width: '100%',
    },
    [theme.breakpoints.up('md')]: {
        width: 280,
    },
    [theme.breakpoints.up('lg')]: {
        width: 350,
    },
}));

export default User;

import { Box } from '@mui/material';

function CustomTabPanel({ value, index, children }) {
    return (
        <Box
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
        >
            {value === index && <Box>{children}</Box>}
        </Box>
    );
}

export default CustomTabPanel;

import React, { useState } from 'react';
import { Box, Grid, IconButton, useMediaQuery } from '@mui/material';
import NoData from '../common/NoData';
import RightIcon from '@mui/icons-material/ArrowForwardIos';
import Loading from './Loading';

function CardList({
    items,
    Component,
    xs = 12,
    md = 6,
    lg = 4,
    spacing = 3,
    padding = 3,
    vertical = 3,
    height,
    width,
    noCenter,
    max,
    verticalAdjustment,
    children,
    noData,
    ...props
}) {
    const [index, setIndex] = useState(0);

    const mobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

    // Content loading
    if (items === null || items === undefined) {
        return <Loading height={height || 400} />;
    }
    // List empty, display empty indication
    else if (items?.length === 0) {
        return children ? (
            children
        ) : noData !== false ? (
            <NoData description={noData} />
        ) : null;
    }

    const BUTTON_SIZE = 50;
    const BUTTON_PADDING = 10;
    const BUTTON_WIDTH = BUTTON_SIZE + BUTTON_PADDING;
    const MAX_INDEX = items.length - max;

    // Calculate whether cards should be made wider if there aren't enough to propertly fill the container
    /*const length = items.length;
    if (12 / md > length) {
        md = 6;
    }
    if (12 / lg > length) {
        lg = md;
    }*/

    // Pad the left and right of the grid for cards that have a fixed width ("   1 2   ")
    const specialPadding = (width) => {
        if (width) {
            const min = `calc(${padding} * 8px)`;
            const bars = `calc(((100% - 2 * ${width}px) / 2)`;
            const final = `max(${min}, ${bars})`;
            return { paddingLeft: final, paddingRight: final };
        }
    };

    function scroll(dir) {
        var i = index + dir;
        if (i < 0) {
            i = 0;
        }
        if (i > MAX_INDEX) {
            i = MAX_INDEX;
        }

        setIndex(i);
    }

    return (
        <Box
            position="relative"
            sx={{
                paddingRight: { xs: padding / 3, md: padding },
                paddingLeft: { xs: padding / 3, md: padding },
                paddingTop: { xs: vertical / 3, md: vertical },
                paddingBottom: { xs: vertical / 3, md: vertical },
            }}
        >
            <Box
                position="absolute"
                width={`calc(100% + ${BUTTON_WIDTH * 2}px)`}
                height={1}
                justifyContent="space-between"
                display={{ xs: 'none', md: max > 0 ? 'flex' : 'none' }}
                marginLeft={`${-BUTTON_WIDTH}px`}
                marginRight={`${-BUTTON_WIDTH}px`}
            >
                <ArrowButton
                    width={BUTTON_SIZE}
                    handleClick={() => scroll(-1)}
                    enabled={index > 0}
                    rotation={180}
                    verticalAdjustment={verticalAdjustment}
                />
                <ArrowButton
                    width={BUTTON_SIZE}
                    handleClick={() => scroll(1)}
                    enabled={index < MAX_INDEX}
                    iconRotation={0}
                    verticalAdjustment={verticalAdjustment}
                />
            </Box>
            <Grid
                container
                spacing={mobile ? spacing / 2 : spacing}
                style={specialPadding(width)}
            >
                {items.slice(index, max ? max + index : max).map((item, i) => (
                    <Grid
                        item
                        xs={xs}
                        md={md}
                        lg={lg}
                        margin={noCenter ? '' : 'auto'}
                        key={i}
                    >
                        <Component item={item} height={height} {...props} />
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
}

const ArrowButton = ({
    width,
    handleClick,
    enabled,
    verticalAdjustment,
    rotation,
}) => {
    return (
        <Box
            height={1}
            width={width}
            zIndex={1}
            paddingBottom={`${verticalAdjustment}px`}
            display="flex"
        >
            <IconButton
                onClick={enabled ? handleClick : undefined}
                sx={{
                    display: enabled ? 'inline-flex' : 'none',
                    marginTop: 'auto',
                    marginBottom: 'auto',
                    transform: `rotate(${rotation}deg)`,
                }}
            >
                <RightIcon sx={{ height: 30, width: 30 }} />
            </IconButton>
        </Box>
    );
};

export default CardList;

import React, { useState } from 'react';
import CardList from '../components/common/CardList';
import PropertyCard from '../components/cards/PropertyCard';
import Footer from '../components/common/Footer';
import { Box } from '@mui/material';
import { setTitle } from '../scripts/style';
import PaddedBox from '../components/common/PaddedBox';
import AuctionFilter from '../components/auction/AuctionFilter';
import DataScroller from '../components/common/DataScroller';

function Marketplace() {
    setTitle('Opportunities');

    return (
        <Box backgroundColor="tint">
            <PaddedBox>
                <Box paddingTop={6} paddingBottom={6}>
                    <DataScroller
                        api={'/auction/get'}
                        // body={{ [config.paramId]: param }}
                        placeholder="Unit Number"
                        Child={PropertyCard}
                        // childParams={{ config }}
                        Parent={CardList}
                        parentParams={{
                            xs: 12,
                            md: 6,
                            lg: 4,
                            noCenter: true,
                            padding: 0,
                            vertical: 3,
                            spacing: 6,
                            noData: false,
                        }}
                        limit={6}
                    />
                </Box>
            </PaddedBox>
            <Footer />
        </Box>
    );
}

export default Marketplace;
